var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticationPage',{class:_vm.rootClass,attrs:{"image":_vm.navigationMeta && _vm.navigationMeta[0] ? _vm.navigationMeta[0].image : null,"modifiers":['right']}},[_c('div',{ref:"registrationForm",staticClass:"p-registration__contentWrapper"},[_c('div',{staticClass:"p-registration__content js-animate",attrs:{"data-animate-config":"{ \"staggered\": true, \"animationName\": \"slideFadeFromBottom\", \"staggerDelay\": 50, \"childs\": \".p-registration__heading, .form__input, .form__checkbox, .form__submitBtn, .authenticationPage__routerLink, .authenticationPage__routerLinkSeparator, .js-form__radio\"}"}},[_c('h3',{staticClass:"p-registration__heading"},[_vm._v(" "+_vm._s(_vm.$t("form.register"))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('label',{staticClass:"form__radio js-form__radio"},[_vm._v(" "+_vm._s(_vm.$t('form.male'))+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"form__radio",attrs:{"type":"radio","value":"m"},domProps:{"checked":_vm._q(_vm.gender,"m")},on:{"change":function($event){_vm.gender="m"}}}),_c('span',{staticClass:"formRadio__checkmark"})]),_c('label',{staticClass:"form__radio  js-form__radio"},[_vm._v(" "+_vm._s(_vm.$t('form.female'))+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"form__radio",attrs:{"type":"radio","value":"f"},domProps:{"checked":_vm._q(_vm.gender,"f")},on:{"change":function($event){_vm.gender="f"}}}),_c('span',{staticClass:"formRadio__checkmark"})]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.firstname
                 },attrs:{"type":"text","placeholder":_vm.$t('form.firstName')},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.firstname)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.firstname)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.lastname
                 },attrs:{"type":"text","placeholder":_vm.$t('form.lastName')},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.lastname)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.lastname)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.company
                 },attrs:{"type":"text","placeholder":_vm.$t('form.company')},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.company)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.company)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.countryId),expression:"countryId"}],ref:"originalSelect",class:{
                    'form__input form__select': true,
                    'error': _vm.errorMessage && _vm.errorMessage.countryId
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.countryId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.$t('form.country'))+" ")]),_vm._l((_vm.sortedCountries),function(country,id){return _c('option',{key:id,domProps:{"value":country.id}},[_vm._v(" "+_vm._s(country.fromCurrentSite.common)+" ")])})],2),(_vm.errorMessage && _vm.errorMessage.countryId)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.countryId)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.email
                 },attrs:{"type":"text","placeholder":_vm.$t('form.email')},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.email)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.email)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.phone
                 },attrs:{"type":"text","placeholder":_vm.$t('form.phone')},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.phone)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.phone)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.password
                 },attrs:{"type":"password","placeholder":_vm.$t('form.password')},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.password)?_c('div',{staticClass:"errorMessage formFullPage__colErrors formFullPage__colErrorsPassword"},[_c('ul',{staticClass:"formFullPage__colErrorsList"},_vm._l((_vm.errorMessage.password),function(msg,index){return _c('li',{key:index,staticClass:"formFullPage__colErrorsListItem"},[_vm._v(_vm._s(msg))])}),0)]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repeatPassword),expression:"repeatPassword"}],class:{
                   'form__input formFullPage__colInput': true,
                   'error': _vm.errorMessage && _vm.errorMessage.password_confirmation
                 },attrs:{"type":"password","placeholder":_vm.$t('form.repeatPassword')},domProps:{"value":(_vm.repeatPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.repeatPassword=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage.password_confirmation)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.password_confirmation)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('label',{staticClass:"form__checkbox"},[_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('form.privacyPolicyText'))}}),_c('a',{attrs:{"href":_vm.$t('form.privacyPolicyLink'),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('form.privacyPolicy'))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.privacyPolicy),expression:"privacyPolicy"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.privacyPolicy)?_vm._i(_vm.privacyPolicy,null)>-1:(_vm.privacyPolicy)},on:{"change":function($event){var $$a=_vm.privacyPolicy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.privacyPolicy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.privacyPolicy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.privacyPolicy=$$c}}}}),_c('span',{class:{
              'formCheckbox__checkmark': true,
              'error': _vm.errorMessage && _vm.errorMessage.acceptTerms
            }})]),(_vm.errorMessage && _vm.errorMessage.acceptTerms)?_c('span',{staticClass:"errorMessage formFullPage__colErrors"},[_vm._v(" "+_vm._s(_vm.errorMessage.acceptTerms)+" ")]):_vm._e()]),_c('div',{staticClass:"formFullPage__row"},[_c('label',{staticClass:"form__checkbox"},[_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('form.newsletterText1'))}}),_c('a',{attrs:{"href":_vm.$t('form.privacyPolicyLink'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('form.newsletterPrivacyPolicy')))]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('form.newsletterText2'))}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newsletter),expression:"newsletter"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.newsletter)?_vm._i(_vm.newsletter,null)>-1:(_vm.newsletter)},on:{"change":function($event){var $$a=_vm.newsletter,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.newsletter=$$a.concat([$$v]))}else{$$i>-1&&(_vm.newsletter=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.newsletter=$$c}}}}),_c('span',{class:{
              'formCheckbox__checkmark': true,
            }})])]),_c('div',{staticClass:"formFullPage__row"},[_c('button',{staticClass:"form__submitBtn formFullPage__colInput"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("form.registerButton"))+" ")]),(_vm.loading)?_c('div',{staticClass:"form__submitBtnLoadingSpinner"},[_c('LoadingSpinner',{attrs:{"background":"blackRed","scale":0.5}})],1):_c('IconRight',{attrs:{"color":'red'}})],1)])]),_c('div',{staticClass:"formFullPage__row"},[_c('div',{staticClass:"authenticationPage__routerLinks"},[_c('RouterLink',{staticClass:"authenticationPage__routerLink",attrs:{"tag":"a","to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("form.backToLogin"))+" ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }