import Task from './Task';
import DataDownloadError from '../../../helpers/errors/download/DataDownloadError';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import registerError from '../../../helpers/registerError';

class DataObjectTask extends Task {
    constructor(downloadManager, dataExecutor, route) {
        super();
        this.downloadManager = downloadManager;
        this.dataExecutor = dataExecutor;
        this.route = route;
    }

    async run() {
        if (this.downloadManager.history.includes(this.route)) {
            this.downloadManager.context.commit('INCREASE_downloadStateObjectsDone');
            return;
        }
        
        this.downloadManager.history.push(this.route);

        try {
            await this.fetchDataObjectFromAPI();
            this.downloadManager.context.commit('INCREASE_downloadStateObjectsDone');
            // const data = await this.fetchDataObjectFromAPI();
            // if (data && Array.isArray(data)) {
            //     this.resolveArrayResponse(data);
            // } else if (data) {
            //     this.loadRelationships(data);
            // }
        } catch (err) {
            this.handleError(err);
        }
    }

    async fetchDataObjectFromAPI() {
        const dataResult = await this.downloadManager.context.dispatch('rr/find', this.route, { root: true });
        return dataResult;
    }

    handleError(err) {
        registerError(`DataObjectTask could not load url: ${this.route}`, err);

        if (err instanceof InternetConnectionError) {
            throw err;
        } else {
            throw new DataDownloadError(`DataObjectTask could not load url: ${this.route}`);
        }
    }

    // resolveArrayResponse(data) {
    //     data.forEach(dataObject => {
    //         this.downloadManager.history.push(dataObject.links.self);
    //         this.loadRelationships(dataObject);
    //     });
    // };

    // loadRelationships(data) {
    //     if (Array.isArray(data)) {
    //         this.loadRelationshipsFromArray(data);
    //     } else {
    //         this.loadRelationshipsFromObject(data);
    //     }
    // };

    // loadRelationshipsFromArray(dataArray) {
    //     dataArray.forEach(data => {
    //         this.loadRelationshipsFromObject(data);
    //     });
    // };

    // loadRelationshipsFromObject(data) {
    //     if (!data || !Object.prototype.hasOwnProperty.call(data, 'relationships')) {
    //         return;
    //     }

    //     const relationshipEntries = Object.values(data.relationships);
    //     relationshipEntries.forEach(relationships => {
    //         if (Array.isArray(relationships)) {
    //             relationships.forEach(route => {
    //                 this.downloadManager.context.commit('INCREASE_downloadStateObjectsTotal');
    //                 this.dataExecutor.enqueueTask(
    //                     new DataObjectTask(this.downloadManager, this.dataExecutor, route));
    //             });
    //         } else {
    //             const route = relationships;
    //             this.downloadManager.context.commit('INCREASE_downloadStateObjectsTotal');
    //             this.dataExecutor.enqueueTask(
    //                 new DataObjectTask(this.downloadManager, this.dataExecutor, route));
    //         }
    //     });
    // };
}

export default DataObjectTask;
