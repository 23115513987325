import { createNamespacedHelpers } from 'vuex';
import CardSlider from '../../../../_organisms/cardSlider/_component.vue';
import SliderOverlay from '../../../../_organisms/sliderOverlay/_component.vue';
import IconClose from '../../../../_svg/icon/nav/close.vue';
import LightboxSliderPrintTemplate from '../../../../_print/lightboxSlider/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ContentBuilderLightboxSlider',

    components: {
        CardSlider,
        SliderOverlay,
        IconClose,
        LightboxSliderPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        contentArray: {
            type: Array,
            default: () => [],
        },
        images: {
            type: Array,
            default: () => [],
        },
        colorScheme: {
            type: String,
            default: 'red',
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderLightboxSlider',
            showOverlay: false,
            activeSliderItem: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        slides() {
            const slides = [];

            this.contentArray.forEach((slide, index) => {
                const mappedSlide = {
                    id: index,
                    title: slide.headline,
                    category: slide.subline,
                    copy: slide.copy,
                    callback: this.openSliderOverlay,
                };

                if (this.images[index]) {
                    mappedSlide.image = this.images[index];
                }

                slides.push(mappedSlide);
            });
            return slides;
        },
        ...baseMapper.mapState(['isPrinting']),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        openSliderOverlay(id) {
            this.activeSliderItem = this.getIndexOfCardWith(id);
            this.showOverlay = true;
            this.$store.dispatch('base/lockBody');
        },
        closeOverlay() {
            this.activeSliderItem = null;
            this.showOverlay = false;
            this.$store.dispatch('base/freeBody');
        },
        getIndexOfCardWith(id) {
            const { slides } = this.$refs.imageSlider;
            const currentSlide = slides.filter(slide => slide.id === id)[0];
            return currentSlide ? currentSlide.id : null;
        },
    },
};
