import Accordion from '../../../../_organisms/accordion/_component.vue';

export default {
    name: 'ContentBuilderAccordion',

    components: {
        Accordion,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderAccordion',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
