import {createNamespacedHelpers} from 'vuex';
import { viewConditionHelper, transformHelper } from '../../../helpers/dataHelper';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import Accordion from '../../_organisms/accordion/_component.vue';
import OutputSectionPrintTemplate from '../../_print/output/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'OutputSection',

    components: {
        SectionWrapper,
        Accordion,
        OutputSectionPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        output: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-outputSection',
            activeCategory: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
        categories() {
            const categories = [
                ...new Set(this.output.map(output => output.outputType ? output.outputType.value : null))
            ].filter(output => output !== null);
            
            if (!this.activeCategory) {
                [this.activeCategory] = categories;
            }
            return categories;
        },
        accordionItems() {
            return this.output.filter(output => output.outputType && output.outputType.value === this.activeCategory).map(output => ({
                accordionHeadline: output.headline,
                accordionCopy: {
                    tables: this.getArrayFrom(output.tables),
                    freshImages: this.getArrayFrom(output.freshImageList),
                    frozenImages: this.getArrayFrom(output.frozenImageList),
                }
            }));
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...transformHelper,
        ...viewConditionHelper,
        selectCategory(index) {
            this.activeCategory = this.categories[index];
        },
    },
};
