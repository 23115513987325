import { createNamespacedHelpers } from 'vuex';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';

const baseMapper = createNamespacedHelpers('base');

/**
 * Create an Imageblock with responsive Images. Uses Imgix to load the Images
 */

// Settings
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.expand = 500; // default 360-500
window.lazySizesConfig.expFactor = 1.7; // default: 1.7

export default {
    name: 'ImageWrapper',

    data() {
        return {
            cn: 'imageModule',
            inlineBase64Image: '',
        };
    },
    props: {
        modifiers: {
            type: Array,
            default: () => []
        },
        src: {
            type: String,
        },
        srcset: {
            type: String,
        },
        image: {
            type: Object,
        },
        cropType: {
            type: String,
            default: 'auto',
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        caption: {
            type: Boolean,
            default: false,
        },
        captionContent: {
            type: String,
            default: '',
        },
        captionAddition: {
            type: String,
        },
        contain: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        defaultSrcSet() {
            if (this.srcset) return this.srcset
            if (this.image && this.image.attributes) {
                const imageTypes = this.image.attributes.versions[this.cropType];
                return this.buildSourceSet(imageTypes.default);
            }
        },
        offlineSrc() {
            if (this.image && 
                this.image.attributes &&
                this.image.attributes.versions &&
                this.image.attributes.versions[this.cropType]) {
                return this.image.attributes.versions[this.cropType].offline;
            }
        },
        placeHolder() {
            if (this.src) return this.src;
            if (this.image && this.image.attributes) {
                const imageTypes = this.image.attributes.versions[this.cropType];

                let placeHolder;

                if (this.isInPdfGenerationMode) {
                    const versions = Object.keys(imageTypes).sort();
                    placeHolder = imageTypes[versions[0]];
                } else {
                    placeHolder = imageTypes.default['200'];
                }

                if (placeHolder) {
                    return placeHolder;
                }
            }
        },
        webpSrcSet() {
            const imageTypes = this.image.versions[this.cropType];
            return this.buildSourceSet(imageTypes.webp);
        },
        ...baseMapper.mapGetters([
            'isOffline',
            'isInPdfGenerationMode',
        ]),
    },
    watch: {},
    methods: {
        buildSourceSet(imageTypes) {
            if (typeof imageTypes === 'undefined') return '';
            const result = [];
            Object.keys(imageTypes).forEach((key) => {
                result.push(`${imageTypes[key]} ${key}w`);
            });
            return result.join(', ');
        }
    }
};
