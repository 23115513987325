import { createNamespacedHelpers } from 'vuex';
import { mapObject } from 'vuex-json-relationship-resolver';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';
import { REDIRECT_TO_404_TIME_MS } from '../../../settings';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'productDetail',

    components: {
        ViewWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-pdfIframePage',
            pdfLink: null,
            timeout404Page: null,
        };
    },

    computed: {
        ...mapObject({
            navItem: {
                type: 'navigation-item',
                filter: {
                    id: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.id;
                    }
                }
            }
        }),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        pdf() {
            let pdf = null;
            if (this.navItem) {
                pdf = this.navItem.pdfDocument;
            }
            return pdf;
        },
        ...baseMapper.mapGetters({
            isTouchDevice: 'isMobile',
            isOffline: 'isOffline',
        }),
        ...baseMapper.mapState([
            'isDesktopApp',
        ]),
    },

    watch: {
        pdf: {
            handler() {
                this.setPdfLink();
            },
            deep: true,
        },
    },

    mounted() {
        this.setPdfLink();
        this.setCurrentDocument();
    },

    created() {
        this.timeout404Page = setTimeout(() => {
            if (!this.pdfLink) {
                this.$router.push({ name: 'page404' });
            }
        }, REDIRECT_TO_404_TIME_MS);
    },

    methods: {
        setPdfLink() {
            if (this.pdf && !this.pdf._loading && !this.pdf._emty) {
                this.pdfLink = this.isOffline ? this.pdf.offlineLink : this.pdf.storageUrl;
            }
        },
        setCurrentDocument() {
            if (this.navItem && this.navItem.pdfDocument) {
                this.$store.dispatch('documents/setCurrentDocumentTo', null);
                this.$nextTick(() => {
                    this.$store.dispatch('documents/setCurrentDocumentTo', {
                        id: `${this.navItem.id}`,
                        type: 'pdf',
                        tableOfContentsTitle: this.navItem.pdfName || this.navItem.name,
                        url: this.navItem.pdfDocument.storageUrl,
                    });
                });
            }
        },
    },

    destroyed() {
        this.$store.dispatch('documents/setCurrentDocumentTo', null);
        clearTimeout(this.timeout404Page);
    },
};
