import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import isDesktopApp from '../services/is-desktop-app';

const init = (store) => {
    Vue.use(Router);

    let router;

    if (isDesktopApp) {
        router = new Router({
            // mode: 'hash',
            routes,
            scrollBehavior(to, from, savedPosition) {
                if (savedPosition) {
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(savedPosition);
                        }, 500);
                    });
                }
                return { x: 0, y: 0 };
            },
        });
    } else {
        router = new Router({
            mode: 'history',
            routes,
            scrollBehavior(to, from, savedPosition) {
                if (savedPosition) {
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(savedPosition);
                        }, 500);
                    });
                }
                return { x: 0, y: 0 };
            },
        });
    }

    
    const redirectToLoginIfNecessary = (to, from, next) => {
        if (store.state.base.isInPdfGenerationMode) {
            next();
            return;
        }

        if (to.matched.some(record => record.meta.requiresAuth)) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    };
    
    const redirectToHomeIfNecessary = (to, from, next) => {
        if (store.state.base.isInPdfGenerationMode) {
            next();
            return;
        }

        if (to.matched.some(record => record.meta.redirectIfLoggedIn)) {
            next({
                name: 'home',
            });
        } else {
            next();
        }
    };

    router.beforeEach((to, from, next) => {
        if (store.state.base.isDesktopApp) {
            // eslint-disable-next-line
            const ipcRenderer = require('electron').ipcRenderer;
            ipcRenderer.send('urlChange', to.name === 'home');
        }
        // ----- Authentication -----
        const user = store.state.rr.objects.filter(o => o.type === 'user');
        if (user.length === 1 || store.getters['base/isOffline']) { // logged in
            redirectToHomeIfNecessary(to, from, next);
        } else {
            store.dispatch('auth/checkIfLoginIsValid', {}).then(() => { // logged in
                redirectToHomeIfNecessary(to, from, next);
            }).catch(() => { // NOT logged in
                redirectToLoginIfNecessary(to, from, next);
            });
        }
    });

    return router;
};

export default init;
