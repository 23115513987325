class Queue {
    constructor() {
        this.queue = [];
    }
    
    enqueue(item) {
        this.queue.push(item);
    }

    dequeue() {
        return this.queue.shift();
    }

    getLength() {
        return this.queue.length;
    }
}

export default Queue;