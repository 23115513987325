import InternetConnectionError from '../../../../helpers/errors/InternetConnectionError';
import UnprocessableEntityError from '../../../../helpers/errors/UnprocessableEntityError';
import UnauthorizedError from '../../../../helpers/errors/UnauthorizedError';

const sendDocument = (context, customer, message, documentArray) => {
    return new Promise((resolve, reject) => {
        fetch(`${context.rootState.base.http.baseUrl}document-generator/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain; charset=utf-8',
                'Authorization': `Bearer ${context.rootState.auth.token}`,
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            },
            body: JSON.stringify({
                meta: {
                    customer,
                    message: message,
                },
                pages: documentArray,
            })
        }).then((response) => {
            return response.json().then(body => {
                if (response.ok) {
                    resolve(body.data.hash);
                } else if (response.status === 422) {
                    reject(new UnprocessableEntityError(null, body.data.errors));
                }  else if (response.status === 401) {
                    reject(new UnauthorizedError());
                } else {
                    reject();
                }
            });
        }).catch((error) => {
            reject(new InternetConnectionError(error));
        });
    });
};

export default sendDocument;