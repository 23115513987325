import InternetConnectionError from '../../../../helpers/errors/InternetConnectionError';
import UnauthorizedError from '../../../../helpers/errors/UnauthorizedError';

const checkForNewNotifications = (context) => {
    return new Promise((resolve, reject) => {
        fetch(`${context.rootState.base.http.baseUrl}notifications/unseen`, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain; charset=utf-8',
                'Authorization': `Bearer ${context.rootState.auth.token}`,
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            },
        }).then((response) => {
            return response.json().then(body => {
                if (response.ok) {
                    const { status_code, data } = body;
                    switch (status_code) {
                        case 200: {
                            resolve(data);
                            break;
                        }
                        default: {
                            reject();
                        }
                    }
                } else if (response.status === 401) {
                    reject(new UnauthorizedError('User not logged in'));
                } else {
                    reject();
                }
            });
        }).catch((error) => {
            reject(new InternetConnectionError(error));
        });
    });
};

export default checkForNewNotifications;
