<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <g fill="none" fill-rule="evenodd">
        <path fill="#E11624" fill-rule="nonzero" d="M39.1818182,16.5979731 C32.5143247,9.88837302 23.2249783,7.44216464 14.6127992,9.22440218 L18.9883418,13.6275773 C25.0133945,13.3305377 31.1252635,15.4622335 35.7091653,20.0925565 L39.1818182,16.5979731 L39.1818182,16.5979731 Z M32.2365125,23.5871399 C29.9966514,21.3331336 27.3053454,19.8654086 24.4404068,19.1140732 L30.5696391,25.2820129 L32.2365125,23.5871399 L32.2365125,23.5871399 Z M2.73632643,6.20158754 L8.06684857,11.5308272 C5.51444872,12.7888772 3.11831824,14.4662773 1,16.5979731 L4.45528959,20.0925565 C6.60833437,17.9259148 9.09128116,16.3184065 11.7478606,15.2525585 L15.6372318,19.1664919 C12.824383,19.9003545 10.1504403,21.3680795 7.94530572,23.5871399 L7.94530572,23.6046129 L11.4005953,27.0817233 C13.7619993,24.7054066 16.8526603,23.5172483 19.9433213,23.4823024 L32.2191492,35.8181818 L34.4242838,33.6165943 L4.97618752,4 L2.73632643,6.20158754 Z M14.8906114,30.5763067 L20.0995907,35.8181818 L25.30857,30.5763067 C22.4436314,27.6758025 17.7729133,27.6758025 14.8906114,30.5763067 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'WifiOff',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationWifiOff',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationWifiOff {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationWifiOff--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationWifiOff--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationWifiOff--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationWifiOff--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
