import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import CaseStudyPrintTemplate from '../../_print/caseStudies/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'CaseStudySection',

    components: {
        CardSlider,
        SectionWrapper,
        CaseStudyPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: () => null,
        },
        caseStudies: {
            type: Array,
            default: () => [],
        },
        mappingFunction: {
            type: Function,
            required: true,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-caseStudySection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...viewConditionHelper,
    },
};
