import FontFaceObserver from 'fontfaceobserver';

// Disable if you use WebFonts
// document.documentElement.className += ' wf-loaded';


/**
 * Check if the User already visited the site and the Fonts were loaded
 * If not resolve all fonts, otherwise directly set the class to html
 */

const fontA = new FontFaceObserver('AktivGrotesk');

fontA.load(null, 10 * 1000).then(() => {
    document.documentElement.classList.add('wf-loaded');
    sessionStorage.fontsLoaded = true;
}).catch((e) => {
    console.error(e);
    document.documentElement.classList.add('wf-failed');
    sessionStorage.fontsLoaded = false;
});
