import { module } from './modules/rr';

const relationshipResolverOptions = {
    customHelpers: {
        buildEndpointUrl(rootState, { endpoint }) {

            if (endpoint) {
                const absoluteURLTest = new RegExp('^(?:[a-z]+:)?//', 'i');
                if (absoluteURLTest.test(endpoint)) return endpoint;
            }

            const endpointUrl = rootState.base.http.baseUrl + endpoint;

            return endpointUrl;
        },
        httpClient(rootState) {
            if (rootState.isDesktopApp) {
                return rootState.desktopApp.offlineHttpClient;
            }
            return null;
        },
        requestOptions(rootState) {
            return {
                headers: {
                    'Authorization': `Bearer ${rootState.auth.token}`,
                    'X-Site-Language': rootState.base.language,
                    'X-IS-DOCUMENT-RENDERER': rootState.base.isInPdfGenerationMode,
                }
            };
        }
    }
};

export default module(relationshipResolverOptions);
