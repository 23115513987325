// only use this method if server returns "401 on auth/ping"
// or "403 on auth/refresh" or "200 on auth/logout"

const logoutOnlyFrontend = (context) => {
    context.commit('setToken', { token: null });
    context.commit('setRefreshToken', { refreshToken: null });
    context.rootState.rr.objects = [];
    context.dispatch('rr/find', 'site/_all', { root: true });
};

export default logoutOnlyFrontend;