import IconLeft from '../../_svg/icon/chevron/left.vue';
import IconRight from '../../_svg/icon/chevron/right.vue';
import IconDownload from '../../_svg/icon/download.vue';
import CloseIcon from '../../_svg/icon/nav/close.vue';
import VideoPlayer from '../../_molecules/videoPlayer/_component.vue';
import ImageWrapper from '../../_atoms/image/_component.vue';
import IconButton from '../../_atoms/iconButton/_component.vue';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';
import { createNamespacedHelpers } from 'vuex';
import { Actions as MediaLibraryActions, Getters as MediaLibraryGetters } from '../../../vuex/modules/medialibrary/module';

const mediaLibraryMapper = createNamespacedHelpers('medialibrary');

export default {
    name: 'MediaLibraryOverlay',

    components: {
        ImageWrapper,
        IconLeft,
        IconRight,
        IconDownload,
        CloseIcon,
        VideoPlayer,
        IconButton,
        LoadingSpinner
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        selectedItem: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            cn: 'o-mediaLibraryOverlay',
            mediaWidth: 0,
            isMediaLoaded: false,
            resizeObserver: undefined
        };
    },

    computed: {
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_ACTIVE_DETAIL_VIEW_ITEM]),
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_MEDIA_ITEMS]),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        item() {
            return this[MediaLibraryGetters.GET_ACTIVE_DETAIL_VIEW_ITEM]
        },
        prevItem() {
            return this[MediaLibraryGetters.GET_MEDIA_ITEMS][this[MediaLibraryGetters.GET_MEDIA_ITEMS].indexOf(this.item) - 1];
        },
        nextItem() {
            return this[MediaLibraryGetters.GET_MEDIA_ITEMS][this[MediaLibraryGetters.GET_MEDIA_ITEMS].indexOf(this.item) + 1];
        },
        videoObject() {
            return {
                _loading: false,
                _empty: false,
                _error: false,
                attributes: {
                    versions: [
                        {
                            url: this.item.video.url,
                            type: this.item.video.type,
                            thumbnail: this.item.video.thumbnail.previews.bigVideoPreview
                        }
                    ]
                }
            }
        },
        downloadUrl() {
            const url = this.item.type === 'video' ? this.item.video.url : this.item.image.original;
            return url.replace('/assets/', '/download/assets/');
        }
    },

    watch: {
        item: function() {
            if (this.resizeObserver) this.resizeObserver.disconnect()

            this.$nextTick(() => {
                this.storeObserver()
                this.calculateMediaWidth()
            })
        },
    },

    mounted() {
        this.$store.dispatch('base/lockBody');
        this.$nextTick(() => {
            this.calculateMediaWidth()
        })
    },

    beforeDestroy() {
        this.$store.dispatch('base/freeBody');
        if (this.resizeObserver) this.resizeObserver.disconnect()
    },

    created() {
        this.storeObserver()
    },

    methods: {
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.ACTIVATE_DETAIL_VIEW]),
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.DEACTIVATE_DETAIL_VIEW]),
        handleCloseTriggerClick() {
            this[MediaLibraryActions.DEACTIVATE_DETAIL_VIEW]();
        },
        preloadNext() {
            if (this.nextItem && this.nextItem.type === 'image') {
                let image = new Image();
                image.src = this.nextItem.image.previews.autoPreview
            }
        },
        preloadPrev() {
            if (this.prevItem && this.prevItem.type === 'image') {
                let image = new Image();
                image.src = this.prevItem.image.previews.autoPreview
            }
        },
        handleNextTriggerClick() {
            this.isMediaLoaded = false
            if (this.nextItem) this[MediaLibraryActions.ACTIVATE_DETAIL_VIEW](this.nextItem);
        },
        handlePrevTriggerClick() {
            this.isMediaLoaded = false
            if (this.prevItem) this[MediaLibraryActions.ACTIVATE_DETAIL_VIEW](this.prevItem);
        },
        getMediaWidth(element) {
            let ratio
            let elementWidth
            let elementHeight
            if (this.item.type === 'video') {
                ratio = element.offsetWidth / element.offsetHeight;
                elementWidth = element.offsetWidth
                elementHeight = element.offsetHeight
            } else {
                ratio = element.naturalWidth / element.naturalHeight;
                elementWidth = element.width
                elementHeight = element.height
            }
            let width = elementHeight * ratio
            if (width > element.width) width = elementWidth

            if (window.innerWidth < 800) {
                return window.innerWidth
            }
            return width
        },
        calculateMediaWidth() {
            if (this.$refs.mediaItem && this.$refs.mediaItem.$el) {
                const img = this.$refs.mediaItem.$el.querySelector('img')
                const video = this.$refs.mediaItem.$el.querySelector('video')

                if (img) {
                    img.onload = () => {
                        this.mediaWidth = this.getMediaWidth(img);
                        this.isMediaLoaded = true;
                        this.resizeObserver.observe(img)
                    }
                }

                if (video) {
                    this.mediaWidth = this.getMediaWidth(video);
                    this.isMediaLoaded = true;
                    this.resizeObserver.observe(video)
                }
            }
        },
        updateMediaWidth() {
            if (this.$refs.mediaItem && this.$refs.mediaItem.$el) {
                const img = this.$refs.mediaItem.$el.querySelector('img')
                const video = this.$refs.mediaItem.$el.querySelector('video')

                if (img) this.mediaWidth = this.getMediaWidth(img);
                if (video) this.mediaWidth = this.getMediaWidth(video);
            }
        },
        storeObserver() {
            this.resizeObserver = new ResizeObserver(entries => {
                entries.forEach(() => {
                    this.updateMediaWidth()
                });
            });
        },
    },
};
