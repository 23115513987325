import MediaLibraryOverlay from './../../components/_organisms/mediaLibraryOverlay/_component.vue';
import { createNamespacedHelpers } from 'vuex';
import { Getters as MediaLibraryGetters } from '../../vuex/modules/medialibrary/module';

const mediaLibraryMapper = createNamespacedHelpers('medialibrary');

export default {
    name: 'mediaLibraryPage',

    components: {
        MediaLibraryOverlay
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        sections: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-mediaLibraryPage',
        };
    },

    computed: {
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_ACTIVE_DETAIL_VIEW_ITEM]),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        isOverlayOpen() {
            return !!this[MediaLibraryGetters.GET_ACTIVE_DETAIL_VIEW_ITEM]
        }
    },

    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        scrollToSection(index) {
            if (this.sections && this.sections[index]) {
                this.sections[index].scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
    },

    destroyed() {},
};
