import Task from './Task';
import { TYPE_WEBROTATE_360 } from '../../../../settings';
import storeAsset from '../../store-asset';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import registerError from '../../../helpers/registerError';
import Webrotate360DownloadError from '../../../helpers/errors/download/Webrotate360DownloadError';
import AddressInfoError from "@/helpers/errors/AddressInfoError";

class Webrotate360Task extends Task {
    constructor(downloadManager, dataExecutor, asset, preservePathElements) {
        super();
        this.downloadManager = downloadManager;
        this.asset = asset;
        this.preservePathElements = preservePathElements;
        this.retries = 0;
    }

    async run() {
        if (!this.asset) {
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingWebrotate360Done');
            return;
        }

        try {
            await this.saveWebrotate360ToDisk();
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingWebrotate360Done');
        } catch (err) {
            this.handleError(err);
        }
    }

    // eslint-disable-next-line
    handleError(err) {
        if (err instanceof InternetConnectionError) {
            registerError('Internet Connection Error', err);
            throw err;
        } else if (err instanceof AddressInfoError) {
            if (this.retries < 3) {
                this.retries += 1;
                this.run();
            } else {
                registerError(`Webrotate360Task could not load asset ${this.asset.id}`, err);
                throw new Webrotate360DownloadError(`Webrotate360Task could not load asset ${this.asset.id}`);
            }
        } else {
            registerError('Webrotate360Task could not load rendering', err);
            throw new Webrotate360DownloadError('Webrotate360Task could not load rendering');
        }
    }

    async saveWebrotate360ToDisk() {
        await storeAsset(this.asset, null, TYPE_WEBROTATE_360, this.preservePathElements);
    }
}

export default Webrotate360Task;
