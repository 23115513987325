import { mapObject } from 'vuex-json-relationship-resolver';
import ImageVideoGallery from '../../components/_organisms/imageVideoGallery/_component.vue';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';
import { REDIRECT_TO_404_TIME_MS } from '../../../settings';

export default {
    name: 'automation',

    components: {
        ImageVideoGallery,
        ViewWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-automationDetail',
            timeout404Page: null,
        };
    },

    computed: {
        ...mapObject({
            automation: {
                type: 'product-line-automation',
                filter: {
                    slug: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.slug;
                    }
                }
            }
        }),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {
        '$route.params.slug'() {
            this.$store.dispatch('rr/find', `product-line-automation/${this.$route.params.slug}`);
        },
    },

    created() {
        this.$store.dispatch('rr/find', `product-line-automation/${this.$route.params.slug}`);

        this.timeout404Page = setTimeout(() => {
            if (!this.automation) {
                this.$router.push({ name: 'page404' });
            }
        }, REDIRECT_TO_404_TIME_MS);
    },

    mounted() {},

    methods: {
        backToLastPage() {
            this.$router.back();
        }
    },

    destroyed() {
        clearTimeout(this.timeout404Page);
    },
};
