
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73"><g fill="none" fill-rule="evenodd"><circle cx="36.388" cy="36.388" r="36.388" fill="#E11624"/><polygon fill="#161A1B" points="40.088 25.903 55.507 49.339 24.67 49.339" transform="rotate(90 40.088 37.621)"/></g></svg>

    </div>
</template>

<script>
export default {
    name: 'play-start',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '73px',
      },
      height: {
        type: String,
        default: '73px',
      },
    },
    data() {
      return {
        cn: 'svg-play-start',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-play-start {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-play-start--black {
    path {
      fill: c('black');
    }
  }

  .svg-play-start--red {
    path {
      fill: c('red');
    }
  }

  .svg-play-start--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-play-start--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    