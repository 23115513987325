import anime from 'animejs';
import { createNamespacedHelpers } from 'vuex';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ScrollSpy',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        sections: {
            type: Array,
            default: () => [],
        },
        showScrollSpy: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            cn: 'o-scrollSpy',
            windowScrollPosition: 0,
            initialAnimationPlayed: false,
            animation: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        menuLinks() {
            const menuLinks = [];
            this.sections.forEach(section => {
                menuLinks.push(section.id);
            });
            return menuLinks;
        },
        scrollOffset() {
            if (this.viewport && this.viewport.height) {
                return this.viewport.height / 2;
            }
            return 0;
        },
        ...baseMapper.mapState([
            'viewport',
        ]),
        ...baseMapper.mapGetters([
            'isMobile',
        ]),
    },

    watch: {
        '$route.params.slug'() {
            if (this.animation) {
                this.animation.reset();
            }
            this.initialAnimationPlayed = false;
        },
    },

    mounted() {},

    created() {
        window.addEventListener('scroll', this.setWindowScrollPosition);
        window.addEventListener('resize', this.setWindowScrollPosition);
    },

    methods: {
        animateElements() {
            anime({
                targets: '.o-scrollSpy__list .o-scrollSpy__listItem',
                easing: 'easeOutQuart',
                opacity: [
                    { value: 0, duration: 0 },
                    { value: 1, duration: 500, delay: 0 },
                ],
                translateY: [
                    { value: 100, duration: 0 },
                    { value: 0, duration: 500, delay: 0 },
                ],
                delay: anime.stagger(100, { start: 0 }),
                complete: (animation) => {
                    this.animation = animation;
                }
            });
            this.initialAnimationPlayed = true;
        },

        hightlightActiveLink(index) {
            if (this.sections[index]) {
                const sectionStart = this.sections[index].offsetTop;
                const sectionHeight = this.sections[index].offsetHeight;
                const sectionEnd = sectionStart + sectionHeight;

                const scrollPosition = this.windowScrollPosition + this.scrollOffset;

                if (scrollPosition >= sectionStart &&
                    scrollPosition < sectionEnd) {
                    return true;
                }
            }
            return false;
        },

        setWindowScrollPosition() {
            if (!this.initialAnimationPlayed) {
                this.animateElements();
            }

            this.windowScrollPosition = window.scrollY;
        },
    },

    destroyed() {
        window.removeEventListener('scroll', this.setWindowScrollPosition);
        window.removeEventListener('resize', this.setWindowScrollPosition);
    }
};
