import { mapObject } from 'vuex-json-relationship-resolver';
import {  createNamespacedHelpers } from 'vuex';
import LogoWithLink from '../../../components/_molecules/logoWithLink/_component.vue';
import ImageComponent from '../../../components/_atoms/image/_component.vue';
import IconDown from '../../../components/_svg/icon/chevron/down.vue';
import IconRight from '../../../components/_svg/icon/chevron/right.vue';
import LoadingSpinner from '../../../components/_atoms/loadingSpinner/_component.vue';
import AuthenticationPage from '../../../layouts/authenticationPage/_component.vue';
import animations from '../../../assets/js/animations';

const baseMapper = createNamespacedHelpers('base');


export default {
    name: 'Login',

    components: {
        LogoWithLink,
        ImageComponent,
        IconDown,
        IconRight,
        LoadingSpinner,
        AuthenticationPage,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-loginPage',
            errorMessage: '',
            email: '',
            keepMeLoggedIn: false,
            password: '',
            language: 'de',
            loading: false,
        };
    },

    created() {
        this.language = this.currentLanguage;
    },

    mounted() {
        this.doAnimations();
        this.$store.dispatch('rr/find', 'navigation-meta/_all', { root: true });
    },

    computed: {
        ...mapObject({ sites: {type: 'site', isArray: true }}),
        ...mapObject({ navigationMeta: { type: 'navigation-meta', isArray: true }}),
        ...baseMapper.mapState({
            isDesktopApp: 'isDesktopApp',
            currentLanguage: 'language',
        }),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {
        language() {
            this.$store.commit('base/setLanguage', this.language);
            this.errorMessage = '';
        }
    },

    methods: {
        submit() {
            this.loading = true;
            this.errorMessage = null;

            let longTerm = this.keepMeLoggedIn;
            if (this.isDesktopApp) longTerm = true;

            this.$store.dispatch('auth/login', {
                username: this.email,
                password: this.password,
                longTerm
            }).then(() => {
                this.loading = false;
                this.$store.dispatch('base/freeBody');
                this.$router.push(this.$route.query.redirect || '/').catch(() => {});
            }).catch((errorMessage) => {
                this.errorMessage = errorMessage;
                this.loading = false;
            });
        },
        doAnimations() {
            const animateWrapper = this.$refs.loginWrapper;
            animations.init(animateWrapper);
        }
    },
};
