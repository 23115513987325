import NoRefreshTokenError from '../../../../helpers/errors/NoRefreshTokenError';
import NotLoggedInError from '../../../../helpers/errors/NotLoggedInError';

const checkIfLoginIsValid = (context) => {
    context.state.isCheckingAuthentication = true;

    return new Promise((resolve, reject) => {
        const url = `${context.rootState.base.http.baseUrl}auth/ping`;
        const headers = {
            'Authorization': `Bearer ${context.state.token}`,
            'X-Site-Language': context.rootState.base.language,
            'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
        };

        fetch(url, { headers }).then((response) => {
            context.state.isCheckingAuthentication = false;
            if (response.ok) {
                resolve();
            } else {
                // no refresh token available
                if (!context.state.refreshToken) {
                    reject(new NoRefreshTokenError());
                    return;
                }

                // generate new token
                context.dispatch('refreshToken').then(() => resolve()).catch(
                    (error) => reject(new NotLoggedInError(error))
                );
            }
        }).catch(() => {
            if (context.rootState.desktopApp) {
                context.dispatch('desktopApp/checkApiVersion', null, { root: true });
            }
        });
    });
};

export default checkIfLoginIsValid;
