
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m21 22.2666667 3.5-3.2666667 1.5 1.4266-6 5.5734-6-5.5734 1.5-1.4266 3.5 3.2666667v-11.2666667h2zm-7 4.7333333h12v2h-12z" fill="#161a1b" fill-rule="evenodd"/></svg>
    </div>
</template>

<script>
export default {
    name: 'download',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-download',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-download {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-download--black {
    path {
      fill: c('black');
    }
  }

  .svg-download--red {
    path {
      fill: c('red');
    }
  }

  .svg-download--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-download--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    