
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg height="132" viewBox="0 0 738 132" width="738" xmlns="http://www.w3.org/2000/svg">
  <g fill="#fff" fill-rule="evenodd">
    <path
      d="m642.566399 0-64.482409 131.995652h29.482145l11.845039-24.198841h44.988536l7.270523 24.198841h65.904335l-39.548688-131.995652zm4.791935 50.567532 9.814337 33.0217419h-25.942548zm-65.985215-50.5501384h-39.974832l-47.410594 47.4105943 9.188167-47.4105943-65.982607-.0086968-25.703386 131.9913032h.395704 65.604296l9.531691-48.3715905h23.637896l-9.427329 48.3715905h65.956516l9.970879-51.3763342c2.143761-11.040585-6.339965-21.2854131-17.584925-21.2462775l-37.774542.152194zm-165.646607 24.2605877 4.704968-24.19884044-101.756885.0130452c-13.319146.0043484-24.772829 9.44472262-27.316643 22.52470684l-11.862432 61.0384767h58.407695l-4.700619 24.1684014h-58.403347l-4.700619 24.17275h101.804717c13.28001 0 24.698906-9.409935 27.234023-22.442087l12.105943-62.1908024h-58.525102l4.487547-23.0856503zm-240.255567 26.2895507 9.814336 33.0217419h-25.942548zm50.667545-50.567532h-55.459481l-64.482409 132h29.486494l11.84069-24.203189h44.992885l7.266175 24.203189h65.908683z"/>
    <path d="m0 131.998 25.621-131.995h66.03l-20.972 107.796h29.099l-4.722 24.199z"/>
  </g>
</svg>

    </div>
</template>

<script>
export default {
    name: 'LaskaLogo',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '738px',
      },
      height: {
        type: String,
        default: '132px',
      },
    },
    data() {
      return {
        cn: 'svg-LaskaLogo',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-LaskaLogo {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-LaskaLogo--black {
    path {
      fill: c('black');
    }
  }

  .svg-LaskaLogo--red {
    path {
      fill: c('red');
    }
  }

  .svg-LaskaLogo--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-LaskaLogo--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    