import Flickity from 'vue-flickity'; // eslint-disable-line
import { mapObject } from 'vuex-json-relationship-resolver';
import ImageWrapper from '../../_atoms/image/_component.vue';
import IconLeft from '../../_svg/icon/chevron/left.vue';
import IconRight from '../../_svg/icon/chevron/right.vue';
import VideoPlayer from '../../_molecules/videoPlayer/_component.vue';
import env from '../../../../.env';

export default {
    name: 'SliderOverlay',

    components: {
        ImageWrapper,
        Flickity,
        IconLeft,
        IconRight,
        VideoPlayer
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        slides: {
            type: Array,
            default: () => [],
        },
        selectedItem: {
            type: Number,
            default: 0,
        }
    },

    data() {
        return {
            cn: 'o-sliderOverlay',
            flickityOptions: {
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                dragThreshold: 3,
                prevNextButtons: true,
                pageDots: false,
                imagesLoaded: true,
                resize: true,
            },
            currentSlideIndex: 0,
            slidesCount: 0,
            dragging: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        noNextSlide() {
            if (this.slidesCount) {
                return this.currentSlideIndex === this.slidesCount - 1;
            }
            return false;
        },
        noPrevSlide() {
            if (this.slidesCount) {
                return this.currentSlideIndex === 0;
            }
            return false;
        },
        ...mapObject({
            sites: { type: 'site', isArray: true },
        }),
        languages() {
            return this.sites.map((site) => site.language);
        }
    },

    watch: {},

    mounted() {
        if (this.slides && this.slides.length > 0) {
            const flickityElem = this.$refs.flickity.$flickity;
            this.$refs.flickity.resize();
            this.$refs.flickity.select(this.selectedItem, false, true);
            flickityElem.nextButton.element.classList.add('flickity__nextBtn');
            flickityElem.prevButton.element.classList.add('flickity__prevBtn');
            flickityElem.focus();
            this.slidesCount = this.$refs.flickity.$children.length;
            this.currentSlideIndex = this.selectedItem;
            this.$refs.flickity.on('change', this.setSelectedIndex);

            this.$refs.flickity.on('dragMove', this.startDragging);
            this.$refs.flickity.on('dragEnd', this.stoppDragging);
        }
    },

    created() {},

    methods: {
        setSelectedIndex() {
            this.currentSlideIndex = this.$refs.flickity.selectedIndex();
        },
        prevSlide() {
            this.$refs.flickity.previous();
        },
        nextSlide() {
            this.$refs.flickity.next();
        },
        startDragging() {
            this.dragging = true;
            document.ontouchmove = (e) => {
                e.preventDefault();
            };
        },
        stoppDragging() {
            this.$nextTick(() => {
                this.dragging = false;
                document.ontouchmove = () => {
                    return true;
                };
            });
        },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|\\[\]\\\\/]/g, '\\$&');
        },
        removeAbsoluteLinks(caption) {
            const pattern = `href\\=\\"${this.escapeRegExp(env.apiDomain)}`;
            const regExp = new RegExp(pattern, 'g');
            return caption.replace(regExp, 'href="');
        },
        removeLanguagePartInLinks(caption) {
            let result = caption;

            this.languages.forEach(language => {
                const pattern = `href\\=\\"${language}\\/`;
                const regExp = new RegExp(pattern, 'g');
                result = result.replace(regExp, 'href="');
            });

            return result;
        },
        makeLinksAbsolute(caption) {
            const pattern = 'href\\=\\"(?!http)';
            const regExp = new RegExp(pattern, 'g');
            return caption.replace(regExp, 'href="/');
        },
        fixInlineLinks(caption) {
            let newCaption = this.removeAbsoluteLinks(caption);
            newCaption = this.removeLanguagePartInLinks(newCaption);
            newCaption = this.makeLinksAbsolute(newCaption);
            return newCaption;
        }
    },
};
