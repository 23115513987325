import { createNamespacedHelpers } from 'vuex';
import Toggle from '../../_atoms/toggle/_component.vue';
import ChevronIconLeft from '../../_svg/icon/chevron/left.vue';
import ChevronIconRight from '../../_svg/icon/chevron/right.vue';

const desktopAppMapper = createNamespacedHelpers('desktopApp');
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Settings',

    components: {
        Toggle,
        ChevronIconLeft,
        ChevronIconRight,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-settings',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isDesktopApp', 'isInternetAvailable']),
        ...baseMapper.mapGetters([
            'isOffline',
        ]),
        ...desktopAppMapper.mapGetters([
            'loading',
            'loadingImagesStatistics',
            'loadingObjectsStatistics',
            'loadingVideosStatistics',
            'loadingGeneral',
        ]),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        async enable() {
            if (!this.isOffline) {
                this.$store.commit('base/setPreventStatusMessages', true);
                await this.$store.dispatch('desktopApp/switchToOffline');
                this.$store.commit('base/setPreventStatusMessages', false);
            }
        },
        disable() {
            if (this.isOffline) {
                this.$store.dispatch('desktopApp/switchToOnline');
            }
        },
        connectionChange(offline) {
            if (offline) {
                this.disable();
            } else {
                this.enable();
            }
        },
        toggleSettings() {
            this.$parent.toggleSettings();
        },
        reset() {
            delete window.localStorage.vuex;
            window.location.reload();
        }
    },
};
