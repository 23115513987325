import {createNamespacedHelpers} from 'vuex';
import Flickity from 'vue-flickity'; // eslint-disable-line

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Slider',

    components: {
        Flickity,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        cardType: {
            type: String,
            default: '',
        },
        controls: {
            type: Boolean,
            default: false,
        },
        controlsPosition: {
            type: String,
            default: 'bottom',
        },
        backgroundColor: {
            type: String,
            default: '',
        },
        sliderCellAlign: {
            type: String,
            default: 'center',
        }
    },

    data() {
        return {
            cn: 'o-slider',
            flickityOptions: {
                cellAlign: this.sliderCellAlign,
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                prevNextButtons: this.controls,
                pageDots: false,
                imagesLoaded: true,
                resize: true,
                contain: false,
            },
            showSlider: true,
            dragging: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {
        const slider = this;

        if (this.controls) {
            const flickityElem = this.$refs.flickity.$flickity;
            flickityElem.nextButton.element.classList.add('flickity__nextBtn');
            flickityElem.prevButton.element.classList.add('flickity__prevBtn');
        }

        this.$refs.flickity.on('dragMove', this.startDragging);
        this.$refs.flickity.on('dragEnd', this.stoppDragging);
        this.$refs.flickity.on('change', (index) => {
            slider.$emit('slidechange', index);
        });

        // resize flickity after timeout so that slides don't overlap
        setTimeout(() => {
            this.$refs.flickity.resize();
        }, 1000);
    },

    created() {},

    methods: {
        startDragging() {
            this.dragging = true;
        },
        stoppDragging() {
            this.$nextTick(() => {
                this.dragging = false;
            });
        },
    },
};
