import Viewer360 from '../../_molecules/viewer360/_component.vue';

export default {
    name: 'Viewer360Container',

    components: {
        Viewer360,
    },

    data() {
        return {
            cn: 'o-viewer360Container',
        };
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        view360: {
            type: Object,
            default: () => null,
        },
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},
    
    methods: {},

    destroyed() {},
};
