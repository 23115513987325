import sendDocument from './sendDocument';

const sendDocumentForm = (context) => {
    const customer = {
        email: context.state.receiver.email,
        firstname: context.state.receiver.firstName,
        lastname: context.state.receiver.lastName,
        company: context.state.receiver.company,
        gender: context.state.receiver.gender,
    };

    const { message } = context.state.receiver;

    const documentArray = context.state.addedDocuments;

    return sendDocument(context, customer, message, documentArray);
};

export default sendDocumentForm;