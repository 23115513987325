import camelCase from 'camelcase';

const buildRelationsKey = (name, isArray) => {
    name = camelCase(name);
    if (isArray) name += 'List';
    return name;
};

const findObjectByUrl = (context, url) => {
    if (!url) {
        return { _loading: false, _empty: true };
    }

    context.dispatch('find', url);
    let foundObject = context.state.objects.filter(o => o.links.self === url);
    if (foundObject.length) {
        foundObject = foundObject[0];
    } else {
        foundObject = {};
    }
    const metaObject = {
        _loading: context.getters.isObjectLoading(url),
        _empty: typeof foundObject === 'undefined'
    };
    const result = Object.assign(foundObject, metaObject);
    return result;
};

const relationship = ({ object, context }) => {
    if (object) {
        object.rel = {};

        if (!Object.prototype.hasOwnProperty.call(object, 'relationships')) {
            object.relationships = {};
            return object;
        }

        // TODO: check eslint error
        for (const key in object.relationships) { // eslint-disable-line
            if (!Object.prototype.hasOwnProperty.call(object.relationships, key)) continue;
            const relationsKey = buildRelationsKey(key, Array.isArray(object.relationships[key]));

            const property = {
                get() {
                    const content = object.relationships[key];
                    if (Array.isArray(content)) {
                        const result = {};
                        for (let i = 0; i < content.length; i++) {
                            Object.defineProperty(result, i, {
                                enumerable: true,
                                get() {
                                    return findObjectByUrl(context, content[i]);
                                }
                            });
                        }
                        return result;
                    }
                    // not array
                    return findObjectByUrl(context, content);
                }
            };
            Object.defineProperty(object.rel, relationsKey, property);
            if (!Object.prototype.hasOwnProperty.call(object, relationsKey)) {
                Object.defineProperty(object, relationsKey, property);
            }
        }
        return object;
    }
};

export default relationship;
