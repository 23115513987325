import downloadSvg from '../../_svg/icon/download.vue';

export default {
    name: 'Download',

    components: {
        downloadSvg
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
        },
        file: {
            type: String,
        },
    },

    data() {
        return {
            cn: 'm-download',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        isDesktopApp() {
            return !!this.$store.state.desktopApp;
        }
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {},
};
