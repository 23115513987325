
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="50px" height="55px" viewBox="0 0 50 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="m/map/marker/single-inactive" transform="translate(7.000000, 1.000000)">
            <g transform="translate(2.500000, 9.000000)" id="Oval-Copy-9">
                <g>
                    <use fill="black" fill-opacity="1" filter="url(#filter-mappin)" xlink:href="#path-mappin1"></use>
                    <path stroke="#E11624" stroke-width="1.3" d="M15.5,35.1427587 C25.0837104,26.8638069 29.85,20.0915457 29.85,14.9210526 C29.85,7.04013798 23.4260518,0.65 15.5,0.65 C7.57394824,0.65 1.15,7.04013798 1.15,14.9210526 C1.15,20.0915457 5.91628963,26.8638069 15.5,35.1427587 Z" stroke-linejoin="square" fill="#161A1B" fill-rule="evenodd"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
export default {
    name: 'pin',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '50px',
      },
      height: {
        type: String,
        default: '55px',
      },
    },
    data() {
      return {
        cn: 'svg-pin',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-pin {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-pin--black {
    path {
      fill: c('black');
    }
  }

  .svg-pin--red {
    path {
      fill: c('red');
    }
  }

  .svg-pin--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-pin--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    