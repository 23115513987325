<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <g fill="none" fill-rule="evenodd" transform="translate(0 -6)">
        <polygon points="0 0 40 0 40 40 0 40" />
        <path fill="#E11624" fill-rule="nonzero" d="M2,22.6106735 L5.36363636,25.9541874 C13.7222727,17.6455554 27.2777273,17.6455554 35.6363636,25.9541874 L39,22.6106735 C28.7913636,12.4631088 12.2254545,12.4631088 2,22.6106735 Z M15.4545455,35.9847291 L20.5,41 L25.5454545,35.9847291 C22.7704545,33.2096126 18.2463636,33.2096126 15.4545455,35.9847291 Z M8.72727273,29.2977013 L12.0909091,32.6412152 C16.7327273,28.0271661 24.2672727,28.0271661 28.9090909,32.6412152 L32.2727273,29.2977013 C25.7809091,22.8447195 15.2359091,22.8447195 8.72727273,29.2977013 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'WifiOn',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationWifiOn',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationWifiOn {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationWifiOn--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationWifiOn--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationWifiOn--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationWifiOn--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
