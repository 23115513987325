import ImageWrapper from '../../_atoms/image/_component.vue';
import PrintItem from '../printItem/_component.vue';

export default {
    name: 'ApplicationDetailsPrintTemplate',

    components: {
        ImageWrapper,
        PrintItem,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        slides: {
            type: Array,
            default: () => [],
        },
        productName: {
            type: String,
            default: null,
        },
        facts: {
            type: Array,
            default: () => [],
        },
        productCategory: {
            type: String,
            default: null,
        },
        detailSliderBigImages: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-applicationPrintTemplate',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        factPairs() {
            console.log('getting here');
            const factPairs = [];
            for (let i = 0; i < this.facts.length; i += 2) {
                const factPair = [
                    this.facts[i],
                    this.facts[i + 1],
                ];
                factPairs.push(factPair);
            }
            return factPairs;
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {},
};
