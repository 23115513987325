var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewWrapper',{attrs:{"property":_vm.product}},[(_vm.product)?_c('DetailPage',{attrs:{"sections":_vm.sections},scopedSlots:_vm._u([{key:"default",fn:function(detailPage){return [_c('div',{ref:"productTemplate"},[_c('IntroSection',{attrs:{"id":"overview","title":_vm.product.title,"subline":_vm.product.content ? _vm.product.content.productSubline : null,"image":_vm.product.productImage,"scroll-function":function () { return detailPage.scrollToSection(1); }}}),_c('HighlightSection',{attrs:{"id":"view360","show-section":(_vm.product['three-d-renderings'].images && _vm.getArrayFrom(_vm.product['three-d-renderings'].images).length > 0) || _vm.webrotate360,"rendering":Object.assign({}, _vm.product.productRendering,
                            {images: _vm.product['three-d-renderings'] ? _vm.product['three-d-renderings'].images : [],
                            meta: _vm.product['three-d-renderings'] ? _vm.product['three-d-renderings'].meta : {},
                            thumbnail: _vm.product.threeDRenderingThumbnail}),"webrotate360":_vm.webrotate360 ? _vm.webrotate360.attributes.renderingPaths : null,"print-rendering":_vm.product.previewImage,"title":_vm.product.title,"subline":_vm.product.content ? _vm.product.content.productSubline : null}}),_c('HighlightSliderSection',{attrs:{"id":"highlights","show-section":!(_vm.product['three-d-renderings'].images && _vm.getArrayFrom(_vm.product['three-d-renderings'].images).length > 0) && !_vm.webrotate360 &&
                                  _vm.relationsNotEmpty(_vm.product, 'highlight'),"product-name":_vm.product.title,"highlights":_vm.getArrayFrom(_vm.product.highlightList)}}),_c('AdvantageSection',{attrs:{"id":"advantages","show-section":_vm.relationsNotEmpty(_vm.product, 'product-advantage'),"advantages":_vm.getArrayFrom(_vm.product.productAdvantageList),"advantage-categories":_vm.advantageCategories,"product-name":_vm.product.title,"mapping-function":function (advantage) { return ({
                            id: advantage.id,
                            title: advantage.title,
                            category: advantage.category,
                            image: advantage.productImage,
                            accordionItem: advantage.accordionItem,
                          }); },"mapping-function-overlay":function (advantage) { return (Object.assign({}, _vm.getVideoOrImageFrom(advantage),
                            {captionOverwrite: {
                              title: advantage.accordionItem && advantage.accordionItem[0] ?
                                advantage.accordionItem[0].accordionHeadline : null,
                              content: advantage.accordionItem && advantage.accordionItem[0] ?
                                advantage.accordionItem[0].accordionCopy : null,
                            }})); }}}),_vm._v(" "),_c('ApplicationSection',{attrs:{"id":"usecases","product-name":_vm.product.title,"application-introduction":_vm.product.content ? _vm.product.content.applicationsIntroduction : null,"show-section":_vm.relationsNotEmpty(_vm.product, 'product-application'),"applications":_vm.getArrayFrom(_vm.product.productApplicationList),"mapping-function":function (application) { return ({
                              id: application.id,
                              title: application.title,
                              category: application.categoryList &&
                                application.categoryList[0] ? application.categoryList[0].title : '',
                              image: application.productImage,
                              link: { name: 'product-application', params: { slug: application.slug }},
                            }); }}}),_c('CaseStudySection',{attrs:{"id":"caseStudies","product-name":_vm.product.title,"show-section":_vm.relationsNotEmpty(_vm.product, 'product-case-study'),"image":_vm.product.productImage,"case-studies":_vm.getArrayFrom(_vm.product.productCaseStudyList),"mapping-function":function (caseStudy) { return ({
                            id: caseStudy.id,
                            title: caseStudy.title,
                            category: caseStudy.categoryList &&
                              caseStudy.categoryList[0] ? caseStudy.categoryList[0].title : '',
                            image: (function () { return _vm.product.productImage; })(),
                            link: { name: 'case-study', params: { slug: caseStudy.slug }}
                          }); }}}),_c('TechnicalDataSection',{attrs:{"id":"technicalDetails","product-name":_vm.product.title,"show-section":(!!_vm.product.content.technicalDataTable &&
                                _vm.product.content.technicalDataTable.length > 0),"table":_vm.product.content.technicalDataTable,"table-columns":_vm.product.content.technicalDataTableColumns}}),_c('OptionSection',{attrs:{"id":"options","product-name":_vm.product.title,"show-section":_vm.relationsNotEmpty(_vm.product, 'productOption'),"option-slides":_vm.getArrayFrom(_vm.product.productOptionList) ? _vm.getArrayFrom(_vm.product.productOptionList).map(function (option, index) {
                         var slide = option.fullscreenSlides[0];
                         if (slide) {
                           return {
                             id: index,
                             title: option.title,
                             category: option.productOptionsCategoryList && option.productOptionsCategoryList[0] ? option.productOptionsCategoryList[0].title : null,
                             fullScreenTitle: slide.headline,
                             fullScreenCopy: slide.copy,
                             image: option.productOptionsImageList && option.productOptionsImageList[0] ? option.productOptionsImageList[0] : null,
                             media: option.productOptionsSlideVideo && !option.productOptionsSlideVideo._empty ? option.productOptionsSlideVideo : option.productOptionsSlideImage,
                             type: option.productOptionsSlideVideo && !option.productOptionsSlideVideo._empty ? 'asset-video' : 'asset-image',
                           };
                         }
                         return null;
                       }) : []}}),_c('TechnicalDocumentsSection',{attrs:{"id":"technicalDocuments","product-name":_vm.product.title,"show-section":_vm.relationsNotEmpty(_vm.product, 'product-downloads'),"downloads":_vm.getArrayFrom(_vm.product.productDownloadsList)}}),_c('FaqSection',{attrs:{"id":"faq","show-section":_vm.relationsNotEmpty(_vm.product, 'faq'),"faqs":_vm.getArrayFrom(_vm.product.faqList)}}),_c('RelatedContentSection',{attrs:{"id":"relatedContent","show-section":_vm.relationsNotEmpty(_vm.product, 'product-related-content'),"contents":_vm.getArrayFrom(_vm.product.productRelatedContentList),"mapping-function":function (content) { return ({
                                 id: content.id,
                                 title: content.title,
                                 category: content.category,
                                 image: content.productImage,
                                 link: _vm.getRelatedContentLinkFrom(content),
                               }); }}})],1)]}}],null,false,1687830788)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }