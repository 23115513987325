import ChevronIconDown from '../../_svg/icon/chevron/down.vue';
import ChevronIconLeft from '../../_svg/icon/chevron/left.vue';
import ChevronIconRight from '../../_svg/icon/chevron/right.vue';
import ChevronIconUp from '../../_svg/icon/chevron/up.vue';

export default {
    name: 'ChevronButton',

    components: {
        ChevronIconDown,
        ChevronIconLeft,
        ChevronIconRight,
        ChevronIconUp,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        direction: {
            type: String,
            default: 'down',
            validator: (value) => {
                // The value must match one of these strings
                return ['down', 'left', 'right', 'up'].indexOf(value) !== -1;
            }
        },
    },

    data() {
        return {
            cn: 'a-chevronButton',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    methods: {
        click(e) {
            this.$emit('click', e);
        },
    },
};
