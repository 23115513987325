const resetStatistics = (context) => {
    // eslint-disable-next-line
    console.log('reset statistics');
    
    context.commit('SET_downloadStateLoading', false);

    context.commit('SET_downloadStateObjectsTotal', 0);
    context.commit('SET_downloadStateObjectsDone', 0);

    context.commit('SET_downloadStateLoadingImagesTotal', 0);
    context.commit('SET_downloadStateLoadingImagesDone', 0);

    context.commit('SET_downloadStateLoadingVideosTotal', 0);
    context.commit('SET_downloadStateLoadingVideosDone', 0);

    context.commit('SET_downloadStateLoadingRenderingsTotal', 0);
    context.commit('SET_downloadStateLoadingRenderingsDone', 0);

    context.commit('SET_downloadStateLoadingWebrotate360Total', 0);
    context.commit('SET_downloadStateLoadingWebrotate360Done', 0);

    context.commit('SET_downloadStateLoadingPdfsTotal', 0);
    context.commit('SET_downloadStateLoadingPdfsDone', 0);
};

export default resetStatistics;