import {createNamespacedHelpers} from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import ProductsPrintTemplate from '../../_print/products/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ProductsSection',

    components: {
        CardSlider,
        SectionWrapper,
        ProductsPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        applicationName: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            cn: 'o-productsSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
    },
};
