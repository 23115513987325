<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <g fill="none" fill-rule="evenodd">
        <polygon points="0 0 40 0 40 40 0 40" />
        <path fill="#E11624" fill-rule="nonzero" d="M21.6666667,11.6666667 L18.3333333,11.6666667 L18.3333333,18.3333333 L11.6666667,18.3333333 L11.6666667,21.6666667 L18.3333333,21.6666667 L18.3333333,28.3333333 L21.6666667,28.3333333 L21.6666667,21.6666667 L28.3333333,21.6666667 L28.3333333,18.3333333 L21.6666667,18.3333333 L21.6666667,11.6666667 Z M20,3.33333333 C10.8,3.33333333 3.33333333,10.8 3.33333333,20 C3.33333333,29.2 10.8,36.6666667 20,36.6666667 C29.2,36.6666667 36.6666667,29.2 36.6666667,20 C36.6666667,10.8 29.2,3.33333333 20,3.33333333 Z M20,33.3333333 C12.65,33.3333333 6.66666667,27.35 6.66666667,20 C6.66666667,12.65 12.65,6.66666667 20,6.66666667 C27.35,6.66666667 33.3333333,12.65 33.3333333,20 C33.3333333,27.35 27.35,33.3333333 20,33.3333333 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'Add',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationAdd',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationAdd {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationAdd--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationAdd--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationAdd--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationAdd--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
