import checkForNewNotifications from './methods/checkForNewNotifications';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import UnauthorizedError from '../../../helpers/errors/UnauthorizedError';
import registerError from '../../../helpers/registerError';

const module = {

    namespaced: true,

    state: {
        currentNotifications: [],
    },

    mutations: {
        ADD_notification(state, notification) {
            if (notification.singleNotificationKey &&
                state.currentNotifications.filter(n => n.singleNotificationKey === notification.singleNotificationKey).length > 0) {
                return;
            }

            state.currentNotifications.push(notification);
        },
        REMOVE_notification(state, notificationId) {
            const index = state.currentNotifications
                .map(notification => notification.id)
                .indexOf(notificationId);

            if (index >= 0) {
                state.currentNotifications.splice(index, 1);
            }
        },
        REMOVE_updateNotifications(state) {
            state.currentNotifications = state.currentNotifications.filter(
                (notification) => !notification.deleteOnClose
            );
        },
    },

    getters: {},

    actions: {
        addNotification(context, notification)  {
            context.commit('ADD_notification', notification);
        },
        removeNotification(context, notificationId)  {
            context.commit('REMOVE_notification', notificationId);
        },
        async checkForNewNotifications(context) {
            const internetAvailable = context.rootState.base.isInternetAvailable;
            if (internetAvailable) {
                try {
                    const newNotifications = await checkForNewNotifications(context);
                    newNotifications.map(notification => ({
                        id: `${notification.id}`,
                        icon: notification.attributes.icon,
                        text: notification.attributes.text,
                        action: notification.attributes.action,
                        actionText: notification.attributes.actionText,
                        linkedEntrySlug: notification.attributes.linkedEntrySlug,
                        linkedSectionType: notification.attributes.linkedSectionType,
                    })).forEach((notification) => {
                        context.commit('ADD_notification', notification);
                    });
                } catch (error) {
                    if (error instanceof InternetConnectionError) {
                        // internet connection was interrupted
                        // do nothing and try later
                    } else if (error instanceof UnauthorizedError) {
                        // user is not logged in
                        // do nothing and try later
                    } else {
                        // there is nothing we can do here,
                        // just report the error
                        registerError('Error checking for new notifications', error);
                    }
                }
            }
        },
    }
};

export default module;
