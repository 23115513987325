import AutomationPage from '../../views/automationDetail/_component.vue';
import ProductLinePage from '../../views/productLineDetail/_component.vue';

const routes = [
    {
        path: '/product-line/:slug',
        name: 'product-line',
        component: ProductLinePage,
        meta: {
            pdfRenderer: {
                enabled: true,
                title: '',
            },
            requiresAuth: true,
            isPrintable: true,
            showTitleInHeader: true,
            documentAddable: true,
        },
    },
    {
        path: '/product-line-automation/:slug',
        name: 'product-line-automation',
        component: AutomationPage,
        meta: {
            requiresAuth: true,
        }
    },
];

export default routes;