
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 53.1 (72631) - https://sketchapp.com -->
    <title>884CA79F-3097-4199-8277-DAC53D1403B9@1.00x</title>
    <desc>Created with sketchtool.</desc>
    <g id="↳-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icon/docs/nav/normal" fill="#161A1B" fill-rule="nonzero">
            <path d="M14,28 L26,28 L26,17 L21,12 L14,12 L14,28 Z M12,10 L22,10 L28,16 L28,30 L12,30 L12,10 Z M28,16 L28,17 L21,17 L21,10 L22,10 L28,16 Z" id="Combined-Shape" transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "></path>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
export default {
    name: 'docs_normal',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-docs_normal',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-docs_normal {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-docs_normal--black {
    path {
      fill: c('black');
    }
  }

  .svg-docs_normal--red {
    path {
      fill: c('red');
    }
  }

  .svg-docs_normal--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-docs_normal--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    