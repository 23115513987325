const getArrayChunks = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize)
        result.push(arr.slice(i, i + chunkSize));
    
    return result;
};


const getArrayFrom = (object) => {
    if (object) {
        return Object.values(object);
    }
    return null;
};


const getRelatedContentLinkFrom = (content) => {
    switch (content.category) {
        case 'products': {
            return { name: 'product', params: { slug: content.slug }};
        }
        case 'productLines': {
            return { name: 'product-line', params: { slug: content.slug }};
        }
        case 'productApplications': {
            return { name: 'product-application', params: { slug: content.slug }};
        }
        case 'caseStudies': {
            return { name: 'case-study', params: { slug: content.slug }};
        }
        case 'pages': {
            return { name: 'content-page', params: { slug: content.slug }};
        }
        default: {
            return { name: 'home' };
        }
    }
};

/* eslint-disable */
const getHomeTeaserLinkFromEntry = (entry, isDesktopApp) => {
    switch (entry.type) {
        case 'product': {
            if (isDesktopApp) {
                return `#/product/${entry.slug}`;
            }
            return `product/${entry.slug}`;
        }
        case 'product-line': {
            if (isDesktopApp) {
                return `#/product-line/${entry.slug}`;
            }
            return `product-line/${entry.slug}`;
        }
        case 'product-application': {
            if (isDesktopApp) {
                return `#/product-application/${entry.slug}`;
            }
            return `product-application/${entry.slug}`;
        }
        case 'case-study': {
            if (isDesktopApp) {
                return `#/case-study/${entry.slug}`;
            }
            return `case-study/${entry.slug}`;
        }
        case 'content-page': {
            if (isDesktopApp) {
                return `#/content-page/${entry.slug}`;
            }
            return `content-page/${entry.slug}`;
        }
        default: {
            return '';
        }
    };
};
/* eslint-enable */

const getHomeTeaserLinkFromAsset = (asset, isOffline) => {
    if (isOffline) {
        return asset.offlineLink;
    }

    return asset.storageUrl;
};

const transformHelper = {
    getArrayChunks,
    getArrayFrom,
    getRelatedContentLinkFrom,
    getHomeTeaserLinkFromEntry,
    getHomeTeaserLinkFromAsset,
};

export default transformHelper;