const logout = (context) => {
    return new Promise((resolve, reject) => {
        const url = `${context.rootState.base.http.baseUrl}auth/logout`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${context.state.token}`,
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            }
        }).then((response) => {
            context.state.isCheckingAuthentication = false;
            if (response.ok) {
                context.dispatch('logoutOnlyFrontend');

                if (window.app.$router.currentRoute.name !== 'login') {
                    window.app.$router.push({ name: 'login' });
                }

                resolve();
            } else {
                reject();
            }
        });
    });
};

export default logout;
