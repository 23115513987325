const resetPassword = (context, {id, code, newPassword, newPasswordConfirmation}) => {
    return new Promise((resolve, reject) => {
        fetch(`${context.rootState.base.http.baseUrl}auth/password-reset/set-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            },
            body: JSON.stringify({
                'id': id,
                'code': code,
                'newPassword': newPassword,
                'newPassword_confirmation': newPasswordConfirmation,
            })
        }).then((response) => {
            return response.json().then(body => {
                if (response.ok) {
                    resolve();
                } else {
                    reject(body.data.errors);
                }
            });
        });
    });
};

export default resetPassword;
