import sendDocumentForm from './methods/sendDocumentForm';
import sendQueuedDocument from './methods/sendQueuedDocument';
import checkDocumentState from './methods/checkDocumentState';
import registerError from '../../../helpers/registerError';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import UnprocessableEntityError from '../../../helpers/errors/UnprocessableEntityError';
import UnauthorizedError from '../../../helpers/errors/UnauthorizedError';

import {
    DOCUMENT_LOADING_POLL_TIME_MS,
    DOCUMENT_PROCESS_TIME_BEFORE_RETRY_MS,
} from '../../../../settings';

const module = {

    namespaced: true,

    state: {
        addedDocuments: [],
        currentDocument: null,
        lastSectionId: null,
        receiver: {
            gender: 'male',
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            message: '',
        },
        documentsToSendQueue: [],
        generatingDocumentsQueue: [],
        addedDocument: null,
    },

    mutations: {
        ADD_document(state, document) {
            state.addedDocuments.push(document);
        },
        DELETE_document(state, documentId) {
            const index = state.addedDocuments.map(document => document.id).indexOf(documentId);
            if (index >= 0) {
                state.addedDocuments.splice(index, 1);
            }
        },
        SET_currentDocument(state, document) {
            state.currentDocument = document;
        },
        SET_lastSectionId(state, id) {
            state.lastSectionId = id;
        },
        SET_addedDocuments(state, documents) {
            state.addedDocuments = documents;
        },
        SET_addedDocument(state, document) {
            state.addedDocument = document;
        },
        SET_receiver_gender(state, gender) {
            state.receiver.gender = gender;
        },
        SET_receiver_firstName(state, firstName) {
            state.receiver.firstName = firstName;
        },
        SET_receiver_lastName(state, lastName) {
            state.receiver.lastName = lastName;
        },
        SET_receiver_company(state, company) {
            state.receiver.company = company;
        },
        SET_receiver_email(state, email) {
            state.receiver.email = email;
        },
        SET_receiver_message(state, message) {
            state.receiver.message = message;
        },
        CLEAR_documentData(state) {
            state.addedDocuments = [];
            state.receiver = {
                gender: 'male',
                firstName: '',
                lastName: '',
                company: '',
                email: '',
                message: '',
            };
        },
        QUEUE_document(state, document) {
            state.documentsToSendQueue.push(document);
        },
        REMOVE_documentFromQueue(state, document) {
            const index = state.documentsToSendQueue.indexOf(document);
            if (index >= 0) {
                state.documentsToSendQueue.splice(index, 1);
            }
        },
        QUEUE_generatingDocument(state, hash) {
            state.generatingDocumentsQueue.push(hash);
        },
        REMOVE_documentFromGeneratingQueue(state, hash) {
            const index = state.generatingDocumentsQueue.indexOf(hash);
            if (index >= 0) {
                state.generatingDocumentsQueue.splice(index, 1);
            }
        },
    },

    getters: {
        getLength(state) {
            return state.addedDocuments.length;
        },
        isAdded(state) {
            return documentId => state.addedDocuments.map(document => document.id).indexOf(documentId) >= 0;
        },
        isGenerating(state) {
            return state.generatingDocumentsQueue.length > 0;
        },
    },

    actions: {
        add(context, document)  {
            context.commit('ADD_document', document);
            context.commit('SET_addedDocument', document);
        },
        remove(context, documentId)  {
            context.commit('DELETE_document', documentId);
        },
        resetAddedDocument(context) {
            context.commit('SET_addedDocument', null);
        },
        setCurrentDocumentTo(context, document)  {
            context.commit('SET_currentDocument', document);
        },
        setLastSectionId(context, id)  {
            context.commit('SET_lastSectionId', id);
        },
        setDocuments(context, documents)  {
            context.commit('SET_addedDocuments', documents);
        },
        // set receiver
        setGenderOfReceiver(context, gender) {
            context.commit('SET_receiver_gender', gender);
        },
        setFirstNameOfReceiver(context, firstName) {
            context.commit('SET_receiver_firstName', firstName);
        },
        setLastNameOfReceiver(context, lastName) {
            context.commit('SET_receiver_lastName', lastName);
        },
        setCompanyOfReceiver(context, company) {
            context.commit('SET_receiver_company', company);
        },
        setEmailOfReceiver(context, email) {
            context.commit('SET_receiver_email', email);
        },
        setMessageOfReceiver(context, message) {
            context.commit('SET_receiver_message', message);
        },
        async sendDocuments(context) {
            const internetAvailable = context.rootState.base.isInternetAvailable;
            if (internetAvailable) {
                try {
                    const hash = await sendDocumentForm(context);
                    context.commit('QUEUE_generatingDocument', hash);
                    context.commit('CLEAR_documentData');
                    context.dispatch('checkLoadingDocument', hash);
                } catch (error) {
                    if (error instanceof UnprocessableEntityError) {
                        // throw error for error messages
                        // errors should be resolved by user
                        throw error;
                    } else if (error instanceof InternetConnectionError) {
                        // internet connection was interrupted
                        // queue document and try later
                        context.dispatch('queueDocument');
                    } else if (error instanceof UnauthorizedError) {
                        // user not logged in
                        // queue document and try later
                        context.dispatch('queueDocument');
                    } else {
                        // there is nothing we can do here,
                        // just report the error
                        registerError('Could not send document', error);
                        context.commit('CLEAR_documentData');
                    }
                }
            } else {
                context.dispatch('queueDocument');
            }
        },
        queueDocument(context) {
            // eslint-disable-next-line
            context.commit('QUEUE_document', {
                addedDocuments: context.state.addedDocuments,
                receiver: context.state.receiver,
            });
            context.commit('CLEAR_documentData');
        },
        async sendQueuedDocument(context, document) {
            const currentDateMs = new Date().getTime();
            if (document.isProcessed && document.processStartDate) {
                const timeSinceProcessingStarted = currentDateMs - document.processStartDate;
                if (timeSinceProcessingStarted < DOCUMENT_PROCESS_TIME_BEFORE_RETRY_MS) {
                    return;
                }
            }

            document.isProcessed = true;
            document.processStartDate = currentDateMs;

            try {
                const hash = await sendQueuedDocument(context, document);
                context.commit('QUEUE_generatingDocument', hash);
                context.commit('REMOVE_documentFromQueue', document);
                context.dispatch('checkLoadingDocument', hash);
            } catch (error) {
                if (error instanceof InternetConnectionError) {
                    document.isProcessed = false;
                    // internet connection was interrupted
                    // do nothing and try later
                } else if (error instanceof UnauthorizedError) {
                    document.isProcessed = false;
                    // user is not logged in
                    // do nothing and try later
                } else {
                    // there is nothing we can do here,
                    // just report the error
                    // and delete the document from the queue
                    registerError('Could not send document', error);
                    context.commit('REMOVE_documentFromQueue', document);
                }
            }
        },
        sendDocumentQueue(context) {
            const internetAvailable = context.rootState.base.isInternetAvailable;
            if (internetAvailable && context.state.documentsToSendQueue.length > 0) {
                context.state.documentsToSendQueue.forEach((document => {
                    context.dispatch('sendQueuedDocument', document);
                }));
            }
        },
        async checkLoadingDocument(context, hash) {
            try {
                const isDocumentDone = await checkDocumentState(context, hash);

                if (isDocumentDone) {
                    context.commit('REMOVE_documentFromGeneratingQueue', hash);
                } else {
                    setTimeout(() => {
                        context.dispatch('checkLoadingDocument', hash);
                    }, DOCUMENT_LOADING_POLL_TIME_MS);
                }
            } catch (error) {
                if (error instanceof InternetConnectionError) {
                    // internet connection was interrupted
                    // do nothing and try later
                } else if (error instanceof UnauthorizedError) {
                    // user is not logged in
                    // do nothing and try later
                } else {
                    // there is nothing we can do here,
                    // just report the error
                    // and delete the hash from the queue
                    registerError('Error checking document creation state', error);
                    context.commit('REMOVE_documentFromGeneratingQueue', hash);
                }
            }
        },
        checkLoadingDocuments(context) {
            const internetAvailable = context.rootState.base.isInternetAvailable;
            if (internetAvailable && context.state.generatingDocumentsQueue.length > 0) {
                context.state.generatingDocumentsQueue.forEach((hash => {
                    context.dispatch('checkLoadingDocument', hash);
                }));
            }
        },
    }
};

export default module;
