import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import BulletPoints from '../contentBuilderSection/modules/bulletPoints/_component.vue';
import ImageComponent from '../../_atoms/image/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'LandingPageFASection',

    components: {
        SectionWrapper,
        BulletPoints,
        ImageComponent,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        functionsHeadline: {
            type: String,
            default: '',
        },
        functions: {
            type: Array,
            default: () => [],
        },
        functionsImage: {
            type: Object,
            default: () => null,
        },
        advantagesHeadline: {
            type: String,
            default: '',
        },
        advantages: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-landingPageFASection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...viewConditionHelper,
    },
};
