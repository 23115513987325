import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import SliderOverlay from '../../_organisms/sliderOverlay/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';
import TechnicalDataImagesPrintTemplate from '../../_print/technicalDataImages/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'OptionSection',

    components: {
        SectionWrapper,
        CardSlider,
        SliderOverlay,
        IconClose,
        TechnicalDataImagesPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        headline: {
            type: String,
            default: null,
        },
        optionSlides: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-optionSection',
            showOverlay: false,
            activeSliderItem: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
        slides() {
            const slides = this.optionSlides.map((slide) => {
                if (slide) {
                    slide.callback = this.openSliderOverlay;
                    return slide;
                }
                return null;
            });
            return slides;
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...viewConditionHelper,
        openSliderOverlay(id) {
            if (!this.isPrinting) {
                this.activeSliderItem = this.getIndexOfCardWith(id);
                this.showOverlay = true;
                this.$store.dispatch('base/lockBody');
            }
        },
        closeOverlay() {
            this.activeSliderItem = null;
            this.showOverlay = false;
            this.$store.dispatch('base/freeBody');
        },
        getIndexOfCardWith(id) {
            if (!this.isPrinting) {
                const { slides } = this.$refs.imageSlider;
                const currentSlide = slides.filter(slide => slide.id === id)[0];
                return currentSlide ? currentSlide.id : null;
            }
        },
    },
};
