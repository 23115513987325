import {createNamespacedHelpers} from 'vuex';
import {viewConditionHelper} from '../../../helpers/dataHelper';
import AdvantagesPrintTemplate from '../../_print/advantages/_component.vue';
import CardSliderWithFilter from '../../_organisms/cardSliderWithFilter/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import SliderOverlay from '../../_organisms/sliderOverlay/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'AdvantageSection',

    components: {
        AdvantagesPrintTemplate,
        CardSliderWithFilter,
        SectionWrapper,
        SliderOverlay,
        IconClose,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        advantages: {
            type: Array,
            default: () => [],
        },
        advantageCategories: {
            type: Array,
            default: () => null,
        },
        mappingFunction: {
            type: Function,
            required: true,
        },
        mappingFunctionOverlay: {
            type: Function,
            required: true,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-advantageSection',
            showOverlay: false,
            activeSliderItem: null,
            filterLabel: 'default',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
        filteredAdvantages() {
            return this.filterAdvantagesBy(this.filterLabel);
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
        openSliderOverlay(id) {
            this.activeSliderItem = this.getIndexOfCardWith(id);
            this.showOverlay = true;
            this.$store.dispatch('base/lockBody');
        },
        closeOverlay() {
            this.activeSliderItem = null;
            this.showOverlay = false;
            this.$store.dispatch('base/freeBody');
            this.filterLabel = 'default';
        },
        getIndexOfCardWith(id) {
            const { filteredSlides } = this.$refs.filterSlider;
            return filteredSlides.map(
                (advantage, index) => ({
                    id: advantage.id,
                    index,
                })
            ).filter(
                advantage => advantage.id === id
            ).map(advantage => advantage.index)[0];
        },
        filterAdvantagesBy(label) {
            if (label === 'default') {
                return this.advantages;
            }
            return this.advantages.filter(
                advantage => (advantage.category === label) ||
                             (advantage.advantageCategory && advantage.advantageCategory.title === label)
            );
        },
        filterCallback(label) {
            this.filterLabel = label;
        }
    },
};
