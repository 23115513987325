import { createNamespacedHelpers } from 'vuex';
import { Actions as MediaLibraryActions, Getters as MediaLibraryGetters } from '../../../vuex/modules/medialibrary/module';

const mediaLibraryMapper = createNamespacedHelpers('medialibrary');

export default {
    name: 'MediaTypeToggle',

    components: {
    },

    props: {},

    data() {
        return {
            cn: 'o-mediaTypeToggle',
            busy: false,
        };
    },

    computed: {
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_ACTIVE_MEDIA_TYPE]),
        isActive() {
            return type => this[MediaLibraryGetters.GET_ACTIVE_MEDIA_TYPE] === type;
        }
    },

    watch: {},

    mounted() {
    },

    created() {
    },

    methods: {
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.SWITCH_MEDIA_TYPE]),
        display(type) {
            this[MediaLibraryActions.SWITCH_MEDIA_TYPE](type);
        }
    },
};
