import Flickity from 'vue-flickity'; // eslint-disable-line
import Card from '../../_molecules/card/_component.vue';

export default {
    name: 'CardSlider',

    components: {
        Flickity,
        Card,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        cardType: {
            type: String,
            default: '',
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-cardSlider',
            flickityOptions: {
                groupCells: '80%',
                contain: true,
                cellAlign: 'left',
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                dragThreshold: 3,
                prevNextButtons: true,
                pageDots: false,
                imagesLoaded: true,
                resize: true,
            },
            selectedAdvantageForOverlay: '0',
            showAdvantagesOverlay: false,
            showSlider: true,
            dragging: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        cardModifiers() {
            const modifiers = [];
            if (this.cardType) {
                modifiers.push(this.cardType);
            }
            if (this.modifiers.includes('squaredSmall')) {
                modifiers.push('squaredSmall');
            }

            return modifiers;
        }
    },

    watch: {
        slides() {
            // guarantee that slider is destroyed and reinitialized
            const wrapper = this.$refs.sliderWrapper;
            wrapper.style.transitionDuration = '0s';
            wrapper.style.opacity = '0';

            this.showSlider = false;
            this.$nextTick(() => {
                this.showSlider = true;
                this.$nextTick(() => {
                    if (this.showSlider) {
                        const flickityElem = this.$refs.flickity.$flickity;
                        flickityElem.nextButton.element.classList.add('flickity__nextBtn');
                        flickityElem.prevButton.element.classList.add('flickity__prevBtn');
                        this.$refs.flickity.on('dragMove', this.startDragging);
                        this.$refs.flickity.on('dragEnd', this.stoppDragging);
                    }
                });

                setTimeout(() => {
                    wrapper.style.transitionDuration = '0.5s';
                    wrapper.style.opacity = '1';
                }, 500);
            });
        },
    },

    mounted() {
        if (this.slides && this.slides.length > 0) {
            const flickityElem = this.$refs.flickity.$flickity;
            flickityElem.nextButton.element.classList.add('flickity__nextBtn');
            flickityElem.prevButton.element.classList.add('flickity__prevBtn');

            this.$refs.flickity.on('dragMove', this.startDragging);
            this.$refs.flickity.on('dragEnd', this.stoppDragging);
        }
    },

    created() {
    },

    methods: {
        startDragging() {
            this.dragging = true;
            document.ontouchmove = (e) => {
                e.preventDefault();
            };
        },
        stoppDragging() {
            this.$nextTick(() => {
                this.dragging = false;
                document.ontouchmove = () => {
                    return true;
                };
            });
        },
    },
};
