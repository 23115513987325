import { createNamespacedHelpers } from 'vuex';
import LocationCard from '../../_molecules/locationCard/_component.vue';
import ImageVideoGallery from '../imageVideoGallery/_component.vue';
import OutputTables from '../outputTablesProductLine/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Accordion',

    components: {
        ImageVideoGallery,
        LocationCard,
        OutputTables,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            required: true,
        },
        chevrons: {
            type: Boolean,
            default: false,
        },
        openFirst: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            cn: 'o-accordion',
            openStates: [],
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {
        this.items.forEach((item, index) => {
            this.openStates.push(index === 0 && this.openFirst);
        });
    },

    created() {
    },

    methods: {
        getHeadlineFor(item) {
            if (this.isPrinting) {
                let headline = item.accordionHeadline.replace('<br>', ' ');
                headline = headline.replace('<br/>', ' ');
                headline = headline.replace('<br />', ' ');
                return headline;
            }
            return item.accordionHeadline;
        },
        toggleItem(clickedIndex) {
            if (this.modifiers.includes('cards')) {
                this.$set(this.openStates, clickedIndex, !this.openStates[clickedIndex]);
            } else {
                this.openStates.forEach((state, index) => {
                    if (index === clickedIndex) {
                        this.$set(this.openStates, index, !state);
                    } else {
                        this.$set(this.openStates, index, false);
                    }
                });
            }
        },
        closeAll() {
            this.openStates.forEach((state, index) => {
                this.$set(this.openStates, index, false);
            });
        },
    },
};