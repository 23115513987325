export default {
    name: 'contentPage',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        sections: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-contentPage',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        lastSectionId() {
            const lastSection = this.sections[this.sections.length - 1];
            return lastSection ? lastSection.id : '';
        },
    },

    watch: {
        lastSectionId() {
            this.$store.dispatch('documents/setLastSectionId', this.lastSectionId);
        },
    },
	
    mounted() {},
	
    created() {},
	
    methods: {
        scrollToSection(index) {
            if (this.sections && this.sections[index]) {
                this.sections[index].scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
    },

    destroyed() {},
};
