
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="↳-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icon/docs/nav/added/white">
            <path d="M27,16.9999 C24.791,16.9999 23,15.2089 23,12.9999 C23,10.7909 24.791,8.9999 27,8.9999 C29.209,8.9999 31,10.7909 31,12.9999 C31,15.2089 29.209,16.9999 27,16.9999" id="Fill-1" fill="#E11624"></path>
            <path d="M27,19.9999 C26.661,19.9999 26.327,19.9759 26,19.9289 L26,22.9999 L21,22.9999 L21,27.9999 L14,27.9999 L14,11.9999 L20.071,11.9999 C20.172,11.2939 20.378,10.6219 20.674,9.9999 L12,9.9999 L12,29.9999 L21,29.9999 L22,29.9999 L28,23.9999 L28,22.9999 L28,19.9289 C27.673,19.9759 27.339,19.9999 27,19.9999" id="Fill-4" fill="#FFFFFF"></path>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
export default {
    name: 'docs_bubble',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-docs_bubble',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-docs_bubble {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-docs_bubble--black {
    path {
      fill: c('black');
    }
  }

  .svg-docs_bubble--red {
    path {
      fill: c('red');
    }
  }

  .svg-docs_bubble--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-docs_bubble--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    