import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import DocumentsDragDropListItem from '../../_molecules/documentsDragDropListItem/_component.vue';
import IconChevronRight from '../../_svg/icon/chevron/right.vue';
import IconPlus from '../../_svg/icon/plus.vue';
import IconMinus from '../../_svg/icon/minus.vue';
import NumberCircle from '../../_atoms/numberCircle/_component.vue';

export default {
    name: 'DocumentsDragDropList',

    components: {
        IconChevronRight,
        DocumentsDragDropListItem,
        IconPlus,
        IconMinus,
        NumberCircle,
        draggable,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        sidebarOpen: {
            type: Boolean,
            deaulft: false,
        }
    },

    data() {
        return {
            cn: 'o-documentsDragDropList',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        documents: {
            get() {
                return this.$store.state.documents.addedDocuments;
            },
            set(documents) {
                this.$store.dispatch('documents/setDocuments', documents);
            },
        },
        ...mapGetters({
            documentCount: 'documents/getLength',
        }),
    },

    watch: {

    },

    mounted() {},

    created() {},

    methods: {
        toggleSidebar() {
            this.$parent.toggleSidebar();
        },
        goToForm() {
            this.$router.push({ name: 'document-form' });
        }
    },
};
