import { createNamespacedHelpers } from 'vuex';
import { Getters as MediaLibraryGetters, Actions as MediaLibraryActions } from '../../../vuex/modules/medialibrary/module';
import ChevronIconDown from '../../_svg/icon/chevron/down.vue';
import CloseIcon from '../../_svg/icon/nav/close.vue';

const mediaLibraryMapper = createNamespacedHelpers('medialibrary');

export default {
    name: 'MediaFilters',

    components: {
        ChevronIconDown,
        CloseIcon
    },

    props: {},

    data() {
        return {
            cn: 'o-mediaFilters',
            busy: false,
            openCategory: undefined,
            activeCategories: []
        };
    },

    computed: {
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_MEDIA_FILTERS_AVAILABLE]),
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_MEDIA_FILTERS_ACTIVE]),
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_MEDIA_FILTERS_CATEGORIES]),
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_ARE_ALL_ACTIVE_FILTERS_FOR_CATEGORY]),
        isActivated() {
            return id => !!this[MediaLibraryGetters.GET_MEDIA_FILTERS_ACTIVE].find(activeFilter => activeFilter.id === id)
        },
        hasChildren() {
            return item => item.children.length > 0
        }
    },

    mounted() {
    },

    created() {
    },

    methods: {
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.ACTIVATE_FILTER]),
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.DEACTIVATE_FILTER]),
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.ACTIVATE_CATEGORY]),
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.DEACTIVATE_CATEGORY]),

        toggleFilter(id, parent, type) {
            let index = this.activeCategories.indexOf(parent.id);
            if (this.isActivated(id)) {
                if (index > -1) {
                    this.activeCategories.splice(index, 1);
                }
                this[MediaLibraryActions.DEACTIVATE_FILTER]({ id, parent, type });
            } else {
                if (index === -1) {
                    this.activeCategories.push(parent.id);
                }
                this[MediaLibraryActions.ACTIVATE_FILTER]({ id, parent, type });
            }
        },
        toggleAll(id, parent, type) {
            if (this.isActivated(id)) {
                this[MediaLibraryActions.DEACTIVATE_FILTER](id);
            } else {
                this[MediaLibraryActions.ACTIVATE_FILTER]({ id, parent, type });
            }
        },
        toggleCategory(id) {
            this.openCategory = this.openCategory === id ? undefined : id;
        },
        toggleAllFiltersForCategory(categoryFilter, identifier) {
            let index = this.activeCategories.indexOf(categoryFilter.id);
            if (this[MediaLibraryGetters.GET_ARE_ALL_ACTIVE_FILTERS_FOR_CATEGORY](categoryFilter)) {
                if (index > -1) {
                    this.activeCategories.splice(index, 1);
                }
                this[MediaLibraryActions.DEACTIVATE_CATEGORY]({
                    filter: categoryFilter,
                    identifier
                });
            } else {
                if (index === -1) {
                    this.activeCategories.push(categoryFilter.id);
                }
                this[MediaLibraryActions.ACTIVATE_CATEGORY]({
                    filter: categoryFilter,
                    identifier
                });
            }
        },
    },
};
