import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../i18n';
import offlineHttpClient from './methods/offline-http-client';
import DownloadManager from '../../../services/DownloadManager';
import deleteLocalStorageFiles from './methods/deleteLocalStorageFiles';
import resetStatistics from './methods/resetStatistics';
import handleOfflineError from './methods/handleOfflineError';
import retryDownloadingAfterError from './methods/retryDownloadingAfterError';
import isUpdating from './methods/isUpdating';
import registerError from '../../../helpers/registerError';
import getTimeString from '../../../helpers/getTimeString';
import packageJson from '../../../../package.json';
import { KEEP_LOGGEDIN_DAYS, KEEP_LOGGEDIN_INTERVAL_MS } from '../../../../settings';
import handleOfflineLogoutNotifications from './methods/handleOfflineLogoutNotifications';
import checkApiVersion from './methods/check-api-version';

const electron = require('electron');
const { ipcRenderer } = require('electron');

const module = {
    namespaced: true,
    state: {
        isOffline: false,
        appVersion: null,
        offlineHttpClient: null,
        error: null,
        errorType: null,

        offlineTimestamp: null,

        downloadStateLoading: false,

        downloadStateObjectsTotal: 0,
        downloadStateObjectsDone: 0,

        downloadStateLoadingImagesTotal: 0,
        downloadStateLoadingImagesDone: 0,

        downloadStateLoadingVideosTotal: 0,
        downloadStateLoadingVideosDone: 0,

        downloadStateLoadingPdfsTotal: 0,
        downloadStateLoadingPdfsDone: 0,

        downloadStateLoadingRenderingsTotal: 0,
        downloadStateLoadingRenderingsDone: 0,

        downloadStateLoadingWebrotate360Total: 0,
        downloadStateLoadingWebrotate360Done: 0,

        isRestoring: false,
    },
    mutations: {
        SET_isOffline(state, value) {
            state.isOffline = value;
        },
        SET_appVersion(state, version) {
            state.appVersion = version;
        },
        SET_error(state, err) {
            state.error = err;
        },
        SET_errorType(state, type) {
            state.errorType = type;
        },
        SET_offlineHttpClient(state, input) {
            state.offlineHttpClient = input;
        },
        SET_offlineTimestamp(state, input) {
            state.offlineTimestamp = input;
        },
        SET_downloadStateLoading(state, value) {
            state.downloadStateLoading = value;
        },
        SET_downloadStateObjectsTotal(state, value) {
            state.downloadStateObjectsTotal = value;
        },
        INCREASE_downloadStateObjectsTotal(state) {
            state.downloadStateObjectsTotal += 1;
        },
        SET_downloadStateObjectsDone(state, value) {
            state.downloadStateObjectsDone = value;
        },
        INCREASE_downloadStateObjectsDone(state) {
            state.downloadStateObjectsDone += 1;
        },
        SET_downloadStateLoadingImagesTotal(state, value) {
            state.downloadStateLoadingImagesTotal = value;
        },
        INCREASE_downloadStateLoadingImagesTotal(state) {
            state.downloadStateLoadingImagesTotal += 1;
        },
        SET_downloadStateLoadingImagesDone(state, value) {
            state.downloadStateLoadingImagesDone = value;
        },
        INCREASE_downloadStateLoadingImagesDone(state) {
            state.downloadStateLoadingImagesDone += 1;
        },
        SET_downloadStateLoadingVideosTotal(state, value) {
            state.downloadStateLoadingVideosTotal = value;
        },
        INCREASE_downloadStateLoadingVideosTotal(state) {
            state.downloadStateLoadingVideosTotal += 1;
        },
        SET_downloadStateLoadingVideosDone(state, value) {
            state.downloadStateLoadingVideosDone = value;
        },
        INCREASE_downloadStateLoadingVideosDone(state) {
            state.downloadStateLoadingVideosDone += 1;
        },
        SET_downloadStateLoadingRenderingsTotal(state, value) {
            state.downloadStateLoadingRenderingsTotal = value;
        },
        INCREASE_downloadStateLoadingRenderingsTotal(state) {
            state.downloadStateLoadingRenderingsTotal += 1;
        },
        SET_downloadStateLoadingRenderingsDone(state, value) {
            state.downloadStateLoadingRenderingsDone = value;
        },
        INCREASE_downloadStateLoadingRenderingsDone(state) {
            state.downloadStateLoadingRenderingsDone += 1;
        },
        SET_downloadStateLoadingWebrotate360Total(state, value) {
            state.downloadStateLoadingWebrotate360Total = value;
        },
        INCREASE_downloadStateLoadingWebrotate360Total(state) {
            state.downloadStateLoadingWebrotate360Total += 1;
        },
        SET_downloadStateLoadingWebrotate360Done(state, value) {
            state.downloadStateLoadingWebrotate360Done = value;
        },
        INCREASE_downloadStateLoadingWebrotate360Done(state) {
            state.downloadStateLoadingWebrotate360Done += 1;
        },
        SET_downloadStateLoadingPdfsTotal(state, value) {
            state.downloadStateLoadingPdfsTotal = value;
        },
        INCREASE_downloadStateLoadingPdfsTotal(state) {
            state.downloadStateLoadingPdfsTotal += 1;
        },
        SET_downloadStateLoadingPdfsDone(state, value) {
            state.downloadStateLoadingPdfsDone = value;
        },
        INCREASE_downloadStateLoadingPdfsDone(state) {
            state.downloadStateLoadingPdfsDone += 1;
        },
        SET_restoring(state, value) {
            state.isRestoring = value;
        },
    },
    getters: {
        isOffline(state) {
            return state.isOffline;
        },
        isLoading(state) {
            return state.downloadStateLoading;
        },
        loadingImagesStatistics(state) {
            return {
                done: state.downloadStateLoadingImagesDone,
                total: state.downloadStateLoadingImagesTotal,
            };
        },
        loadingRenderingsStatistics(state) {
            return {
                done: state.downloadStateLoadingRenderingsDone,
                total: state.downloadStateLoadingRenderingsTotal,
            };
        },
        loadingWebrotate360Statistics(state) {
            return {
                done: state.downloadStateLoadingWebrotate360Done,
                total: state.downloadStateLoadingWebrotate360Total,
            };
        },
        loadingPdfsStatistics(state) {
            return {
                done: state.downloadStateLoadingPdfsDone,
                total: state.downloadStateLoadingPdfsTotal,
            };
        },
        loadingVideosStatistics(state) {
            return {
                done: state.downloadStateLoadingVideosDone,
                total: state.downloadStateLoadingVideosTotal,
            };
        },
        loadingObjectsStatistics(state) {
            return {
                done: state.downloadStateObjectsDone,
                total: state.downloadStateObjectsTotal,
            };
        },
    },
    actions: {
        init(context) {
            if (context.state.isOffline) {
                context.commit('SET_offlineHttpClient', offlineHttpClient);
            }

            setInterval(() => {
                context.dispatch('checkKeepLoggedin');
            }, KEEP_LOGGEDIN_INTERVAL_MS);
        },

        checkAppVersion(context, notificationText) {
            const currentVersion = packageJson.version;

            if (context.state.appVersion && context.state.appVersion !== currentVersion) {
                context.dispatch('notifications/addNotification', {
                    id: uuidv4(),
                    icon: 'check',
                    text: notificationText,
                    deleteOnClose: true,
                    action: '',
                }, { root: true });
            }

            context.commit('SET_appVersion', currentVersion);
        },

        startLoading(context) {
            context.commit('SET_downloadStateLoading', true);
        },

        stoppLoading(context) {
            context.commit('SET_downloadStateLoading', false);
        },

        async forceLogout(context) {
            await context.dispatch('switchToOnline');
            context.dispatch('auth/logout', null, { root: true });
            context.dispatch('notifications/addNotification',
                {
                    id: uuidv4(),
                    icon: 'error',
                    text: i18n.t('notifications.offlineModeForcedLogoutMessage'),
                    deleteOnClose: true,
                    action: '',
                },
                { root: true }
            );
        },

        restore(context, offline = true) {
            // eslint-disable-next-line
            console.log('restore app data', offline);

            const startTime = Date.now();

            if (offline) {
                context.dispatch('startLoading');
                context.commit('SET_restoring', true);
            }

            const objectArray = context.rootState.rr.objects;
            const promises = [];

            objectArray.forEach((object) => {
                const promise = context.dispatch('rr/restoreObject', object, { root: true });
                promises.push(promise);
            });

            Promise.all(promises).then(() => {
                const endTime = Date.now();

                if (offline) {
                    context.dispatch('stoppLoading');
                    context.commit('SET_restoring', false);
                }

                context.dispatch('base/setInitialized', true, { root: true });

                document.querySelector('body').classList.add('vue-initialized');
                console.log('Restoring Objects DONE'); // eslint-disable-line
                console.log('time for restoring objects', getTimeString(startTime, endTime)); // eslint-disable-line
            });
        },

        async switchToOffline(context) {
            electron.remote.getGlobal('offlineMode').appIsInOfflineMode = true;
            ipcRenderer.send('disableMenuPoints');

            if (!isUpdating(context)) {
                if (!context.state.downloadStateLoading) {
                    // eslint-disable-next-line
                    console.log('switch app to offline use');

                    context.dispatch('startLoading');
                    const downloadManager = new DownloadManager(context);
                    try {
                        await downloadManager.downloadAll();
                        context.commit('SET_isOffline', true);
                        ipcRenderer.send('offlineModeEnabled');
                        context.dispatch('stoppLoading');
                        resetStatistics(context);
                        const now = new Date();
                        context.commit('SET_offlineTimestamp', now.getTime());
                        context.dispatch('notifications/addNotification',
                            {
                                id: uuidv4(),
                                icon: 'check',
                                text: i18n.t('notifications.offlineModeEnabledMessage'),
                                deleteOnClose: true,
                                action: '',
                            },
                            { root: true }
                        );
                    } catch (err) {
                        handleOfflineError(context, err);
                    }
                } else {
                    registerError('Offline Mode is already loading', new Error('already loading'));
                }
            }
        },

        async switchToOnline(context, reset = { error: true, offlineStatusForUpdates: true }) {
            // eslint-disable-next-line
            console.log('switch app to online use');

            context.rootState.rr.objects = [];

            if (reset.error) {
                context.commit('SET_error', null);
            }

            resetStatistics(context);
            context.commit('SET_isOffline', false);

            const userLoaded = context.dispatch('rr/find', 'user/current', { root: true });
            const sitesLoaded = context.dispatch('rr/find', 'site/_all', { root: true });
            const navigationMetaLoaded = context.dispatch('rr/find', 'navigation-meta/_all', { root: true });
            const navigationLoaded = context.dispatch('rr/find', 'navigation/_all', { root: true });
            const homeLoaded = context.dispatch('rr/find', 'home-page', { root: true });
            deleteLocalStorageFiles();

            await Promise.all([userLoaded, sitesLoaded, navigationMetaLoaded, navigationLoaded, homeLoaded]);
            context.commit('SET_offlineTimestamp', null);
            window.app.$router.push({ name: 'home' }).catch(() => {});

            // don't reset if offline module enabling resulted in an error
            if (reset.offlineStatusForUpdates) {
                electron.remote.getGlobal('offlineMode').appIsInOfflineMode = false;
                ipcRenderer.send('offlineModeDisabled');
                context.dispatch('notifications/addNotification',
                    {
                        id: uuidv4(),
                        icon: 'check',
                        text: i18n.t('notifications.offlineModeDisabledMessage'),
                        deleteOnClose: true,
                        action: '',
                    },
                    { root: true }
                );
                ipcRenderer.send('checkForUpdate');
            }
        },

        clearStateAfterLoadingWasInterrupted(context) {
            if (!context.state.isOffline && (context.state.downloadStateLoading || context.state.error)) {
                context.dispatch('stoppLoading');
                context.commit('SET_error', null);
                context.commit('SET_errorType', null);
                resetStatistics(context);
                deleteLocalStorageFiles();
            }
        },

        setLoadingError(context, err) {
            context.commit('SET_error', err);
        },

        setLoadingErrorType(context, type) {
            context.commit('SET_errorType', type);
        },

        async retryDownloading(context) {
            context.commit('SET_error', null);
            context.dispatch('startLoading');

            try {
                await retryDownloadingAfterError(context);
                context.commit('SET_isOffline', true);
                ipcRenderer.send('offlineModeEnabled');
                context.dispatch('stoppLoading');
                resetStatistics(context);
            } catch (err) {
                handleOfflineError(context, err);
            }
        },

        checkKeepLoggedin(context) {
            if (context.state.isOffline) {
                const currentTimestamp = new Date();
                const currentOfflineTimeMs = currentTimestamp.getTime() - context.state.offlineTimestamp;
                const allowedOfflineTimeMs = KEEP_LOGGEDIN_DAYS * 24 * 60 * 60 * 1000;

                if (context.state.offlineTimestamp &&
                    allowedOfflineTimeMs < currentOfflineTimeMs) {
                    context.dispatch('forceLogout');
                } else {
                    handleOfflineLogoutNotifications(context, allowedOfflineTimeMs, currentOfflineTimeMs);
                }
            }
        },

        checkApiVersion,
    }
};

export default module;
