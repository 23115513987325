import { mapObject } from 'vuex-json-relationship-resolver';
import ImageComponent from '../../../../components/_atoms/image/_component.vue';
import IconDown from '../../../../components/_svg/icon/chevron/down.vue';
import IconRight from '../../../../components/_svg/icon/chevron/right.vue';
import LoadingSpinner from '../../../../components/_atoms/loadingSpinner/_component.vue';
import AuthenticationPage from '../../../../layouts/authenticationPage/_component.vue';
import animations from "../../../../assets/js/animations";

export default {
    name: 'forgotPassword',

    components: {
        ImageComponent,
        IconDown,
        IconRight,
        LoadingSpinner,
        AuthenticationPage,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-forgotPassword',
            errorMessage: null,
            email: '',
            regex: '',
            loading: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({navigationMeta: {type: 'navigation-meta', isArray: true}}),
    },

    watch: {},

    mounted() {
        this.regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;


        this.doAnimations();
    },

    created() {
        this.$store.dispatch('rr/find', 'navigation-meta/_all', { root: true });
    },

    methods: {
        submit() {
            this.loading = true;
            this.errorMessage = null;
            if (this.isEmailValid() === true) {
                this.$store.dispatch('auth/forgotPassword', {
                    email: this.email,
                }).then(() => {
                    this.loading = false;
                    this.$router.push(this.$route.query.redirect || '/forgot-password-email-sent');
                }).catch(() => {
                    this.loading = false;
                    this.$router.push(this.$route.query.redirect || '/forgot-password-email-sent');
                });
            }
        },
        isEmailValid() {
            if (this.email.length === 0) {
                this.loading = false;
                this.errorMessage = 'fieldCannotBeEmpty';
                return false;
            }
            if (!this.regex.test(this.email)) {
                this.loading = false;
                this.errorMessage = 'noValidEmail';
                return false;
            }
            return true;
        },

        doAnimations() {
            const animateWrapper = this.$refs.forgotPassword;
            animations.init(animateWrapper);
        }
    },
};
