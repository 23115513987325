import { createNamespacedHelpers } from 'vuex';
import AddIcon from '../../_atoms/addIcon/_component.vue';

const documentsMapper = createNamespacedHelpers('documents');

export default {
    name: 'AddDocument',

    components: {
        AddIcon,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'm-addDocument',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...documentsMapper.mapGetters([
            'isAdded',
        ]),
        ...documentsMapper.mapState({
            document: 'currentDocument',
        }),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        toggleDocumentState() {
            if (this.isAdded(this.document.id)) {
                this.$store.dispatch('documents/remove', this.document.id);
            } else {
                this.$store.dispatch('documents/add', this.document);
            }
        }
    },
};
