import PrintItem from '../printItem/_component.vue';
import ImageWrapper from '../../_atoms/image/_component.vue';

export default {
    name: 'AdvantagesPrintTemplate',

    components: {
        PrintItem,
        ImageWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-advantagesPrintTemplate',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        categories() {
            const categoryArray = this.slides.map(slide => slide.category);
            const uniqueCategories =  [...new Set(categoryArray)];
            return uniqueCategories;
        }
    },
	
    watch: {},

    mounted() {},
	
    created() {},
	
    methods: {
        getAdvantagesBy(category) {
            return this.slides.filter(advantage => advantage.category === category);
        },
        getAdvantagePairsBy(category) {
            const advantageList = this.getAdvantagesBy(category);
            const advantagePairs = [];
            for (let i = 0; i < advantageList.length; i += 2) {
                const advantagePair = [
                    advantageList[i],
                    advantageList[i + 1],
                ];
                advantagePairs.push(advantagePair);
            }
            return advantagePairs;
        },
        getImageFromCategory(category) {
            // eslint-disable-next-line
            const slides = this.getAdvantagesBy(category);

            if (slides.length > 0) {
                return slides[0].image;
            }
            return null;
        }
    },
};
