import { createNamespacedHelpers } from 'vuex';
import LaskaAppLogo from '../../_svg/LaskaAppLogo.vue';
import LaskaLogo from '../../_svg/LaskaLogo.vue';
import MainNavigation from '../mainNavigation/_component.vue';
import BurgerIcon from '../../_svg/icon/nav/hamburger--alt.vue';
import CloseIcon from '../../_svg/icon/nav/close.vue';
import DocumentIcon from '../../_atoms/documentIcon/_component.vue';

const documentMapper = createNamespacedHelpers('documents');
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'AppHeader',

    components: {
        LaskaAppLogo,
        LaskaLogo,
        BurgerIcon,
        CloseIcon,
        DocumentIcon,
        MainNavigation,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-appHeader',
            isNavOpen: false,
            showDocumentTitle: false,
            documentTitle: '',
            documentAddable: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...documentMapper.mapState({
            currentDocument: 'currentDocument',
        }),
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {
        currentDocument() {
            this.$store.dispatch('documents/resetAddedDocument');
            if (this.currentDocument) {
                this.showDocumentTitle = this.$router &&
                                   this.$router.currentRoute &&
                                   this.$router.currentRoute.meta.showTitleInHeader &&
                                   this.currentDocument &&
                                   this.currentDocument.tableOfContentsTitle;
                if (this.showDocumentTitle) {
                    this.documentTitle = this.currentDocument.tableOfContentsTitle;
                }
                this.documentAddable = this.$router.currentRoute.meta.documentAddable;
            } else {
                this.showDocumentTitle = false;
                this.documentTitle = '';
                this.documentAddable = false;
            }
        }
    },

    mounted() {},

    created() {},

    methods: {
        toggleMenu() {
            this.$refs.mainNavigation.toggleVisibility();
            this.isNavOpen = this.$refs.mainNavigation.isVisible;
        }
    },
};
