
// ASSET TYPES
export const TYPE_IMAGE = 'asset-image';
export const TYPE_RENDERING = 'asset-rendering-3d-image';
export const TYPE_VIDEO = 'asset-video';
export const TYPE_VIDEO_THUMBNAIL = 'asset-video-thumbnail';
export const TYPE_PDF = 'asset-pdf';
export const TYPE_PRODUCT = 'product';
export const TYPE_WEBROTATE_360 = 'webrotate-360';

// size of downloaded images
export const IMAGE_SIZE = 1200;
export const RENDERING_SIZE = 1600;
export const VIDEO_SIZE = 720;

// time before request timeout in milliseconds
export const REQUEST_TIMEOUT_MS = 2 * 60 * 1000;

// time to keep logged in in offline app in days
export const KEEP_LOGGEDIN_DAYS = 30;

// interval to check keep_loggedin in milliseconds
export const KEEP_LOGGEDIN_INTERVAL_MS = 60 * 60 * 1000; // 1h

// time before request timeout in milliseconds
export const DOCUMENT_LOADING_POLL_TIME_MS = 2 * 60 * 1000;

// time before processed documents are not considered in process anymore
export const DOCUMENT_PROCESS_TIME_BEFORE_RETRY_MS = 60 * 60 * 1000;

// time before redirecting to 404 Page
export const REDIRECT_TO_404_TIME_MS = 30 * 1000;

// time for checking for new notifications (10min)
export const NOTIFICATION_POLL_TIME_MS = 10 * 60 * 1000;

// time for checking for new updates (10h)
export const AUTO_UPDATE_POLL_TIME_MS = 10 * 60 * 60 * 1000;
