import Vue from 'vue';
import Vuex from 'vuex';

import isDesktopApp from '../services/is-desktop-app';

import relationshipResolverModule from './relationshipResolver';
import authenticationModule from './modules/authentication/module';
import documentModule from './modules/documents/module';
import headerModule from './modules/header/module';
import baseModule from './modules/base/module';
import navigationModule from './modules/navigation/module';
import notifiactionModule from './modules/notifications/module';
import mediaLibraryModule from './modules/medialibrary/module';

import VuexPersistPatch from './plugins/vuex-persist-patch';

import {
    vuexAuthPersistence,
    vuexBasePersistence,
    vuexDesktopAppPersistence,
    vuexDocumentPersistence,
    vuexObjectsPersistence,
    vuexNotificationPersistence,
} from './persistance';

Vue.use(Vuex);

const store = {
    modules: {
        base: baseModule,
        rr: relationshipResolverModule,
        auth: authenticationModule,
        documents: documentModule,
        header: headerModule,
        medialibrary: mediaLibraryModule,
        navigation: navigationModule,
        notifications: notifiactionModule,
    },
    mutations: {
        RESTORE_MUTATION: vuexObjectsPersistence.RESTORE_MUTATION,
    },
    plugins: [
        vuexAuthPersistence.plugin,
        vuexBasePersistence.plugin,
        vuexDesktopAppPersistence.plugin,
        vuexDocumentPersistence.plugin,
        vuexObjectsPersistence.plugin,
        vuexNotificationPersistence.plugin,
        VuexPersistPatch(),
    ],
};

// only load desktopApp module when client is a desktopApp
if (isDesktopApp) {
    store.modules.desktopApp = require('./modules/desktop-app/module').default;
}

let vuexStore;

try {
    vuexStore = new Vuex.Store(store);
} catch (error) {
    localStorage.clear();
    window.location.reload();
}

export default vuexStore;
