import { transformHelper } from '../../../helpers/dataHelper';
import ChevronButton from '../../_atoms/chevronButton/_component.vue';

export default {
    name: 'OutputTableCutter',

    components: {
        ChevronButton,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            require: true,
        },
    },

    data() {
        return {
            cn: 'o-outputTableCutter',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...transformHelper,
    },
};
