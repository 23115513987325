import ImageWrapper from '../../../../_atoms/image/_component.vue';
import FullPageQuote from '../../../../_molecules/fullPageQuote/_component.vue';

export default {
    name: 'ContentBuilderQuote',

    components: {
        ImageWrapper,
        FullPageQuote,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        imageSize: {
            type: String,
            default: 'portrait',
        },
        image: {
            type: Object,
            default: () => {},
        },
        quote: {
            type: String,
            default: '',
        },
        source: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: '',
        },
        quoteStyle: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            cn: 'o-contentBuilderQuote',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
