import isDesktopApp from '../../../services/is-desktop-app';
import env from '../../../../.env';
import i18n from '../../../../i18n';
import restoreLanguageFromLocalStorage from './methods/restoreLanguageFromLocalStorage';
import handleInternetConnection from './methods/handleInternetConnection';
import handlePrinting from './methods/handlePrinting';
import initializeSentryUser from './methods/initializeSentryUser';

const module = {
    namespaced: true,
    state: {
        http: {
            baseUrl: env.baseUrl,
        },
        language: null,
        appInitialized: false,
        isDesktopApp,
        isPrinting: false,
        isInternetAvailable: true,
        preventStatusMessages: false,
        currentPageTitle: null,
        isRenderingDone: false,
        printingMeta: {},
        isInPdfGenerationMode: false,
        printingOnlyFirstPage: null,
        isBodyLocked: false,
        viewport: {
            width: null,
            height: null,
        },
        isTouchDevice: false,
    },
    mutations: {
        SET_initialized(state, status) {
            state.appInitialized = !!status;
        },
        setPreventStatusMessages(state, status) {
            state.preventStatusMessages = !!status;
        },
        SET_BASEURL(state, url) {
            state.http.baseUrl = url;
        },
        setLanguage(state, language) {
            state.language = language;
            i18n.locale = language;
        },
        setIsLoadingSites(state, status) {
            state.isLoadingSites = status;
        },
        SET_isPrinting(state, status) {
            state.isPrinting = status;
        },
        SET_printingOnlyFirstPage(state, status) {
            state.printingOnlyFirstPage = status;
        },
        SET_isInternetAvailable(state, status) {
            state.isInternetAvailable = status;
        },
        SET_isRenderingDone(state, status) {
            state.isRenderingDone = status;
        },
        SET_printingMeta(state, printingMeta) {
            state.printingMeta = printingMeta;
        },
        SET_isInPdfGenerationMode(state, status) {
            state.isInPdfGenerationMode = status;
        },
        SET_isBodyLocked(state, status) {
            state.isBodyLocked = status;
        },
        SET_viewportSize(state, size) {
            state.viewport = { ...size };
        },
        SET_touchDevice(state, value) {
            state.isTouchDevice = value;
        },
    },

    getters: {
        isMobile(state) {
            return state.isTouchDevice &&
                   state.viewport &&
                   state.viewport.width &&
                   state.viewport.width <= 1920;
        },
        isOffline(state, getters, rootState) {
            return state.isDesktopApp &&
                   rootState.desktopApp.isOffline;
        },
        isInPdfGenerationMode(state) {
            return state.isInPdfGenerationMode;
        }
    },

    actions: {
        async init(context) {
            restoreLanguageFromLocalStorage(context);
            handleInternetConnection(context);
            handlePrinting(context);

            // initialize desktop app
            if (context.state.isDesktopApp) {
                context.dispatch('desktopApp/init', {}, {root: true});
            } else {
                context.commit('SET_initialized', true);
            }

            if (!context.getters.isOffline) {
                initializeSentryUser(context);

                context.dispatch('rr/find', 'site/_all', { root: true });
                context.dispatch('rr/find', 'navigation-meta/_all', { root: true });
            }
        },
        renderingDone(context) {
            context.commit('SET_isRenderingDone', true);
            window.htmlPdfDone = true;
        },
        async loadPrintingMetaData(context) {
            const url = new URL(window.location.href);
            let parameter = url.searchParams.get('data');
            if (!parameter) return;
            parameter = atob(parameter);
            parameter = JSON.parse(parameter);
            if (!parameter) return;

            context.commit('SET_isPrinting', true);
            context.rootState.auth.token = parameter.token;
            context.commit('auth/setToken', { token: parameter.token }, { root: true });
            context.commit('SET_printingMeta', parameter);
            context.commit('setLanguage', parameter.meta.language);
            context.commit('SET_isInPdfGenerationMode', true);
            context.commit('SET_printingOnlyFirstPage', parameter.onlyFirstPage);

            const offer = await context.dispatch('rr/find', `offer/${parameter.offerId}`, {root: true});
            const body = JSON.parse(offer.requestBody);
            body.pageCounts = parameter.pageCounts;
            context.commit('SET_printingMeta', body);


            const $fontPreloader = document.createElement('span');
            $fontPreloader.classList.add('font_preload');
            $fontPreloader.innerHTML = `
              <div class="font_preload" style="opacity: 0">
                <span style="font-family: 'AktivGrotesk'; font-weight: 400;">400</span>
                <span style="font-family: 'AktivGrotesk'; font-weight: 500;">500</span>
                <span style="font-family: 'AktivGrotesk'; font-weight: 700;">700</span>
                <span style="font-family: 'AktivGrotesk'; font-weight: 900;">900</span>
              </div>
            `;
            $fontPreloader.style.position = 'absolute';
            $fontPreloader.style.top = '0';
            $fontPreloader.style.visibility = 'hidden';

            document.body.appendChild($fontPreloader);

            if (parameter.onlyFirstPage === true) {
                const style = document.createElement('style');
                style.innerHTML = `
                    @page { margin: 0; size: a4; }
                    .o-sectionWrapper:not(.o-introSection):not(.o-imageIntroSection) {
                        display: none;
                    }
                `;
                document.head.appendChild(style);
            } else if (parameter.onlyFirstPage === false) {
                const style = document.createElement('style');
                style.innerHTML = `
                    @page { margin: 25mm 0 35mm; size: a4; }
                    .o-sectionWrapper.o-introSection,
                    .o-sectionWrapper.o-imageIntroSection {
                        display: none;
                    }
                `;
                document.head.appendChild(style);
            }
        },
        lockBody(context) {
            context.commit('SET_isBodyLocked', true);
        },
        freeBody(context) {
            context.commit('SET_isBodyLocked', false);
        },
        setViewportSize(context, size) {
            context.commit('SET_viewportSize', size);
        },
        setTouchDeviceTo(context, value) {
            context.commit('SET_touchDevice', value);
        },
        setInitialized(context, value) {
            context.commit('SET_initialized', value);
        },
        setBaseUrl(context, url) {
            context.commit('SET_BASEURL', url);
        },
    },
};

export default module;
