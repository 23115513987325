import IconCheck from '../../../../_svg/icon/check.vue';
import IconCross from '../../../../_svg/icon/cross.vue';
import IconPlus from '../../../../_svg/icon/plus.vue';

export default {
    name: 'ContentBuilderBulletPoints',

    components: {
        IconCheck,
        IconCross,
        IconPlus,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array
        },
        colorScheme: {
            type: String,
            default: 'red',
        },
        contentBuilderSection: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderBulletPoints',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        filteredItems() {
            const checkItems = this.items.filter(item => item.iconType === 'checkmark');
            const plusItems = this.items.filter(item => item.iconType === 'cross');
            const crossItems = this.items.filter(item => item.iconType === 'plus');
            const numberItems = this.items.filter(item => item.iconType === 'number');

            const filteredArray = numberItems.concat(checkItems.concat(crossItems, plusItems));

            const half = Math.ceil(filteredArray.length / 2);
            const first = filteredArray.slice(0, half);
            const second = filteredArray.slice(half, filteredArray.length);

            return [first, second];
        },

    },

    watch: {},

    mounted() {},

    created() {},

    methods: {},
};
