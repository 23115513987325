import isDesktopApp from './src/services/is-desktop-app';
let offlineStoragePath = null;

if (isDesktopApp) {
    const electron = require('electron');
    const app = (electron.app || electron.remote.app);
    offlineStoragePath = app.getPath('userData') + '/offline-storage/';
}

export default {
	baseUrl: 'https://api.laska-app.com/v1/',
	apiDomain: 'https://api.laska-app.com/',
    mapsApiKey: 'AIzaSyDHglHdZKvrlwBnlgvg39SfRRJ-Hy9wU2k',
    NUTS_URL: 'https://update-service.laska-app.com',
    sentry: {
        enabled: true,
        dsnJs: 'https://e50069d2c6674495b9c57ba60bfdeb16@sentry.io/5169846',
        dsnElectron: 'https://a605bc4d8f7f4723bcf55c5738b74a17@sentry.io/1442653',
    },
	offline: {
		storage: {
			path: offlineStoragePath,
		}
	}
};
