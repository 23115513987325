import { createNamespacedHelpers } from 'vuex';
import { mapObject } from 'vuex-json-relationship-resolver';
import GridOverlay from './helpers/gridOverlay/_component.vue';
import OnRenderDoneChecker from './helpers/onRenderDoneChecker/_component.vue';
import LoadingOverlay from './components/_organisms/loadingOverlay/_component.vue';
import ErrorOverlay from './components/_organisms/errorOverlay/_component.vue';
import NotificationOverlay from './components/_organisms/notificationOverlay/_component.vue';
import AppHeader from './components/_organisms/appHeader/_component.vue';
import env from '../.env';

let ipcRenderer = null;

const baseMapper = createNamespacedHelpers('base');

export default {
    data() {
        return {
            environment: process.env.NODE_ENV,
        };
    },
    components: {
        GridOverlay,
        OnRenderDoneChecker,
        LoadingOverlay,
        ErrorOverlay,
        AppHeader,
        NotificationOverlay,
    },
    computed: {
        ...baseMapper.mapState([
            'isBodyLocked',
            'isInternetAvailable',
            'isDesktopApp',
            'appInitialized',
            'printingOnlyFirstPage',
        ]),
        ...baseMapper.mapGetters([
            'isOffline',
        ]),
        ...mapObject({ user: { type: 'user' }}),
        appClasses() {
            const classes = {};
            if (this.printingOnlyFirstPage === true) {
                classes['app-print--only-first-page'] = true;
            } else if (this.printingOnlyFirstPage === false) {
                classes['app-print--except-first-page'] = true;
            }
            return classes;
        }
    },
    watch: {
        isBodyLocked() {
            if (this.isBodyLocked) {
                document.querySelector('body').classList.add('is-locked');
            } else {
                document.querySelector('body').classList.remove('is-locked');
            }
        },
        isInternetAvailable() {
            if (this.isInternetAvailable) {
                this.$store.dispatch('documents/sendDocumentQueue');
                this.$store.dispatch('documents/checkLoadingDocuments');
            }
        },
        user() {
            this.$store.dispatch('documents/sendDocumentQueue');
            this.$store.dispatch('documents/checkLoadingDocuments');

            this.setOfflineMenuState();
        },
    },
    created() {
        if (this.isDesktopApp && !this.isOffline) {
            this.$store.dispatch('desktopApp/clearStateAfterLoadingWasInterrupted');
        }

        // only when online
        if (this.isDesktopApp && !this.isOffline || !this.isDesktopApp) {
            this.$store.dispatch('base/setBaseUrl', env.baseUrl);
        }

        this.$store.dispatch('documents/sendDocumentQueue');
        this.$store.dispatch('documents/checkLoadingDocuments');
        this.$store.dispatch('base/init');

        if (this.isDesktopApp) {
            this.$store._vm.$root.$on('storageReady', () => {
                this.$store.dispatch('desktopApp/restore', this.isOffline);
            });
        }

        window.addEventListener('resize', this.setWindowSize);
        this.$store.dispatch('base/setTouchDeviceTo', (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)));
    },
    mounted() {
        this.setWindowSize();

        if (this.isDesktopApp) {
            const { remote } = require('electron'); // eslint-disable-line
            const { initialSiteLoad } = remote.getGlobal('sharedObj');

            if (initialSiteLoad) {
                this.$router.replace({ name: 'home' }).catch(() => {});
            }

            // eslint-disable-next-line
            ipcRenderer = require('electron').ipcRenderer;
            ipcRenderer.send('resetInitialSiteLoad');

            // check if app was updated
            this.$store.dispatch('desktopApp/checkAppVersion',
                this.$t('notifications.updateSuccessfulNotificationMessage'));

            this.setOfflineMenuState();
        }
    },
    destroyed() {
        window.addEventListener('resize', this.setWindowSize);
    },
    methods: {
        setWindowSize() {
            let viewPortWidth;
            let viewPortHeight;

            if (typeof window.innerWidth !== 'undefined') {
                viewPortWidth = window.innerWidth;
                viewPortHeight = window.innerHeight;
            }

            this.$store.dispatch('base/setViewportSize', {
                width: viewPortWidth,
                height: viewPortHeight,
            });
        },
        doOnLeave() {
            document.body.classList.remove('pageEntered');
            document.body.classList.add('pageLeaved');
        },
        doOnEnter() {
            document.body.classList.remove('pageLeaved');
            document.body.classList.add('pageEntered');
        },
        setOfflineMenuState() {
            if (this.isDesktopApp) {
                const { isOffline } = this.$store.state.desktopApp;

                if (!this.$store.state.desktopApp.downloadStateLoading || isOffline) {
                    if (!this.user) {
                        ipcRenderer.send('disableMenuPoints');
                    } else if (isOffline) {
                        ipcRenderer.send('offlineModeEnabled');
                    } else {
                        ipcRenderer.send('offlineModeDisabled');
                    }
                } else {
                    ipcRenderer.send('disableMenuPoints');
                }
            }
        }
    },
};
