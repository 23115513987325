var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.rootClass},[_c('IconLeft',{class:{
              'flickity__prevBtn--mobile': true,
              'flickity__prevBtn--disabled': _vm.noPrevSlide
            },attrs:{"color":"white"},nativeOn:{"click":function($event){return _vm.prevSlide.apply(null, arguments)}}}),_c('IconRight',{class:{
               'flickity__nextBtn--mobile': true,
               'flickity__nextBtn--disabled': _vm.noNextSlide
             },attrs:{"color":"white"},nativeOn:{"click":function($event){return _vm.nextSlide.apply(null, arguments)}}}),_c('div',{staticClass:"o-sliderOverlay__container"},[_c('div',{staticClass:"o-sliderOverlay__wrapper"},[_c('div',{staticClass:"o-sliderOverlay__content"},[_c('div',{staticClass:"o-sliderOverlay__contentCol"},[(_vm.slides && _vm.slides.length > 0)?_c('div',{staticClass:"o-sliderOverlay__galleryWrapper"},[_c('flickity',{ref:"flickity",staticClass:"flickity",attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,class:[
                     'carousel-cell',
                     'o-sliderOverlay__carouselCell',
                     { 'o-sliderOverlay__carouselCell--video': slide.type === 'asset-video'},
                     { 'o-sliderOverlay__carouselCell--noPointer': _vm.dragging },
                     { 'is-selected': _vm.currentSlideIndex === index } ]},[(slide.type === 'asset-image')?[_c('ImageWrapper',{staticClass:"o-sliderOverlay__carouselCellImage",attrs:{"modifiers":['imageGallery'],"image":slide,"caption":true,"caption-content":("<h2>" + (slide.captionOverwrite ? slide.captionOverwrite.title : '') + "</h2>" + (slide.captionOverwrite ? _vm.fixInlineLinks(slide.captionOverwrite.content) : '')),"caption-addition":((parseInt(index) + 1) + "/" + (_vm.slides.length)),"crop-type":"landscape"}})]:(slide.type === 'asset-video')?[_c('VideoPlayer',{attrs:{"video":slide,"caption":true,"caption-content":("<h2>" + (slide.captionOverwrite ? slide.captionOverwrite.title : '') + "</h2>" + (slide.captionOverwrite ? _vm.fixInlineLinks(slide.captionOverwrite.content) : '')),"caption-addition":((parseInt(index) + 1) + "/" + (_vm.slides.length))}})]:_vm._e()],2)}),0)],1):_vm._e()])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }