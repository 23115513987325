import { createNamespacedHelpers } from 'vuex';

const rrMapper = createNamespacedHelpers('rr');
const authMapper = createNamespacedHelpers('auth');

export default {
    name: 'PdfRendererOnRenderDoneChecker',

    data() {
        return {
            timeoutHandler: null,
        };
    },

    computed: {
        ...rrMapper.mapState(['loadingObjects']),
        ...authMapper.mapState(['isCheckingAuthentication']),
    },
	
    watch: {
        loadingObjects(loadingObjects) {
            const waitForImages = ($wrapper) => {
                return new Promise((resolve) => {
                    const $images = $wrapper.querySelectorAll('img');
                    const callback = () => {
                        for (let i = 0; i < $images.length; i++) {
                            if (!$images[i].complete) {
                                return;
                            }
                        }
                        resolve();
                    };
                    [...$images].forEach(($image) => {
                        callback();
                        $image.addEventListener('load', callback);
                    });
                });
            };

            if (window.pdfRendererTimeoutHandler) {
                clearTimeout(window.pdfRendererTimeoutHandler);
            }
            console.info(loadingObjects.length, this.isCheckingAuthentication);
            if (!loadingObjects.length && !this.isCheckingAuthentication) {
                console.info('now done loading, but just to be sure, we wait some seconds');
                console.info(loadingObjects.length, this.isCheckingAuthentication);
                window.pdfRendererTimeoutHandler = setTimeout(async() => {
                    console.log('waiting for images to be done');
                    await waitForImages(document.querySelector('body'));
                    console.log('images done!');
                    this.$store.dispatch('base/renderingDone');
                }, 800);
            }
        }
    },

    created() {
        window.htmlPdfDone = false;
        setTimeout(() => {
            this.$store.dispatch('base/renderingDone');
        }, 30 * 1000);
    },
};
