import Webrotate360 from '../../_molecules/webrotate360/_component.vue';

export default {
    name: 'webrotate360Container',

    components: {
        Webrotate360,
    },

    data() {
        return {
            cn: 'o-webrotate360Container',
        };
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        renderings: {
            type: Array,
            default: () => null,
        },
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},

    methods: {},

    destroyed() {},
};
