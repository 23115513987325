import DocumentOverviewPage from '../../views/documents/documentOverview/_component.vue';
import DocumentFormPage from '../../views/documents/documentForm/_component.vue';
import DocumentProcessingPage from '../../views/documents/documentProcessing/_component.vue';

const routes = [
    {
        path: '/documents/overview',
        name: 'document-overview',
        component: DocumentOverviewPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/documents/form',
        name: 'document-form',
        component: DocumentFormPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/documents/processing',
        name: 'document-processing',
        component: DocumentProcessingPage,
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;