import LocationCard from '../locationCard/_component.vue';
import MapPin from '../../_atoms/mapPin/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';

export default {
    name: 'MapMarker',

    components: {
        LocationCard,
        MapPin,
        IconClose,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        location: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            cn: 'm-mapMarker',
            isActive: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
    },

    methods: {
        setActive(isActive) {
            this.isActive = isActive;
            this.$refs.mapPin.setActive(isActive);
        },
        markerClick() {
            this.$emit('markerclick');
        },
        infoBoxClose() {
            this.$emit('infoboxclose');
        },
    },
};
