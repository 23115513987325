<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <g fill="none" fill-rule="evenodd">
        <polygon points="0 0 40 0 40 40 0 40" />
        <path fill="#E11624" fill-rule="nonzero" d="M30,11.6666667 L27.65,9.31666667 L17.0833333,19.8833333 L19.4333333,22.2333333 L30,11.6666667 Z M37.0666667,9.31666667 L19.4333333,26.95 L12.4666667,20 L10.1166667,22.35 L19.4333333,31.6666667 L39.4333333,11.6666667 L37.0666667,9.31666667 Z M0.683333333,22.35 L10,31.6666667 L12.35,29.3166667 L3.05,20 L0.683333333,22.35 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'Sent',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationSent',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationSent {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationSent--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationSent--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationSent--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationSent--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
