export default {
    name: 'FactTable',

    components: {
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        // tableHeading: {
        //     type: String,
        //     default: 'Leistungsdaten',
        // },
        table: {
            type: Array,
            required: true,
        },
        tableColumns: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            cn: 'o-factTable',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        isOptionColumn(index) {
            return index > 1;
        },
    },
};
