import { createNamespacedHelpers } from 'vuex';
import CardSlider from '../cardSlider/_component.vue';
import Flickity from 'vue-flickity'; // eslint-disable-line

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'CardSliderWithFilter',

    components: {
        CardSlider,
        Flickity,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        cardType: {
            type: String,
            default: '',
        },
        slides: {
            type: Array,
            default: () => [],
        },
        filterCallback: {
            type: Function,
        },
        labels: {
            type: Array,
            default: () => null,
        }
    },

    data() {
        return {
            cn: 'o-cardSliderWithFilter',
            labelToFilter: 'default',
            flickityOptions: {
                contain: true,
                cellAlign: 'left',
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                dragThreshold: 3,
                prevNextButtons: false,
                pageDots: false,
                resize: true,
                freeScroll: true,
                freeScrollFriction: 0.03
            },
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        filterLabels() {
            return this.labels ? this.labels.map(label => label.title) : [...new Set(this.slides.map(slide => slide.category))];
        },
        filteredSlides() {
            if (this.labelToFilter === 'default') {
                return this.slides;
            }

            return this.slides.filter(slide => slide.category === this.labelToFilter);
        },
        ...baseMapper.mapGetters({
            isTouchDevice: 'isMobile',
        })
    },

    watch: {
        slides() {
            this.labelToFilter = 'default';
        },
    },

    mounted() {},

    created() {},

    methods: {
        filterSlides(filterString) {
            this.labelToFilter = filterString;
            if (this.filterCallback) {
                this.filterCallback(this.labelToFilter);
            }
        }
    },
};
