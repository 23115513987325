const refreshToken = (context) => {
    if (context.state.isTokenRefreshing) return;
    return new Promise((resolve, reject) => {
        context.state.isTokenRefreshing = true;
        const url = `${context.rootState.base.http.baseUrl}auth/refresh`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${context.state.refreshToken}`,
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            }
        }).then((response) => {
            if (!response.ok) {
                context.state.isTokenRefreshing = false;
                context.dispatch('logoutOnlyFrontend');
                return reject();
            }

            return response.json().then(body => {
                context.state.isTokenRefreshing = false;
                context.commit('setToken', {token: body.data.token});
                resolve();
            });
        });
    });
};

export default refreshToken;