const getTimeString = (startTime, endTime) => {
    if (!startTime || !endTime) {
        return;
    }

    const timeDifferenceInMs = endTime - startTime;
    const timeDifferenceInSec = Math.round(timeDifferenceInMs / 1000);
    const timeDifferenceInMin = Math.floor(timeDifferenceInSec / 60);

    return `${timeDifferenceInMin} Minuten ${timeDifferenceInSec - timeDifferenceInMin * 60} Sekunden`;
};

export default getTimeString;
