import ApplicationPage from '../../views/applicationDetail/_component.vue';
import ProductPage from '../../views/productDetail/_component.vue';
import PdfIframePage from '../../views/pdfIframePage/_component.vue';

const routes = [
    {
        path: '/product/:slug',
        name: 'product',
        component: ProductPage,
        meta: {
            pdfRenderer: {
                enabled: true,
                title: ''
            },
            isPrintable: true,
            requiresAuth: true,
            showTitleInHeader: true,
            documentAddable: true,
        },
    },
    {
        path: '/static-product/:id',
        name: 'static-product',
        component: PdfIframePage,
        meta: {
            pdfRenderer: {
                enabled: true,
                title: ''
            },
            isPrintable: true,
            requiresAuth: true,
            showTitleInHeader: true,
            documentAddable: true,
        },
    },
    {
        path: '/product-application/:slug',
        name: 'product-application',
        component: ApplicationPage,
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;