import Task from './Task';
import { VIDEO_SIZE, TYPE_VIDEO, TYPE_VIDEO_THUMBNAIL } from '../../../../settings';
import storeAsset from '../../store-asset';
import VideoDownloadError from '../../../helpers/errors/download/VideoDownloadError';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import registerError from '../../../helpers/registerError';

class VideoTask extends Task {
    constructor(downloadManager, dataExecutor, video) {
        super();
        this.downloadManager = downloadManager;
        this.dataExecutor = dataExecutor;
        this.video = video;
    }

    async run() {
        if (!this.video) {
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingVideosDone');
            return;
        }
        
        try {
            await this.loadVideo();
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingVideosDone');
        } catch (err) {
            this.handleError(err);
        }
    }

    handleError(err) {
        registerError(`VideoTask could not load video ${this.video.id}`, err);

        if (err instanceof InternetConnectionError) {
            throw err;
        } else {
            throw new VideoDownloadError(`VideoTask could not load video ${this.video.id}`);
        }
    }

    async loadVideo() {
        if (this.video.versions && Object.keys(this.video.versions).length > 0) {
            const videoVersion = this.getVideoVersionWithSize(VIDEO_SIZE);
            const videoLink = await this.saveVideoToDisk(videoVersion);
            const thumbnailLink = await this.saveThumbnailToDisk(videoVersion);
            this.video.offlineVersion = {
                extension: videoVersion.extension,
                height: videoVersion.height,
                thumbnail: thumbnailLink,
                thumbnailExtension: videoVersion.thumbnailExtension,
                type: videoVersion.type,
                url: videoLink,
            };
        } else {
            throw new VideoDownloadError('No video sources found');
        }
    }

    // eslint-disable-next-line
    async saveVideoToDisk(video) {
        const { url, extension } = video;
        const storageLink = await storeAsset(url, extension, TYPE_VIDEO);
        return storageLink;
    }
    
    // eslint-disable-next-line
    async saveThumbnailToDisk(video) {
        const url = video.thumbnail;
        const extension = video.thumbnailExtension;
        const storageLink = await storeAsset(url, extension, TYPE_VIDEO_THUMBNAIL);
        return storageLink;
    }
    
    getVideoVersionWithSize(size) {
        let result = this.video.versions.filter(video => video.height === size)[0];
        if (!result) {
            // video of size VIDEO_SIZE is not available
            // use biggest available video
            const videoSizes = this.video.versions.map(video => video.height);
            const maxSize =  Math.max(...videoSizes);
            // eslint-disable-next-line
            result = this.video.versions.filter(video => video.height === maxSize)[0];
        }
        return result;
    }
}

export default VideoTask;
