import ImageWrapper from '../../_atoms/image/_component.vue';

export default {
    name: 'Card',

    components: {
        ImageWrapper
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        id: {
            type: [String, Number],
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        category: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        image: {
            type: Object,
            default: null,
        },
        link: {
            type: [Object, String],
            default: '',
        },
        callback: {
            type: Function,
        },
    },

    data() {
        return {
            cn: 'm-card',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        imageCropType() {
            if (this.modifiers.includes('half')) {
                return '';
            }

            if (this.modifiers.includes('squaredSmall')) {
                return 'square';
            }

            return 'portrait';
        }
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        clickedCard(event) {
            if (this.callback) {
                event.preventDefault();
                this.callback(this.id);
            } else {
                this.$router.push(this.link).catch(() => {});
            }
        }
    },
};
