const module = {

    namespaced: true,

    state: {
        logoColor: 'red',
        iconColor: 'white',
        iconBackgroundColor: 'black',
    },

    mutations: {
        SET_logoColor(state, color) {
            state.logoColor = color;
        },
        SET_iconColor(state, color) {
            state.iconColor = color;
        },
        SET_iconBackgroundColor(state, color) {
            state.iconBackgroundColor = color;
        },
    },

    getters: {},

    actions: {
        setLogoColor(context, color)  {
            context.commit('SET_logoColor', color);
        },
        setIconColor(context, color)  {
            context.commit('SET_iconColor', color);
        },
        setIconBackgroundColor(context, color)  {
            context.commit('SET_iconBackgroundColor', color);
        },
    }
};

export default module;