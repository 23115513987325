import { createNamespacedHelpers } from 'vuex';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SliderOverlay from '../../_organisms/sliderOverlay/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ReferenceSection',

    components: {
        CardSlider,
        SliderOverlay,
        IconClose,
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        references: {
            type: Array,
            default: () => [],
        },
        mappingFunction: {
            type: Function,
            required: true,
        },
        mappingFunctionOverlay: {
            type: Function,
            required: true,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-referenceSection',
            showOverlay: false,
            activeSliderItem: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        slides() {
            return this.references
                .map(slide => this.mappingFunction(slide))
                .map((slide, index) => ({
                    ...slide,
                    index,
                    callback: this.openSliderOverlay,
                }));
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        openSliderOverlay(id) {
            this.activeSliderItem = this.getIndexOfCardWith(id);
            this.showOverlay = true;
            this.$store.dispatch('base/lockBody');
        },
        closeOverlay() {
            this.activeSliderItem = null;
            this.showOverlay = false;
            this.$store.dispatch('base/freeBody');
        },
        getIndexOfCardWith(id) {
            const { slides } = this.$refs.referenceSlider;
            const currentSlide = slides.filter(slide => slide.id === id)[0];
            return currentSlide ? currentSlide.index : null;
        },
    },
};
