const initRenderProcessEvents = (store) => {
    const { isDesktopApp } = store.state.base;

    if (isDesktopApp) {
        // eslint-disable-next-line
        const { ipcRenderer } = require('electron');
        
        ipcRenderer.on('log', (evt, msg) => {
            // eslint-disable-next-line
            console.log('Main Process', msg);
        });
    }
};

export default initRenderProcessEvents;
