import { viewConditionHelper } from '../../../helpers/dataHelper';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';

export default {
    name: 'RelatedContentSection',

    components: {
        CardSlider,
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        contents: {
            type: Array,
            default: () => [],
        },
        mappingFunction: {
            type: Function,
            required: true,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-relatedContentSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
    },
};
