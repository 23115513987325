import { mapObject } from 'vuex-json-relationship-resolver';
import ImageComponent from '../../../../components/_atoms/image/_component.vue';
import AuthenticationPage from '../../../../layouts/authenticationPage/_component.vue';

export default {
    name: 'forgotPasswordEmailSent',

    components: {
        ImageComponent,
        AuthenticationPage,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-forgotPasswordEmailSent',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({navigationMeta: {type: 'navigation-meta', isArray: true}}),
    },

    watch: {},

    mounted() {},

    created() {
        this.$store.dispatch('rr/find', 'navigation-meta/_all', { root: true });
    },

    methods: {},
};
