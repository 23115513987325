import {createNamespacedHelpers} from 'vuex';
import { mapObject } from 'vuex-json-relationship-resolver';
import { transformHelper, viewConditionHelper } from '../../helpers/dataHelper';
import DetailPage from '../../layouts/detailPage/_component.vue';
import SectionWrapper from '../../components/_organisms/sectionWrapper/_component.vue';
import AdvantageSection from '../../components/_sections/advantageSection/_component.vue';
import ApplicationSection from '../../components/_sections/applicationSection/_component.vue';
import AutomationSection from '../../components/_sections/automationSection/_component.vue';
import HighlightSliderSection from '../../components/_sections/highlightSliderSection/_component.vue';
import IntroSection from '../../components/_sections/introSection/_component.vue';
import ReasonsSection from '../../components/_sections/reasonsSection/_component.vue';
import RelatedContentSection from '../../components/_sections/relatedContentSection/_component.vue';
import OutputSection from '../../components/_sections/outputProductLineSection/_component.vue';
import OptionSection from '../../components/_sections/optionSection/_component.vue';
import ReferenceSection from '../../components/_sections/referenceSection/_component.vue';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';
import FaqSection from '../../components/_sections/faqSection/_component.vue';
import TechnicalDocumentsSection from '../../components/_sections/technicalDocumentsSection/_component.vue';
import { REDIRECT_TO_404_TIME_MS } from '../../../settings';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'productLineDetail',

    components: {
        DetailPage,
        SectionWrapper,
        AdvantageSection,
        ApplicationSection,
        AutomationSection,
        HighlightSliderSection,
        IntroSection,
        ReasonsSection,
        RelatedContentSection,
        OutputSection,
        OptionSection,
        ReferenceSection,
        ViewWrapper,
        FaqSection,
        TechnicalDocumentsSection,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-productLineDetail',
            sections: [],
            timeout404Page: null,
        };
    },

    computed: {
        ...mapObject({
            productLine: {
                type: 'product-line',
                filter: {
                    slug: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.slug;
                    }
                }
            },
            navItem: {
                type: 'navigation-item',
                filter: {
                    link: ($vm) => {
                        if (!$vm) return;
                        return `/product-line/${$vm.$route.params.slug}`;
                    },
                },
            },
            advantageCategories: {
                type: 'productLine-category',
                isArray: true,
            },
        }),
        ...baseMapper.mapState(['isPrinting']),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        downloadList() {
            const downloadNames = this.getArrayFrom(this.productLine.downloads);
            const downloadList = this.getArrayFrom(this.productLine.downloadsList);
            const downloads = [];

            downloadList.forEach((download, index) => {
                if (download && downloadNames[index]) {
                    downloads.push({
                        fileName: downloadNames[index],
                        content: download,
                    });
                }
            });
            return downloads;
        }
    },

    watch: {
        '$route.params.slug'() {
            this.$store.dispatch('rr/find', `product-line/${this.$route.params.slug}`);
        },
        productLine() {
            this.initSections();
            this.setCurrentDocument();
        },
        navItem(newItem, oldItem) {
            if (!oldItem && newItem) {
                this.$nextTick(() => {
                    this.setCurrentDocument();
                });
            }
        }
    },

    mounted() {
        this.setCurrentDocument();

        if (this.productLine) {
            this.initSections();
        }
    },

    created() {
        this.$store.dispatch('rr/find', `product-line/${this.$route.params.slug}`);
        this.$store.dispatch('rr/find', 'category/_allLines');

        this.timeout404Page = setTimeout(() => {
            if (!this.productLine) {
                this.$router.push({ name: 'page404' });
            }
        }, REDIRECT_TO_404_TIME_MS);
    },

    methods: {
        ...transformHelper,
        ...viewConditionHelper,
        setCurrentDocument() {
            if (this.navItem) {
                this.$store.dispatch('documents/setCurrentDocumentTo', {
                    id: `${this.navItem.id}`,
                    tableOfContentsTitle: this.navItem.pdfName || this.navItem.name,
                    urlPath: `/product-line/${this.$route.params.slug}`,
                });
            }
        },
        initSections() {
            this.$nextTick(() => {
                this.sections = Array.from(this.$refs.productLineTemplate.children);
            });
        },
        getVideoOrImageFrom(advantage) {
            let videoOrImage = {};
            if (advantage.videoList && Object.values(advantage.videoList).length > 0) {
                videoOrImage = {
                    ...advantage.videoList[0],
                    title: advantage.videoList[0].title,
                    versions: advantage.videoList[0].versions,
                };
            } else if (advantage.imageList && Object.values(advantage.imageList).length > 0) {
                videoOrImage = {
                    ...advantage.imageList[0],
                };
            }
            return videoOrImage;
        }
    },

    destroyed() {
        this.$store.dispatch('documents/setCurrentDocumentTo', null);
        clearTimeout(this.timeout404Page);
    },
};
