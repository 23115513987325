import { createNamespacedHelpers, mapGetters } from 'vuex';
import DocumentSVG from '../../_svg/icon/documents/docs_normal.vue';
import DocumentSVGadd from '../../_svg/icon/documents/docs_add.vue';
import DocumentSVGwithDot from '../../_svg/icon/documents/docs_bubble.vue';
import LoadingSpinner from '../loadingSpinner/_component.vue';

const documentsMapper = createNamespacedHelpers('documents');

export default {
    name: 'DocumentIcon',

    components: {
        DocumentSVG,
        DocumentSVGadd,
        DocumentSVGwithDot,
        LoadingSpinner,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        color: {
            type: String,
            default: 'white',
        },
        backgroundColor: {
            type: String,
            default: 'black',
        },
        isAddable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'a-documentIcon',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapGetters({
            documentCount: 'documents/getLength',
            documentIsGenerating: 'documents/isGenerating',
        }),
        ...documentsMapper.mapGetters([
            'isAdded',
        ]),
        ...documentsMapper.mapState({
            document: 'currentDocument',
            addedDocument: 'addedDocument'
        }),
    },

    methods: {
        addDocument() {
            if (this.document) {
                this.$store.dispatch('documents/add', this.document);
            }
        }
    },

    watch: {}
};
