import getHttp from '../helpers/httpHelper';
import InternetConnectionError from '../helpers/errors/InternetConnectionError';
import {REQUEST_TIMEOUT_MS} from '../../settings';
import NotFoundError from '../helpers/errors/NotFoundError';
import AddressInfoError from '../helpers/errors/AddressInfoError';

const fs = require('fs');
const path = require('path');
const crypto = require('crypto');
const offlineStoragePath = require('./offline-storage-path').default;

const sha1 = (data) => {
    return crypto.createHash('sha1').update(data, 'binary').digest('hex');
};

const createDirectoryIfNotExists = (dir) => {
    if (!fs.existsSync(dir)) {
        fs.mkdirSync(dir,{ recursive: true });
    }
};

const isInternetConnectionProblem = (err) => {
    const errorMsg = err.message;

    // ETIMEDOUT Error
    const isETIMEDOUT = errorMsg.includes('ETIMEDOUT');

    // ENETDOWN Error
    const isENETDOWN = errorMsg.includes('ENETDOWN');

    return isETIMEDOUT || isENETDOWN;
};

const isAddressInfoProblem = (err) => {
    const errorMsg = err.message;

    // ENOTFOUND Error
    const isENOTFOUND = errorMsg.includes('ENOTFOUND');

    return isENOTFOUND;
};

function getPathFileName(url, extension, preservePathElements) {
    if (preservePathElements) {
        return url.split('/').
            slice(-preservePathElements).
            join('/');
    }
    return `${sha1(url)}.${extension}`;
}

/**
 * Store asset in offline storage
 * @param url
 * @param extension
 * @param type
 * @param preservePathElements - how many levels of the path should be preserved and stored in the offline storage
 * @returns {Promise<unknown>}
 */
const storeAsset = async (url, extension, type, preservePathElements) => {
    const pathFileName = getPathFileName(url, extension, preservePathElements);
    const offlineStoragePathForType = path.join(offlineStoragePath, type);
    const filepathForType = path.join(offlineStoragePathForType, pathFileName);
    const storageLinkForResult = `storage://${type}/${pathFileName}`; // eslint-disable-line

    if (fs.existsSync(filepathForType)) {
        // eslint-disable-next-line
        console.log('file already exists', filepathForType);
        return storageLinkForResult;
    }

    createDirectoryIfNotExists(offlineStoragePathForType);

    if (preservePathElements >= 2) {
        const dirPath = filepathForType.split(path.sep).slice(0, -1).join('/');
        createDirectoryIfNotExists(dirPath);
    }

    const http = getHttp(url);
    const file = fs.createWriteStream(filepathForType);

    return new Promise((resolve, reject) => {
        const request = http.get(url, (response) => {
            const { statusCode } = response;

            let error;
            if (statusCode === 404) {
                error = new NotFoundError(`Image ${url} returned a 404 response`);
            } else if (statusCode !== 200) {
                error = new Error(`Request Failed.\nStatus Code: ${statusCode}`);
            }

            if (error) {
                // Consume response data to free up memory
                response.resume();
                reject(error);
            }

            response.pipe(file);

            file.on('error', (err) => {
                file.unpipe();
                file.end();
                reject(new Error(err));
            });

            file.on('finish', () => {
                file.close(() => {
                    resolve(storageLinkForResult);
                });
            });
        }).on('error', (err) => {
            if (isInternetConnectionProblem(err)) {
                reject(new InternetConnectionError(err));
            } else if (isAddressInfoProblem(err)) {
                reject(new AddressInfoError(err));
            } else {
                reject(new Error(err));
            }
        });

        // Manually stop request after 60 seconds
        request.setTimeout(REQUEST_TIMEOUT_MS, () => {
            request.abort();
            reject(new InternetConnectionError('Manual request timeout reached'));
        });
    });
};

export default storeAsset;
