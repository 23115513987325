
export default {
    name: 'LocationCard',

    components: {
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        location: {
            type: Object,
            default: () => {},
        },
        isInfo: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            cn: 'm-locationCard',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
    },

    methods: {
    },
};
