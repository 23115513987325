const pushSublevelsToTrail = (activePageTrail, navItem) => {
    let currentNavItem = navItem;
    while (currentNavItem.parent && !currentNavItem.parent._empty) {
        const { parent } = currentNavItem;
        const index = Object.values(parent.subList).indexOf(currentNavItem);
        activePageTrail.push(index);
        currentNavItem = currentNavItem.parent;
    }
    return currentNavItem;
};

export default pushSublevelsToTrail;