import { createNamespacedHelpers } from 'vuex';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';
import SendDocument from '../../_molecules/sendDocument/_component.vue';
import scrollTriggers from '../../../assets/js/scrollTriggers';

const documentsMapper = createNamespacedHelpers('documents');
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'SectionWrapper',

    components: {
        LoadingSpinner,
        SendDocument,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        propertiesToLoad: {
            type: Array,
            default: () => [],
        },
        timerOffsetMs: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            cn: 'o-sectionWrapper',
            isContentLoaded: false,
            isTimerComplete: false,
        };
    },

    computed: {
        ...documentsMapper.mapState([
            'lastSectionId',
        ]),
        ...baseMapper.mapState([
            'isPrinting',
        ]),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
        isLastSection() {
            if (this.isLoaded) {
                return this.$refs.section.id === this.lastSectionId;
            }
        },
        isFirstSection() {
            if (this.isLoaded) {
                return this.$refs.section.id === 'overview';
            }
        },
        pageIsPrintable() {
            return this.$route.meta && this.$route.meta.isPrintable;
        },
        isLoaded() {
            return this.isContentLoaded && (this.isTimerComplete || this.isPrinting);
        }
    },

    watch: {
        propertiesToLoad: {
            handler() {
                if (!this.isContentLoaded) {
                    this.checkLoadingState();
                }
            },
            deep: true,
        },
        isLoaded() {
            if (this.isLoaded && !this.isPrinting) {
                this.$nextTick(() => {
                    scrollTriggers.init();
                    this.$emit('loaded');
                });
            }
        }
    },

    mounted() {
        this.checkLoadingState();
        setTimeout(() => {
            this.isTimerComplete = true;
        }, this.timerOffsetMs);
    },

    created() {},

    methods: {
        checkLoadingState() {
            let sectionLoaded = true;

            this.propertiesToLoad.forEach((property) => {
                if (!property) {
                    sectionLoaded = false;
                }
            });

            if (sectionLoaded) {
                this.isContentLoaded = true;
            }
        },
    },
};
