<template>
  <div :class="[
         'o-documentListing__document',
         `o-documentListing__document--level-${level}`,
         {'o-documentListing__document--added': documentAdded(`${document.id}`)}]"
       @click="toggleDocument()">
    <div class="o-documentListing__documentTitle">
      {{ document.name }}
    </div>
    <div v-if="document && document.pdfDocument && false" />

    <AddIcon :is-added="documentAdded(`${document.id}`)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddIcon from '../../_atoms/addIcon/_component.vue';

export default {
    name: 'Document',

    components: {
        AddIcon,
    },

    props: {
        level: {
            type: Number,
            required: true,
        },
        document: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            documentAdded: 'documents/isAdded',
        }),
    },

    methods: {
        getLinkFrom(document) {
            if (document.linkType === 'asset') {
                return document.relationships['pdf-document'];
            }
            return document.link;
        },
        getDocumentObject(document) {
            if (document.linkType === 'asset') {
                return {
                    id: `${document.id}`,
                    type: 'pdf',
                    tableOfContentsTitle: this.document.pdfName || this.document.name,
                    url: document.pdfDocument.storageUrl,
                };
            }
            return {
                id: `${document.id}`,
                tableOfContentsTitle: document.pdfName || document.name,
                urlPath: document.link,
            };
        },
        addDocument() {
            this.$store.dispatch('documents/add', this.getDocumentObject(this.document));
        },
        removeDocument() {
            this.$store.dispatch('documents/remove', `${this.document.id}`);
        },
        toggleDocument() {
            if (this.documentAdded(`${this.document.id}`)) {
                this.removeDocument();
            } else {
                this.addDocument();
            }
        }
    },
};
</script>
