import { viewConditionHelper, transformHelper } from '../../../helpers/dataHelper';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import Accordion from './modules/accordion/_component.vue';
import BulletPoints from './modules/bulletPoints/_component.vue';
import BulletPointsAccordion from './modules/bulletPointsAccordion/_component.vue';
// import FullPageSlider from './modules/fullPageSlider/_component.vue';
import FullPageSlidesUnderneath from './modules/fullPageSlidesUnderneath/_component.vue';
import Headline from './modules/headline/_component.vue';
import ImageGallery from './modules/imageGallery/_component.vue';
import ImageSingle from './modules/imageSingle/_component.vue';
import ImageSliderWithDescription from './modules/imageSliderWithDescription/_component.vue';
import ImageWithText from './modules/imageWithText/_component.vue';
import InfoNumbers from './modules/infoNumbers/_component.vue';
import LightboxSlider from './modules/lightboxSlider/_component.vue';
import Quote from './modules/quote/_component.vue';
import RichText from './modules/richText/_component.vue';
import Video from './modules/video/_component.vue';

export default {
    name: 'ContentBuilderSection',

    components: {
        SectionWrapper,
        Accordion,
        BulletPoints,
        BulletPointsAccordion,
        // FullPageSlider,
        FullPageSlidesUnderneath,
        Headline,
        ImageGallery,
        ImageSliderWithDescription,
        ImageWithText,
        ImageSingle,
        InfoNumbers,
        LightboxSlider,
        Quote,
        RichText,
        Video,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        contentBlocks: {
            type: Array,
            default: () => [],
        },
        pageHighlightColorBlue: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...viewConditionHelper,
        ...transformHelper,
    },
};
