import DocumentGeneratorCoverSheetPage from '../../views/documents/documentGeneratorCoverSheet/_component.vue';
import DocumentGeneratorLastSheetPage from '../../views/documents/documentGeneratorLastSheet/_component.vue';

const routes = [
    {
        path: '/document-generator/cover-sheet',
        name: 'document-generator-cover-sheet',
        component: DocumentGeneratorCoverSheetPage,
        meta: {
            isPrintable: true,
            requiresAuth: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/document-generator/last-sheet',
        name: 'document-generator-last-sheet',
        component: DocumentGeneratorLastSheetPage,
        meta: {
            isPrintable: true,
            requiresAuth: true,
            hideAppHeader: true,
        },
    },

];

export default routes;