<template>
  <div :class="{
    'o-Navigation__level--x': true,
    [`o-Navigation__level--${level}`]: true,
    [`o-Navigation__level--${level}--active`]: hasActiveLink(level), // link in submenu is selected
    'o-Navigation__submenu--active': activeParent,
    'o-Navigation__submenu': true }">
    <div :class="[`o-Navigation__wrapper o-Navigation__wrapperLevel--${level}`]">
      <ul>
        <li v-for="(linkItem, index) in links"
            :key="index"
            :class="['o-Navigation__linkLevel-x', `o-Navigation__linkLevel--${level}`]">
          <div class="o-Navigation__linkAnimationWrapper">
            <RouterLink v-if="linkItem.link"
                        :to="linkItem.link"
                        tag="a"
                        :class="[
                          'o-Navigation__link',
                          { 'o-Navigation__link--active': isActiveLink(linkItem) },
                        ]"
                        @click.native="toggleVisibility">
              {{ linkItem.name }}
            </RouterLink>
          </div>
          <div class="o-Navigation__linkAnimationWrapper">
            <div v-if="hasChildren(linkItem)"
                 :class="['o-Navigation__link', {'o-Navigation__link--active': isActive(index) }]"
                 @click="select(index, level)">
              {{ linkItem.name.substring(0,linkItem.name.lastIndexOf(" ")) }}
              <span class="o-Navigation__linkLastWord">
                  {{ linkItem.name.substring(linkItem.name.lastIndexOf(" ")+1) }}
                <ChevronIconRight class="o-Navigation__linkArrow"
                                width="30px"
                                height="30px"
                                color="black" />
              </span>
            </div>
          </div>
          <MainMenuSub v-if="hasChildren(linkItem)"
                       :nav-ref="navRef"
                       :level="level + 1"
                       :links="Object.values(linkItem.subList)"
                       :active-parent="isActive(index)"
                       :is-active-link="isActiveLink"
                       :toggle-visibility="toggleVisibility" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ChevronIconRight from '../../_svg/icon/chevron/right.vue';

const navigationMapper = createNamespacedHelpers('navigation');

export default {
    name: 'MainMenuSub',

    components: {
        ChevronIconRight
    },

    props: {
        level: {
            type: Number,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
        activeParent: {
            type: Boolean,
            default: false,
        },
        toggleVisibility: {
            type: Function,
            required: true,
        },
        navRef: {
            type: HTMLDivElement,
            required: true,
        },
        isActiveLink: {
            type: Function,
            required: true,
        }
    },

    computed: {
        ...navigationMapper.mapState([
            'currentMenuState',
        ]),
    },

    watch: {},

    methods: {
        isActive(index) {
            return this.activeParent && this.currentMenuState[this.level - 1] === index;
        },
        hasActiveLink(level) {
            const hasActiveLinkInCurrentState = this.currentMenuState.length >= level;
            let hasActiveRouterLink = false;
            this.links.forEach((link) => {
                if (this.isActiveLink(link)) {
                    hasActiveRouterLink = true;
                }
            });
            return hasActiveLinkInCurrentState || hasActiveRouterLink;
        },
        select(index, level) {
            this.$store.dispatch('navigation/resetCurrentMenuStateToLevel', level - 1);
            this.$store.dispatch('navigation/pushToCurrentMenuState', index);
        },
        hasChildren(linkItem) {
            return linkItem.subList && Object.values(linkItem.subList).length > 0;
        },
    },
};
</script>

<style lang="scss">
  @import './style';
</style>
