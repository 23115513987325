import { createNamespacedHelpers } from 'vuex';
import { mapObject } from 'vuex-json-relationship-resolver';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import Accordion from '../../_organisms/accordion/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import '../../../assets/js/scrollTriggers';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'FaqSection',

    components: {
        Accordion,
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        faqs: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-faqSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
        ...mapObject({
            product: {
                type: 'product',
                filter: {
                    slug: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.slug;
                    }
                }
            },
        }),
        productName() {
            return this.product ? this.product.title : null;
        }
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
    },
};
