const attributesGetterSetter = ({ object }) => {
    if (object) {
      for (let key in object.attributes) {
        if  (!Object.prototype.hasOwnProperty.call(object.attributes, key)) continue
        if (Object.prototype.hasOwnProperty.call(object, key)) continue
        Object.defineProperty(object, key, {
          get () {
            return object.attributes[key]
          },
          set (value) {
            object.attributes[key] = value
          }
        })
      }
      return object
    }
}

export default attributesGetterSetter
