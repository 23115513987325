import LaskaLogo from '../../_svg/LaskaLogo.vue';

export default {
    name: 'LogoWithLink',

    components: {
        LaskaLogo
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        colorSwitchesMobile: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            cn: 'm-logoWithLink',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        backToStart() {
            this.$router.push('/').catch(() => {});
        },
    },
};
