export default {
    name: 'ApplicationPrintTemplate',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        slides: {
            type: Array,
            default: () => [],
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-applicationPrintTemplate',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        categories() {
            return [...new Set(this.slides.map(slide => slide.category))];
        }
    },
	
    watch: {},

    mounted() {},
	
    created() {},
	
    methods: {
        getApplicationsBy(category) {
            return this.slides.filter(slide => slide.category === category);
        }
    },
};
