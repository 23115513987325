<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <defs>
        <path id="document-a" d="M21.1270764,42.7 L39.8729236,42.7 L39.8729236,25.925 L32.025,18.3 L21.1270764,18.3 L21.1270764,42.7 Z M18.3,15.25 L33.55,15.25 L42.7,24.4 L42.7,45.75 L18.3,45.75 L18.3,15.25 Z M30.5,15.25 L33.55,15.25 L33.55,27.45 L30.5,27.45 L30.5,15.25 Z M42.7,24.4 L42.7,27.45 L32.025,27.45 L32.025,24.4 L42.7,24.4 Z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <polygon points="0 0 40 0 40 40 0 40" />
        <g transform="translate(-10 -11)">
          <mask id="document-b" fill="#fff">
            <use xlink:href="#document-a" />
          </mask>
          <use fill="#979797" fill-rule="nonzero" xlink:href="#document-a" />
          <g fill="#E11624" fill-rule="nonzero" mask="url(#document-b)">
            <rect width="61" height="61" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'Document',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationDocument',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationDocument {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationDocument--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationDocument--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationDocument--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationDocument--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
