import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../../i18n';

const checkApiVersion = async (context) => {
        const url = `${context.rootState.base.http.baseUrl}auth/ping`;
        const headers = {
            'Authorization': `Bearer ${context.state.token}`,
            'X-Site-Language': context.rootState.base.language,
            'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
        };
        const response = await fetch(url, {headers});

        if (context.rootState.desktopApp && response.status === 404) {
            await context.dispatch('notifications/addNotification', {
                id: uuidv4(),
                icon: 'check',
                text: `${i18n.t('notifications.oldVersionMessage1')} <a href="http://update-service.laska-app.com/">${i18n.t('notifications.oldVersionMessage2')}</a> ${i18n.t('notifications.oldVersionMessage3')}`,
                deleteOnClose: true,
                singleNotificationKey: 'oldVersion',
            }, { root: true });
        }
};

export default checkApiVersion;
