/**
 * source: https://github.com/videojs/video.js/blob/master/docs/guides/options.md
 */

const videoPlayerOptions = {


    // false: the same as having no attribute on the video element, won't autoplay
    // true: the same as having attribute on the video element, will use browsers autoplay
    // 'muted': will mute the video element and then manually call play() on loadstart. This is likely to work.
    // 'play': will call play() on loadstart, similar to browsers autoplay
    // 'any': will call play() on loadstart and if the promise is rejected it will mute the video element then call play().
    autoplay: false,

    // Determines whether or not the player has controls that the user can interact with.
    // Without controls the only way to start the video playing is with the autoplay attribute or through the Player API.
    controls: true,

    // Sets the display height of the video player in pixels.
    height: '100',

    // Causes the video to start over as soon as it ends.
    loop: false,

    // Will silence any audio by default.
    muted: false,

    // Suggests to the browser whether or not the video data should begin downloading as soon as the <video> element is loaded. Supported values are:
    // 'auto': Start loading the video immediately (if the browser supports it). Some mobile devices will not preload the video in order to protect their users' bandwidth/data usage. This is why the value is called 'auto' and not something more conclusive like 'true'.
    // 'metadata': Load only the meta data of the video, which includes information like the duration and dimensions of the video. Sometimes, the meta data will be loaded by downloading a few frames of video.
    // 'none': Don't preload any data. The browser will wait until the user hits "play" to begin downloading.
    preload: 'none',

    // Puts the player in fluid mode and the value is used when calculating the dynamic size of the player. The value should represent a ratio - two numbers separated by a colon (e.g. "16:9" or "4:3").
    aspectRatio: '16:9',

    // When used with the responsive option, sets breakpoints that will configure how class names are toggled on the player to adjust the UI based on the player's dimensions.
    // By default, the breakpoints are:
    // vjs-layout-tiny        0-210
    // vjs-layout-x-small   211-320
    // vjs-layout-small     321-425
    // vjs-layout-medium    426-768
    // vjs-layout-large     769-1440
    // vjs-layout-x-large  1441-2560
    // vjs-layout-huge     2561+

    breakpoints: {
        tiny: 300,
        xsmall: 400,
        small: 500,
        medium: 600,
        large: 700,
        xlarge: 800,
        huge: 900
    },

    // When true, the Video.js player will have a fluid size. In other words, it will scale to fit its container.
    // fluid: true,

    // Video.js indicates that the user is interacting with the player by way of the "vjs-user-active" and "vjs-user-inactive" classes and the "useractive" event.
    // The inactivityTimeout determines how many milliseconds of inactivity is required before declaring the user inactive. A value of 0 indicates that there is no inactivityTimeout and the user will never be considered inactive.
    inactivityTimeout: 10,

    // A language code matching one of the available languages in the player. This sets the initial language for a player, but it can always be changed.
    language: 'en',

    // Explicitly set a default value for the associated tech option.
    nativeControlsForTouch: false,


    // Allows overriding the default message that is displayed when Video.js cannot play back a media source.
    notSupportedMessage: 'Not Supported',

    // An array of numbers strictly greater than 0, where 1 means regular speed (100%), 0.5 means half-speed (50%), 2 means double-speed (200%), etc. If specified, Video.js displays a control (of class vjs-playback-rate) allowing the user to choose playback speed from among the array of choices. The choices are presented in the specified order from bottom to top.
    playbackRates: [],

    // This supports having plugins be initialized automatically with custom options when the player is initialized - rather than requiring you to initialize them manually.
    plugins: {},

    // Setting this option to true will cause the player to customize itself based on responsive breakpoints (see: breakpoints option).
    // When this option is false (the default), responsive breakpoints will be ignored.
    responsive: false,


    // Defines the order in which Video.js techs are preferred. By default, this means that the Html5 tech is preferred. Other registered techs will be added after this tech in the order in which they are registered.
    techOrder: ['html5'],

    userActions: {
        // Controls how double-clicking on the player/tech operates. If set to false, double-clicking is disabled. If undefined or set to true, double-clicking is enabled and toggles fullscreen mode. To override the default double-click handling, set userActions.doubleClick to a function which accepts a dblclick event:
        // doubleClick: () => {}

        // Controls how player-wide hotkeys operate. If set to false, or undefined, hotkeys are disabled. If set to true or an object (to allow definitions of fullscreenKey etc. below), hotkeys are enabled as described below. To override the default hotkey handling, set userActions.hotkeys to a function which accepts a keydown event:
        // Default  hotkey handling is:
        // f        toggle fullscreen    only enabled if a Fullscreen button is present in the Control Bar
        // m        toggle mute          always enabled, even if no Control Bar is present
        // k        toggle play/pause    always enabled, even if no Control Bar is present
        // Space    toggle play/pause    always enabled, even if no Control Bar is present
        // Hotkeys  require player focus first. Note that the Space key activates controls such as buttons and menus if that control has keyboard focus. The other hotkeys work regardless of which control in the player has focus.
        hotkeys: true,
    },

    // This is used to determine which children (by component name) and in which order they are created on a player
    children: {
        mediaLoader: true, // (has no DOM element)
        posterImage: false,
        textTrackDisplay: true,
        loadingSpinner: true,
        bigPlayButton: false,
        controlBar: {
            children: [
                'playToggle',
                'progressControl',
                'currentTimeDisplay',
                'timeDivider',
                'durationDisplay',
                'fullscreenToggle'
            ]
        },
        errorDisplay: true, // (hidden, until there is an error)
        textTrackSettings: true,
        resizeManager: true, // (hidden)
    }
};

export default videoPlayerOptions;
