export default {
    name: 'ContentBuilderHeadline',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        text: {
            type: String
        },
        size: {
            type: String,
            default: 'h2'
        }
    },

    data() {
        return {
            cn: 'o-contentBuilderHeadline',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
