const http = require('http');
const https = require('https');

const getHttp = (url) => {
    let requester = https;
    if (url.indexOf('http://') >= 0) {
        requester = http;
    }
    return requester;
};

export default getHttp;