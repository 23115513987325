import VideoPlayer from '../../../../_molecules/videoPlayer/_component.vue';

export default {
    name: 'ContentBuilderVideo',

    components: {
        VideoPlayer,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        video: {
            type: Object,
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderVideo',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
