import { mapObject } from 'vuex-json-relationship-resolver';
import ImageComponent from '../../../../components/_atoms/image/_component.vue';
import IconDown from '../../../../components/_svg/icon/chevron/down.vue';
import IconRight from '../../../../components/_svg/icon/chevron/right.vue';
import LoadingSpinner from '../../../../components/_atoms/loadingSpinner/_component.vue';
import AuthenticationPage from '../../../../layouts/authenticationPage/_component.vue';
import animations from '../../../../assets/js/animations';

export default {
    name: 'registration',

    components: {
        ImageComponent,
        IconDown,
        IconRight,
        LoadingSpinner,
        AuthenticationPage,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-registration',
            errorMessage: '',
            gender: 'm',
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            phone: '',
            password: '',
            repeatPassword: '',
            privacyPolicy: false,
            newsletter: false,
            countryId: null,
            loading: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({countries: {type: 'country', isArray: true}}),
        ...mapObject({navigationMeta: {type: 'navigation-meta', isArray: true}}),
        sortedCountries() {
            if (!Array.isArray(this.countries)) return;

            const independentCountries = this.countries.filter(country => country.isIndependent);
            return independentCountries.sort((a, b) => {
                return a.fromCurrentSite.common.localeCompare(b.fromCurrentSite.common);
            });
        }
    },

    watch: {},

    mounted() {
        this.doAnimations();
    },

    created() {
        this.$store.dispatch('rr/find', 'country/_all');
        this.$store.dispatch('rr/find', 'navigation-meta/_all', { root: true });
    },

    methods: {
        submit() {
            this.loading = true;
            this.errorMessage = null;

            this.$store.dispatch('auth/register', {
                gender: this.gender,
                firstname: this.firstName,
                lastname: this.lastName,
                company: this.company,
                phone: this.phone,
                countryId: this.countryId,
                email: this.email,
                password: this.password,
                password_confirmation: this.repeatPassword,
                acceptTerms: this.privacyPolicy,
                newsletter: this.newsletter,
            }).then(() => {
                this.loading = false;
                this.$router.push('/registration-email-sent');
            }).catch((errorMessage) => {
                this.errorMessage = errorMessage;
                this.loading = false;
            });
        },
        doAnimations() {
            const animateWrapper = this.$refs.registrationForm;
            animations.init(animateWrapper);
        }
    },
};
