import IconAdd from '../../_svg/icon/notification/add.vue';
import IconCheck from '../../_svg/icon/notification/check.vue';
import IconClose from '../../_svg/icon/nav/close.vue';
import IconDocument from '../../_svg/icon/notification/document.vue';
import IconError from '../../_svg/icon/notification/error.vue';
import IconSent from '../../_svg/icon/notification/sent.vue';
import IconUpdate from '../../_svg/icon/notification/update.vue';
import IconWifiOff from '../../_svg/icon/notification/wifi-off.vue';
import IconWifiOn from '../../_svg/icon/notification/wifi-on.vue';

import actions from './_actions';

export default {
    name: 'Notification',

    components: {
        IconAdd,
        IconCheck,
        IconClose,
        IconDocument,
        IconError,
        IconSent,
        IconUpdate,
        IconWifiOff,
        IconWifiOn,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        id: {
            type: String,
        },
        icon: {
            type: String,
            default: 'info',
        },
        text: {
            type: String,
            required: true,
        },
        boxStyle: {
            type: String,
            default: 'default',
        },
        action: {
            type: String,
            default: '',
        },
        actionText: {
            type: String,
            default: null,
        },
        linkedEntrySlug: {
            type: String,
            default: null,
        },
        linkedSectionType: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'm-notification',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers, `${this.cn}--${this.boxStyle}`];
        },

        iconColor() {
            return this.boxStyle === 'default' ? 'red' : 'black';
        },

        iconCloseColor() {
            return this.boxStyle === 'default' ? 'white' : 'black';
        },
    },

    methods: {
        removeNotification() {
            this.$el.style.height = `${this.$el.clientHeight}px`;

            setTimeout(() => {
                this.$el.parentElement.style.height = `${this.$el.parentElement.clientHeight - this.$el.clientHeight}px`;
                this.$el.classList.add('remove');
                setTimeout(() => {
                    this.$store.dispatch('notifications/removeNotification', this.id);
                }, 500);
            });
        },

        callNotificationAction() {
            const action = actions[this.action];
            if (action) {
                action(this);
            }
        },
    },
};
