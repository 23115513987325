
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 53.1 (72631) - https://sketchapp.com -->
    <title>151D69B1-8C2A-4B45-B138-DBEDA626D596@1.00x</title>
    <desc>Created with sketchtool.</desc>
    <g id="↳-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icon/docs/add" fill="#161A1B" fill-rule="nonzero">
            <path d="M27,24 L22,24 L22,29 L13,29 L13,11 L27,11 L27,24 Z M11,31 L23,31 L29,25 L29,9 L11,9 L11,31 Z M21,18 L24,18 L24,20 L21,20 L21,23 L19,23 L19,20 L16,20 L16,18 L19,18 L19,15 L21,15 L21,18 Z" id="Combined-Shape"></path>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
export default {
    name: 'docs_add',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-docs_add',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-docs_add {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-docs_add--black {
    path {
      fill: c('black');
    }
  }

  .svg-docs_add--red {
    path {
      fill: c('red');
    }
  }

  .svg-docs_add--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-docs_add--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    