import _ from 'lodash';
import Accordion from '../accordion/_component.vue';
import ImageComponent from '../../_atoms/image/_component.vue';
import imageSvg from '../../_svg/icon/imageIcon.vue';
import infoSvg from '../../_svg/icon/info.vue';

export default {
    name: 'AccordionImageCombination',

    components: {
        Accordion,
        ImageComponent,
        imageSvg,
        infoSvg,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        rendering: {
            type: Object,
            required: true,
        },
        highlightList: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            cn: 'o-accordionImageCombination',
            contentHeight: 0,
            showText: true,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {
        this.$nextTick(() => {
            this.calcContentHeight();
        });
    },
	
    created() {
        window.addEventListener('resize', this.resizeHandler);
    },
	
    methods: {
        toggleItem() {
            this.showText = !this.showText;
        },
        calcContentHeight() { // only relevant for mobile will be ignored >= s
            if (this.$refs.accordion && this.$refs.imageWrapper) {
                const offset = 30; // so accordion and image will overlap
                const accordionHeight = this.$refs.accordion.$el.clientHeight;
                const imageHeight = this.$refs.imageWrapper.clientHeight;
                this.contentHeight = accordionHeight + imageHeight - offset;
            }
        },
        resizeHandler: _.debounce(function() { // eslint-disable-line
            this.$refs.accordion.closeAll();
            window.setTimeout(this.calcContentHeight, 300); // animation take 0.25s
        }, 50),
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeHandler);
    },
};
