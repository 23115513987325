export default {
    name: 'IconButton',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            cn: 'a-iconButton',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    methods: {
        click(e) {
            this.$emit('click', e);
        },
    },
};
