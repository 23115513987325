<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <g fill="none" fill-rule="evenodd">
        <polygon points="0 0 40 0 40 40 0 40" />
        <path fill="#E11624" fill-rule="nonzero" d="M20.5,10.4090909 L20.5,15.4545455 L27.25,8.72727273 L20.5,2 L20.5,7.04545455 C13.04125,7.04545455 7,13.0663636 7,20.5 C7,23.1404545 7.77625,25.5959091 9.0925,27.6645455 L11.55625,25.2090909 C10.796875,23.8131818 10.375,22.1986364 10.375,20.5 C10.375,14.9331818 14.914375,10.4090909 20.5,10.4090909 Z M31.9075,13.3354545 L29.44375,15.7909091 C30.18625,17.2036364 30.625,18.8013636 30.625,20.5 C30.625,26.0668182 26.085625,30.5909091 20.5,30.5909091 L20.5,25.5454545 L13.75,32.2727273 L20.5,39 L20.5,33.9545455 C27.95875,33.9545455 34,27.9336364 34,20.5 C34,17.8595455 33.22375,15.4040909 31.9075,13.3354545 L31.9075,13.3354545 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'Update',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationUpdate',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationUpdate {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationUpdate--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationUpdate--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationUpdate--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationUpdate--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
