const isNotEmpty = (array) => {
    let result = false;
    if (array && array.length > 0) {
        array.forEach(entry => {
            if (!result && !entry._loading && !entry._empty) {
                result = true;
            }
        });
    }
    return result;
};

const isLoadedAndExists = (object) => {
    return object && !object._loading && !object._empty;
};

const relationsNotEmpty = (entry, key) => {
    return !!entry.relationships &&
           !!entry.relationships[key] &&
           entry.relationships[key].length > 0;
};

const relationNotEmpty = (entry, key) => {
    return !!entry.relationships &&
           !!entry.relationships[key];
};

const conditionHelper = {
    isNotEmpty,
    isLoadedAndExists,
    relationsNotEmpty,
    relationNotEmpty,
};

export default conditionHelper;
