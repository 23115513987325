import FullPageSlide from '../../../../_molecules/fullPageSlide/_component.vue';

export default {
    name: 'ContentBuilderFullPageSlidesUnderneath',

    components: {
        FullPageSlide,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        images: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderFullPageSlidesUnderneath',
            bgColor: this.items.length > 0 ? this.items[0].backgroundColor: 'black',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
