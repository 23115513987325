const initRenderProcessEvents = (store) => {
    const { isDesktopApp } = store.state.base;

    if (isDesktopApp) {
        // eslint-disable-next-line
        const { ipcRenderer } = require('electron');

        ipcRenderer.on('updateNotification', (evt, notification) => {
            store.commit('notifications/ADD_notification', notification);
        });

        ipcRenderer.on('deleteUpdateNotifications', () => {
            store.commit('notifications/REMOVE_updateNotifications', null, { root: true });
        });

        ipcRenderer.on('checkApiVersion', () => {
            if (!store.state.desktopApp.isOffline) {
                store.dispatch('desktopApp/checkApiVersion', null, { root: true });
            }
        });
    }
};

export default initRenderProcessEvents;
