import {createNamespacedHelpers} from 'vuex';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';
import Tabs from '../../_molecules/tabs/_component.vue';
import WR360 from '@webrotate360/imagerotator';
import '@webrotate360/imagerotator/build/css/thin.css';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Webrotate360',

    components: {
        LoadingSpinner,
        Tabs,
    },

    data() {
        return {
            cn: 'm-webrotate360',
            webrotate360: null,
            showWebrotate360: true,
            play: false,
            isLoading: false,
            showHotspotVideo: false,
            hotspotVideoSrc: null,
            currentRenderingId: 0,
        };
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        renderings: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers.map(mod => `${this.cn}--${mod}`).
                join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapGetters(['isOffline', 'isInPdfGenerationMode']),
        currentRendering() {
            return this.renderings[this.currentRenderingId] || null;
        },
        tabs() {
            return this.renderings.map((r, index) => ({
                id: index,
                label: r.name,
            }));
        },
        previewImage() {
            if (!this.currentRendering) {
                return null;
            }
            if (this.isOffline) {
                return `storage://webrotate-360/${this.currentRendering.renderingFolder}/${this.currentRendering.coverImagePath}`;
            }
            return `${this.currentRendering.baseUrl}${this.currentRendering.renderingFolder}/${this.currentRendering.coverImagePath}`;
        },
    },

    mounted() {
        if (this.isOffline) {
            this.play360View();
        }
    },

    methods: {
        play360View() {
            if (this.showWebrotate360) {
                this.play = true;
                this.isLoading = true;
                this.$nextTick(() => {
                    this.webrotate360 = WR360.ImageRotator.Create(
                        'webrotate360');
                    this.webrotate360.licenseCode = 'WZcFamc9NxzgiTY9n1eYl4jOytSWwdIDVdtWuaOMWGUcqRl71GuVwRwnK5yg39OsYm09eAfatg==';
                    if (this.isOffline) {
                        this.webrotate360.settings.configFileURL = `storage://webrotate-360/${this.currentRendering.renderingFolder}/${this.currentRendering.xmlFile}`;
                        this.webrotate360.settings.graphicsPath = `storage://webrotate-360/${this.currentRendering.renderingFolder}/theme`;
                    } else {
                        this.webrotate360.settings.configFileURL = `${this.currentRendering.baseUrl +
                        this.currentRendering.renderingFolder}/${this.currentRendering.xmlFile}`;
                        this.webrotate360.settings.graphicsPath = `${this.currentRendering.baseUrl +
                        this.currentRendering.renderingFolder}/theme`;
                    }
                    this.webrotate360.settings.alt = '3D Rendering';
                    this.webrotate360.settings.apiReadyCallback = (api) => {
                        this.isLoading = false;
                       api.hotspots.onActivate((hotspot) => {
                           this.activateHotspot(hotspot, api);
                        });
                    };
                    this.webrotate360.runImageRotator();
                });
            }
        },
        hideHotspotVideo() {
            this.hotspotVideoSrc = null;
            this.showHotspotVideo = false;
        },
        activateHotspot(hotspot, api) {
            const hotspotSource = hotspot.hotspotConfig.hotspotInfo.src;
            if (hotspotSource.endsWith('.gif')) {
                if (this.isOffline) {
                    this.hotspotVideoSrc = `storage://webrotate-360/${this.currentRendering.renderingFolder}/${hotspotSource.slice(0, -3)}mp4`;
                } else {
                    this.hotspotVideoSrc = `${this.currentRendering.baseUrl}${this.currentRendering.renderingFolder}/${hotspotSource.slice(0, -3)}mp4`;
                }
                this.showHotspotVideo = true;
                this.$nextTick(() => {
                    this.$refs.hotspotVideo.play();
                    api.hotspots.deactivate(hotspot.hotspotConfig.id);
                });
            }
        },
        updateActiveRendering(id) {
            this.currentRenderingId = id;
            if (!this.isLoading) {
                this.webrotate360.delete();
            } else {
                this.webrotate360.settings.apiReadyCallback = () => {
                    this.webrotate360.delete();
                };
            }
            this.play360View();
        },
    },
};