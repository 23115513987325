import IconDownload from '../../_svg/icon/download.vue';
import IconPlay from '../../_svg/icon/player/play-start.vue';
import ImageWrapper from '../../_atoms/image/_component.vue';

export default {
    name: 'MediaLibraryItem',

    components: {
        ImageWrapper,
        IconDownload,
        IconPlay
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        item: {
            type: Object,
            default: () => {},
        }
    },

    data() {
        return {
            cn: 'm-mediaLibraryItem',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
        thumbnail() {
            return this.item.type === 'video' ? this.item.video.thumbnail.previews.thumbnailPreview : this.item.image.previews.thumbnailPreview;
        },
        srcset() {
            return this.item.type === 'video' ? this.item.video.thumbnail.srcset.thumbnail : this.item.image.srcset.thumbnail;
        },
        downloadUrl() {
            const url = this.item.type === 'video' ? this.item.video.url : this.item.image.original;
            return url.replace('/assets/', '/download/assets/');
        }
    },

    mounted() {},

    methods: {},
};
