import ScrollSpy from '../../components/_organisms/scrollSpy/_component.vue';

export default {
    name: 'detailPage',

    components: {
        ScrollSpy,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        sections: {
            type: Array,
            default: () => [],
        },
        showScrollSpy: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            cn: 'p-detailPage',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        lastSectionId() {
            const lastSection = this.sections[this.sections.length - 1];
            return lastSection ? lastSection.id : '';
        },
    },

    watch: {
        lastSectionId() {
            this.$store.dispatch('documents/setLastSectionId', this.lastSectionId);
        },
    },

    mounted() {
        this.$store.dispatch('documents/setLastSectionId', this.lastSectionId);
    },

    created() {},

    methods: {
        scrollToSection(index) {
            if (this.sections && this.sections[index]) {
                this.sections[index].scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
    },

    destroyed() {
        this.$store.dispatch('documents/setLastSectionId', null);
    },
};
