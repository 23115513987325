import registerError from '../../../../helpers/registerError';
import deleteLocalStorageFiles from './deleteLocalStorageFiles';
import InternetConnectionError from '../../../../helpers/errors/InternetConnectionError';
import DataDownloadError from '../../../../helpers/errors/download/DataDownloadError';
import ImageDownloadError from '../../../../helpers/errors/download/ImageDownloadError';
import PdfDownloadError from '../../../../helpers/errors/download/PdfDownloadError';
import RenderingDownloadError from '../../../../helpers/errors/download/RenderingDownloadError';
import VideoDownloadError from '../../../../helpers/errors/download/VideoDownloadError';
import Webrotate360DownloadError from '../../../../helpers/errors/download/Webrotate360DownloadError';
import {
    TYPE_IMAGE,
    TYPE_PDF,
    TYPE_RENDERING,
    TYPE_VIDEO,
    TYPE_VIDEO_THUMBNAIL,
    TYPE_WEBROTATE_360,
} from '../../../../../settings';

const clearDataDownloadStatus = (context) => {
    context.commit('SET_downloadStateObjectsTotal', 0);
    context.commit('SET_downloadStateObjectsDone', 0);
    context.dispatch('switchToOnline', { error: false, offlineStatusForUpdates: false });
};

const clearImageDownloadStatus = (context) => {
    context.commit('SET_downloadStateLoadingImagesTotal', 0);
    context.commit('SET_downloadStateLoadingImagesDone', 0);
    context.dispatch('setLoadingErrorType', TYPE_IMAGE);
    deleteLocalStorageFiles(TYPE_IMAGE);
};

const clearPdfDownloadStatus = (context) => {
    context.commit('SET_downloadStateLoadingPdfsTotal', 0);
    context.commit('SET_downloadStateLoadingPdfsDone', 0);
    context.dispatch('setLoadingErrorType', TYPE_PDF);
    deleteLocalStorageFiles(TYPE_PDF);
};

const clearRenderingDownloadStatus = (context) => {
    context.commit('SET_downloadStateLoadingRenderingsTotal', 0);
    context.commit('SET_downloadStateLoadingRenderingsDone', 0);
    context.dispatch('setLoadingErrorType', TYPE_RENDERING);
    deleteLocalStorageFiles(TYPE_RENDERING);
};

const clearWebrotate360DownloadStatus = (context) => {
    context.commit('SET_downloadStateLoadingWebrotate360Total', 0);
    context.commit('SET_downloadStateLoadingWebrotate360Done', 0);
    context.dispatch('setLoadingErrorType', TYPE_WEBROTATE_360);
    deleteLocalStorageFiles(TYPE_WEBROTATE_360);
};

const clearVideoDownloadStatus = (context) => {
    context.commit('SET_downloadStateLoadingVideosTotal', 0);
    context.commit('SET_downloadStateLoadingVideosDone', 0);
    context.dispatch('setLoadingErrorType', TYPE_VIDEO);
    deleteLocalStorageFiles(TYPE_VIDEO);
    deleteLocalStorageFiles(TYPE_VIDEO_THUMBNAIL);
};

const checkWhereErrorAppeared = (context) => {
    const dataStatistics = context.getters.loadingObjectsStatistics;
    const imageStatistics = context.getters.loadingImagesStatistics;
    const pdfStatistics = context.getters.loadingPdfsStatistics;
    const renderingStatistics = context.getters.loadingRenderingsStatistics;
    const webrotate360Statistics = context.getters.loadingWebrotate360Statistics;
    const videoStatistics = context.getters.loadingVideosStatistics;

    if (dataStatistics.total !== dataStatistics.done) { // error loading data object
        clearDataDownloadStatus(context);
    } else if (imageStatistics.total !== imageStatistics.done) { // error loading image
        clearImageDownloadStatus(context);
    } else if (renderingStatistics.total !== renderingStatistics.done) { // error loading rendering
        clearRenderingDownloadStatus(context);
    } else if (videoStatistics.total !== videoStatistics.done) { // error loading video
        clearVideoDownloadStatus(context);
    } else if (pdfStatistics.total !== pdfStatistics.done) { // error loading pdf
        clearPdfDownloadStatus(context);
    } else if (webrotate360Statistics.total !== webrotate360Statistics.done) { // error loading webrotate360
        clearWebrotate360DownloadStatus(context);
    }
};

const handleOfflineError = (context, err) => {
    registerError('An error occured while switching to offline mode', err);
    context.dispatch('stoppLoading');
    context.dispatch('setLoadingError', err);

    if (err instanceof InternetConnectionError) {
        console.log('INTERNET ERROR');
        checkWhereErrorAppeared(context);
    } else if (err instanceof DataDownloadError) {
        clearDataDownloadStatus(context);
    } else if (err instanceof ImageDownloadError) {
        clearImageDownloadStatus(context);
    } else if (err instanceof PdfDownloadError) {
        clearPdfDownloadStatus(context);
    } else if (err instanceof RenderingDownloadError) {
        clearRenderingDownloadStatus(context);
    } else if (err instanceof VideoDownloadError) {
        clearVideoDownloadStatus(context);
    } else if (err instanceof Webrotate360DownloadError) {
        clearWebrotate360DownloadStatus(context);
    } else {
        console.log('getting to default');
        checkWhereErrorAppeared(context);
    }
};

export default handleOfflineError;
