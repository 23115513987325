import {createNamespacedHelpers} from 'vuex';
import { mapObject } from 'vuex-json-relationship-resolver';
import { transformHelper, viewConditionHelper } from '../../helpers/dataHelper';
import DetailPage from '../../layouts/detailPage/_component.vue';
import DetailSection from '../../components/_sections/caseStudyDetailsSection/_component.vue';
import ApplicationSection from '../../components/_sections/applicationSection/_component.vue';
import IntroSection from '../../components/_sections/introSection/_component.vue';
import ProductSection from '../../components/_sections/productSection/_component.vue';
import RelatedContentSection from '../../components/_sections/relatedContentSection/_component.vue';
import SectionWrapper from '../../components/_organisms/sectionWrapper/_component.vue';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';
import { REDIRECT_TO_404_TIME_MS } from '../../../settings';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'CaseStudyDetail',

    components: {
        DetailPage,
        DetailSection,
        ApplicationSection,
        IntroSection,
        ProductSection,
        RelatedContentSection,
        SectionWrapper,
        ViewWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-caseStudyDetail',
            sections: [],
            timeout404Page: null,
        };
    },

    computed: {
        ...mapObject({
            caseStudy: {
                type: 'case-study',
                filter: {
                    slug: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.slug;
                    }
                }
            }
        }),
        ...baseMapper.mapState(['isPrinting']),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        products() {
            return this.getArrayFrom(this.caseStudy.productOrProductLineList).map(entry => {
                // product line
                if (entry.type === 'product-line') {
                    const productLine = this.getArrayFrom(this.caseStudy.productLineList).find(o => o.id === entry.id);
                    return productLine ? {
                        id: productLine.id,
                        title: productLine.title,
                        image: productLine.image,
                        link: { name: 'product-line', params: { slug: productLine.slug }},
                    } : null;
                } 

                // product
                const product = this.getArrayFrom(this.caseStudy.productList).find(o => o.id === entry.id);
                return product ? {
                    id: product.id,
                    title: product.title,
                    category: product.productCategoryList && product.productCategoryList[0] ? product.productCategoryList[0].title : null,
                    image: product.previewImage,
                    link: { name: 'product', params: { slug: product.slug }},
                } : null;
            });
        }
    },

    watch: {
        '$route.params.slug'() {
            this.$store.dispatch('rr/find', `case-study/${this.$route.params.slug}`);
        },
        caseStudy() {
            this.initSections();
        },
    },
	
    mounted() {
        if (this.caseStudy) {
            this.initSections();
        }
    },
	
    created() {
        this.$store.dispatch('rr/find', `case-study/${this.$route.params.slug}`);

        this.timeout404Page = setTimeout(() => {
            if (!this.caseStudy) {
                this.$router.push({ name: 'page404' });
            }
        }, REDIRECT_TO_404_TIME_MS);
    },
	
    methods: {
        ...transformHelper,
        ...viewConditionHelper,
        initSections() {
            this.$nextTick(() => {
                this.sections = Array.from(this.$refs.caseStudySections.children);
            });
        },
    },

    destroyed() {
        clearTimeout(this.timeout404Page);
    },
};
