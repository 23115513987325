const handleInternetConnection = (context) => {
    context.commit('SET_isInternetAvailable', window.navigator.onLine);

    window.addEventListener('online',  () => {
        context.commit('SET_isInternetAvailable', true);
    });
    window.addEventListener('offline', () => {
        context.commit('SET_isInternetAvailable', false);
    });
};

export default handleInternetConnection;