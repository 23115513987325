
export default {
    name: 'NumberCircle',

    components: {
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Number,
            default: 0,
            validator: (value) => {
                // The value must match one of these strings
                return value >= 0;
            }
        },
    },

    data() {
        return {
            cn: 'a-numberCircle',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    methods: {
    },
};
