import objectRelationshipAggregator from './object-aggregation/relationship';
import objectAttributesGetterSetter from './object-aggregation/attributes-getter-setter';
import storeObject from './store-object';

const handleObject = (object, context) => {
    if (object && object.included) {
        Object.values(object.included).forEach((relatedObject) => {
            handleObject(relatedObject, context);
        });
    }
    object = objectRelationshipAggregator({ object, context });
    object = objectAttributesGetterSetter({ object, context });

    object = storeObject({ object, context });
    return object;
};

export default handleObject;
