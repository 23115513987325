import { createNamespacedHelpers } from 'vuex';
import anime from 'animejs';
import ChevronButton from '../../_atoms/chevronButton/_component.vue';
import ImageComponent from '../../_atoms/image/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import LaskaLogo from '../../_svg/LaskaLogo.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'IntroSection',

    components: {
        ChevronButton,
        ImageComponent,
        SectionWrapper,
        LaskaLogo,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: null,
        },
        subline: {
            type: String,
            default: null,
        },
        image: {
            type: Object,
            default: null,
        },
        scrollFunction: {
            type: Function,
        },
    },

    data() {
        return {
            cn: 'o-introSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        isLoaded() {
            this.$nextTick(() => {
                if (!this.isPrinting) {
                    this.doAnimations();
                }
            });
        },
        doAnimations() {
            // Els Slide from bottom
            const {introSection} = this.$refs;
            const els = [...introSection.querySelectorAll('.o-introSection__headline, .o-introSection__introText')];

            if (els) {
                els.forEach((el) => {
                    el.style.opacity = 0;
                    el.style.transform = 'translateY(100px)';
                });

                anime({
                    targets: els,
                    easing: 'easeOutQuart',
                    begin: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animated');
                            aniEl.target.classList.add('js-animating');
                        });
                    },
                    complete: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animating');
                            aniEl.target.classList.add('js-animated');
                        });
                    },
                    delay: 0,
                    translateY: [
                        {value: 0, duration: 500}
                    ],
                    opacity: [
                        {value: 1, duration: 750}
                    ],
                });
            }

            // Image Fading
            const image = introSection.querySelector('.o-introSection__image');
            if (image) {
                image.style.opacity = 0;

                anime({
                    targets: image,
                    easing: 'linear',
                    begin: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animated');
                            aniEl.target.classList.add('js-animating');
                        });
                    },
                    complete: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animating');
                            aniEl.target.classList.add('js-animated');
                        });
                    },
                    delay: 1000,
                    duration: 1500,
                    opacity: 1
                });
            }
        },
    },
};
