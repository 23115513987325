import ProductOverviewPage from '../../views/productOverview/_component.vue';

const routes = [
    {
        path: '/product-overview/:slug',
        name: 'product-overview',
        component: ProductOverviewPage,
        meta: {
            pdfRenderer: {
                enabled: true,
                title: ''
            },
            isPrintable: true,
            requiresAuth: true,
            showTitleInHeader: true,
            documentAddable: true,
        },
    },
];

export default routes;
