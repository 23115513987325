<template>
  <div :class="rootClass"
       :style="`
          width: ${width};
          height: ${height};
       `">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
      <defs>
        <path id="windows-a" d="M8.0088,19.2 L8,11.3984 L17.6,10.0944 L17.6,19.2 L8.0088,19.2 Z M19.2,9.8624 L31.9968,8 L31.9968,19.2 L19.2,19.2 L19.2,9.8624 Z M32,20.8 L31.9968,32 L19.2,30.2 L19.2,20.8 L32,20.8 Z M17.6,29.996 L8.008,28.6808 L8.0072,20.8 L17.6,20.8 L17.6,29.996 Z"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="windows-b" fill="#fff">
          <use xlink:href="#windows-a"/>
        </mask>
        <use fill="#000" fill-rule="nonzero" xlink:href="#windows-a"/>
        <g fill="#161A1B" fill-rule="nonzero" mask="url(#windows-b)">
          <rect width="40" height="40"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'windows',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-windows',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-windows {
    svg {
      width: 100%;
      height: 100%;
    }
  
    mask, g, path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-windows--black {
    mask, g, path {
      fill: c('black');
    }
  }

  .svg-windows--red {
    mask, g, path {
      fill: c('red');
    }
  }

  .svg-windows--gray {
    mask, g, path {
      fill: c('white', '30%');
    }
  }

  .svg-windows--blue {
    mask, g, path {
      fill: c('blue');
    }
  }
</style>
    