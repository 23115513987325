var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewWrapper',{attrs:{"property":_vm.productLine}},[(_vm.productLine)?_c('DetailPage',{class:_vm.rootClass,attrs:{"sections":_vm.sections},scopedSlots:_vm._u([{key:"default",fn:function(detailPage){return [_c('div',{ref:"productLineTemplate"},[_c('IntroSection',{attrs:{"id":"overview","title":_vm.productLine.title,"subline":_vm.productLine.subline,"image":_vm.productLine.image,"scroll-function":function () { return detailPage.scrollToSection(1); }}}),_c('HighlightSliderSection',{attrs:{"id":"highlights","show-section":_vm.relationsNotEmpty(_vm.productLine, 'highlight'),"product-name":_vm.productLine.title,"is-product-line":true,"highlights":_vm.getArrayFrom(_vm.productLine.highlightList)}}),_c('ReasonsSection',{attrs:{"id":"reasons","show-section":_vm.relationsNotEmpty(_vm.productLine, 'reasons'),"product-name":_vm.productLine.title,"image":_vm.productLine.productLinesReasonsImage,"image-print":_vm.productLine.productLinesReasonsImagePrint,"reasons":_vm.getArrayFrom(_vm.productLine.reasonsList),"mapping-function":function (reason) { return ({
                          text: reason.copy,
                          iconType: 'number'
                        }); }}}),_c('AutomationSection',{attrs:{"id":"automation","show-section":_vm.relationsNotEmpty(_vm.productLine, 'automation'),"product-name":_vm.productLine.title,"automations":_vm.getArrayFrom(_vm.productLine.automationList),"mapping-function":function (automation) { return ({
                             id: automation.id,
                             title: automation.title,
                             category: automation.subline,
                             image: automation.previewImage,
                             content: automation.content,
                             link: { name: 'product-line-automation', params: { slug: automation.slug }}
                           }); }}}),_c('AdvantageSection',{attrs:{"id":"advantagesLines","show-section":_vm.relationsNotEmpty(_vm.productLine, 'product-line-advantage'),"product-name":_vm.productLine.title,"advantages":_vm.getArrayFrom(_vm.productLine.productLineAdvantageList),"advantage-categories":_vm.advantageCategories,"mapping-function":function (advantage) { return ({
                            id: advantage.id,
                            title: advantage.title,
                            category: advantage.category,
                            image: advantage.mainImage,
                            accordionItem: advantage.accordionItem,
                          }); },"mapping-function-overlay":function (advantage) { return (Object.assign({}, _vm.getVideoOrImageFrom(advantage),
                            {captionOverwrite: {
                              title: advantage.accordionItem && advantage.accordionItem[0] ?
                                advantage.accordionItem[0].accordionHeadline : null,
                              content: advantage.accordionItem && advantage.accordionItem[0] ?
                                advantage.accordionItem[0].accordionCopy : null,
                            }})); }}}),_c('ApplicationSection',{attrs:{"id":"usecases","show-section":_vm.relationsNotEmpty(_vm.productLine, 'application'),"product-name":_vm.productLine.title,"application-introduction":_vm.productLine.content ? _vm.productLine.content.applicationsIntroduction : null,"applications":_vm.getArrayFrom(_vm.productLine.applicationList),"mapping-function":function (application) { return ({
                              id: application.id,
                              title: application.title,
                              category: application.categoryList && application.categoryList[0] ? application.categoryList[0].title : '',
                              image: application.productImage,
                              link: { name: 'product-application', params: { slug: application.slug }},
                            }); }}}),_c('OutputSection',{attrs:{"id":"output","product-name":_vm.productLine.title,"show-section":_vm.relationsNotEmpty(_vm.productLine, 'output'),"output":_vm.getArrayFrom(_vm.productLine.outputList)}}),_c('ReferenceSection',{attrs:{"id":"references","show-section":_vm.relationsNotEmpty(_vm.productLine, 'productLineReference'),"product-name":_vm.productLine.title,"references":_vm.getArrayFrom(_vm.productLine.productLineReferenceList),"mapping-function":function (reference) { return ({
                            id: reference.id,
                            title: reference.title,
                            category: reference.category,
                            image: reference.productLineReferencePreviewImage,
                            slideContent: reference.slideContent,
                          }); },"mapping-function-overlay":function (reference) { return (Object.assign({}, reference.productLineReferenceSlideImage,
                            {captionOverwrite: {
                              title: reference.slideContent && reference.slideContent.headline ?
                                reference.slideContent.headline : null,
                              content: reference.slideContent && reference.slideContent.copy ?
                                reference.slideContent && reference.slideContent.copy : null,
                            }})); }}}),_c('TechnicalDocumentsSection',{attrs:{"id":"technicalDocuments","product-name":_vm.productLine.title,"show-section":_vm.relationsNotEmpty(_vm.productLine, 'downloads'),"downloads":_vm.downloadList}}),_c('FaqSection',{attrs:{"id":"faq","show-section":_vm.relationsNotEmpty(_vm.productLine, 'faq'),"faqs":_vm.getArrayFrom(_vm.productLine.faqList)}}),_c('RelatedContentSection',{attrs:{"id":"relatedContent","show-section":_vm.relationsNotEmpty(_vm.productLine, 'related-content'),"contents":_vm.getArrayFrom(_vm.productLine.relatedContentList),"mapping-function":function (content) { return ({
                                 id: content.id,
                                 title: content.title,
                                 category: content.category,
                                 image: content.productImage,
                                 link: _vm.getRelatedContentLinkFrom(content),
                               }); }}})],1)]}}],null,false,776480082)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }