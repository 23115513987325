import anime from 'animejs';

const animations = {
    animateElements(el, config) {
        let animeEls = [el];
        if (config.childs) {
            animeEls = el.querySelectorAll(config.childs);
        }

        if (config.useCss) {
            animeEls.forEach((aniEl, index) => {
                let transformSettings = 'translate3d(0, 200px, 0)';

                if (config.animationName) {
                    if (config.animationName === 'slideFadeFromBottom') {
                        transformSettings = 'translate3d(0, 100px, 0)';
                    }

                    if (config.animationName === 'slideFadeFromRight') {
                        transformSettings = 'translate3d(100px, 0, 0)';
                    }
                }

                aniEl.style.opacity = 0;
                aniEl.style.transform = transformSettings;
                aniEl.style.transition = 'all 0ms';

                setTimeout(() => {
                    aniEl.style.opacity = 1;
                    aniEl.style.transform = 'translate3d(0, 0, 0)';
                    aniEl.style.transition = `all ${config.duration || 750}ms`;
                }, ((config.delay || 250) + 250) + (index * (config.staggerDelay || 100)));
            });
        } else {
            const defaultSettings = {
                targets: animeEls,
                duration: config.duration || 750,
                delay: config.staggered ?
                    anime.stagger(config.staggerDelay || 100, {start: (config.delay || 250) + 250}) :
                    (config.delay || 0) + 250,
                easing: config.easing || 'easeOutQuart',
                begin: (response) => {
                    response.animatables.forEach((aniEl) => {
                        aniEl.target.classList.remove('js-animated');
                        aniEl.target.classList.add('js-animating');
                    });
                },
                complete: (response) => {
                    response.animatables.forEach((aniEl) => {
                        aniEl.target.classList.remove('js-animating');
                        aniEl.target.classList.add('js-animated');
                        aniEl.target.style.removeProperty('transform');
                    });
                }
            };

            let animationSettings = {
                opacity: [0, 1],
                translateY: ['200px', '0px'],
            };

            if (config.animationName) {
                if (config.animationName === 'slideFadeFromBottom') {
                    animationSettings = {
                        opacity: [0, 1],
                        translateY: ['100px', '0px'],
                    };
                }

                if (config.animationName === 'slideFadeFromRight') {
                    animationSettings = {
                        opacity: [0, 1],
                        translateX: ['100px', '0px'],
                    };
                }
            }

            anime({
                ...animationSettings,
                ...defaultSettings
            });
        }
    },

    init(el, selector) {
        if (el) {
            const els = el.querySelectorAll(selector || '.js-animate');
            if (els.length > 0) {
                els.forEach((item) => {
                    const config = item.dataset.animateConfig ? JSON.parse(item.dataset.animateConfig) : {};
                    this.animateElements(item, config);
                });
            }
        }
    }
};

export default animations;
