import Task from './Task';
import { TYPE_PDF } from '../../../../settings';
import storeAsset from '../../store-asset';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import PdfDownloadError from '../../../helpers/errors/download/PdfDownloadError';
import registerError from '../../../helpers/registerError';

class PdfTask extends Task {
    constructor(downloadManager, dataExecutor, pdf) {
        super();
        this.downloadManager = downloadManager;
        this.dataExecutor = dataExecutor;
        this.pdf = pdf;
    }

    async run() {
        if (!this.pdf) {
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingPdfsDone');
            return;
        }
        
        try {
            await this.savePdfToDisk();
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingPdfsDone');
        } catch (err) {
            this.handleError(err);
        }
    }

    handleError(err) {
        registerError(`PdfTask could not load pdf ${this.pdf.id}`, err);

        if (err instanceof InternetConnectionError) {
            throw err;
        } else {
            throw new PdfDownloadError(`PdfTask could not load pdf ${this.pdf.id}`);
        }
    }

    async savePdfToDisk() {
        if (!this.pdf.storageUrl) {
            throw new PdfDownloadError('Storage url does not exist for pdf');
        }

        const storageLink = await storeAsset(this.pdf.storageUrl, 'pdf', TYPE_PDF);
        this.pdf.offlineLink = storageLink;
    }
}

export default PdfTask;
