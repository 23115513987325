const initRenderProcessEvents = (store) => {
    const { isDesktopApp } = store.state.base;

    if (isDesktopApp) {
        // eslint-disable-next-line
        const { ipcRenderer } = require('electron');

        // eslint-disable-next-line
        ipcRenderer.on('enableOfflineMode', async () => {
            const { isOffline } = store.state.desktopApp;

            if (!isOffline) {
                store.commit('base/setPreventStatusMessages', true);
                await store.dispatch('desktopApp/switchToOffline');
                store.commit('base/setPreventStatusMessages', false);
            }
        });

        // eslint-disable-next-line
        ipcRenderer.on('disableOfflineMode', () => {
            const { isOffline, error } = store.state.desktopApp;

            if (isOffline || error) {
                store.dispatch('desktopApp/switchToOnline');
            }
        });

        ipcRenderer.on('historyGoHome', () => {
            window.app.$router.push({'name':'home'}).catch(() => {});
        });

        ipcRenderer.on('checkKeepLoggedin', () => {
            store.dispatch('desktopApp/checkKeepLoggedin');
        });
    }
};

export default initRenderProcessEvents;
