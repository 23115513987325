import Queue from "../../helpers/queue";

const WAIT_TIMEOUT_MS = 40;

class TaskRunner {
    constructor(queue, runConditionFunction) {
        if (queue instanceof Queue && typeof runConditionFunction === 'function') {
            this.taskQueue = queue;
            this.runConditionFunction = runConditionFunction;
            this.running = false;
        } else {
            throw new TypeError('Parameter queue of type Queue and runConditionFunction of type Function must be defined');
        }
    }

    async run() {
        this.running = true;

        const timeout = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        };
        
        while (this.runConditionFunction() && this.running) {
            const task = this.taskQueue.dequeue();
            if (task) {
                await task.run(); // eslint-disable-line
            } else {
                await timeout(WAIT_TIMEOUT_MS); // eslint-disable-line
            }
        }
    }

    stop() {
        this.running = false;
    }
}

export default TaskRunner;