import { mapObject } from 'vuex-json-relationship-resolver';
import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper, transformHelper } from '../../../helpers/dataHelper';
import MediaLibraryLayout from '../../../layouts/mediaLibraryPage/_component.vue';
import IntroSection from '../../../components/_sections/introSection/_component.vue';
import MedialibrarySection from '../../../components/_sections/medialibrarySection/_component.vue';
import RelatedContentSection from '../../../components/_sections/relatedContentSection/_component.vue';
import ViewWrapper from '../../../components/_organisms/viewWrapper/_component.vue';
import { Actions as MediaLibraryActions } from '../../../vuex/modules/medialibrary/module';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'medialibrary',

    components: {
        MediaLibraryLayout,
        IntroSection,
        MedialibrarySection,
        RelatedContentSection,
        ViewWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        medialibraryPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            cn: 'p-medialibraryOverview',
            sections: [],
            product: false,
        };
    },

    created() {
        if (!this.isOffline) {
            this.$store.dispatch(`medialibrary/${MediaLibraryActions.FETCH_MEDIA_ITEMS}`);
            this.$store.dispatch(`medialibrary/${MediaLibraryActions.FETCH_MEDIA_FILTERS}`);
        }
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({
            medialibrary: { type: 'mediathek', isArray: true },
        }),
        ...baseMapper.mapGetters(['isOffline']),
    },

    watch: {
        medialibrary : {
            handler() {
                this.$nextTick(() => {
                    if (this.$refs && this.$refs.medialibraryTemplate) {
                        this.sections = Array.from(this.$refs.medialibraryTemplate.children);
                    }
                });
            }
        }
    },

    methods: {
        ...transformHelper,
        ...viewConditionHelper,
    },
};
