import Task from './Task';
import { IMAGE_SIZE, TYPE_IMAGE } from '../../../../settings';
import storeAsset from '../../store-asset';
import ImageDownloadError from '../../../helpers/errors/download/ImageDownloadError';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import registerError from '../../../helpers/registerError';
import NotFoundError from '../../../helpers/errors/NotFoundError';
import AddressInfoError from '../../../helpers/errors/AddressInfoError';

class ImageTask extends Task {
    constructor(downloadManager, dataExecutor, image) {
        super();
        this.downloadManager = downloadManager;
        this.dataExecutor = dataExecutor;
        this.image = image;
        this.retries = 0;
    }

    async run() {
        if (!this.image ||
            this.image.type !== 'asset-image' ||
            this.downloadManager.history.includes(this.image.original)) {
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingImagesDone');
            return;
        }

        this.downloadManager.history.push(this.image.original);

        try {
            await this.loadImageInAllOrientations();
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingImagesDone');
        } catch (err) {
            this.handleError(err);
        }
    }

    handleError(err) {
        if (err instanceof InternetConnectionError) {
            registerError('Internet Connection Error', err);
            throw err;
        } else if (err instanceof NotFoundError) {
            // do not throw error, continue downloading, but register error
            registerError(`Image ${this.image.id} not found`, err);
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingImagesDone');
        } else if (err instanceof AddressInfoError) {
            if (this.retries < 3) {
                this.retries += 1;
                this.run();
            } else {
                registerError(`ImageTask could not load image ${this.image.id}`, err);
                throw new ImageDownloadError(`ImageTask could not load image ${this.image.id}`);
            }
        } else {
            registerError(`ImageTask could not load image ${this.image.id}`, err);
            throw new ImageDownloadError(`ImageTask could not load image ${this.image.id}`);
        }
    }

    async loadImageInAllOrientations() {
        if (!this.image.versions && !this.image.original) {
            throw new ImageDownloadError(`Neither versions nor original image path exist for image ${this.image.id}`);
        }

        if (this.image.versions && Object.keys(this.image.versions).length > 0) {
            const imagePromises = [];
            Object.values(this.image.versions).forEach((imageInOrientation) => {
                if (!imageInOrientation.default) {
                    throw new ImageDownloadError('Default image format does not exist for orientation');
                }

                imagePromises.push(this.saveImageToDisk(imageInOrientation));
            });

            await Promise.all(imagePromises);
        } else if (this.image.original) {
            const url = this.image.original;
            const extension = this.image.extension.toLowerCase();
            const storageLink = await storeAsset(url, extension, TYPE_IMAGE);
            this.image.offlineLink = storageLink;
        }
    }

    async saveImageToDisk(imageInOrientation) {
        const size = this.getImageSize(imageInOrientation);
        const url = imageInOrientation.default[size];
        const extension = this.image.extension.toLowerCase();
        const storageLink = await storeAsset(url, extension, TYPE_IMAGE);
        imageInOrientation.offline = storageLink;
    }

    // eslint-disable-next-line
    getImageSize(imageInOrientation) {
        if (imageInOrientation.default[IMAGE_SIZE]) {
            return IMAGE_SIZE;
        }

        // img of size IMAGE_SIZE is not available
        // use biggest available image
        const sizes = Object.keys(imageInOrientation.default);
        return Math.max(...sizes);
    }
}

export default ImageTask;
