var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.rootClass},[((_vm.slides && _vm.slides.length > 0) || _vm.view360)?_c('div',[(_vm.isInline)?_c('div',{class:['o-imageVideoGallery__fullImgWrapper', {
           'o-imageVideoGallery__fullImgWrapper--video': _vm.videoSelected,
           'o-imageVideoGallery__fullImgWrapper--360': _vm.view360Selected,
         }, 'js-animate'],attrs:{"data-animate-config":"{ \"staggered\": true, \"staggerDelay\": 50, \"childs\": \".o-imageVideoGallery__fullImg, .a-imageModule__caption\"}"}},[_vm._l((_vm.slides),function(slide,index){return [(slide.type === 'asset-image')?_c('ImageWrapper',{key:index,ref:"sliderImage",refInFor:true,class:{'o-imageVideoGallery__fullImg': true,
                               'o-imageVideoGallery__fullImg--hide': parseInt(index) !== _vm.itemToShow},attrs:{"caption":!!slide.captionOverwrite,"caption-content":("<h2>" + (slide.captionOverwrite ? slide.captionOverwrite.title : '') + "</h2>" + (slide.captionOverwrite ? slide.captionOverwrite.content : '')),"image":slide,"crop-type":'landscape'}}):(slide.type === 'asset-video')?_c('VideoPlayer',{key:index,ref:"sliderImage",refInFor:true,class:{'o-imageVideoGallery__fullImg': true,
                              'o-imageVideoGallery__fullImg--hide': parseInt(index) !== _vm.itemToShow},attrs:{"caption":!!slide.captionOverwrite,"caption-content":("<h2>" + (slide.captionOverwrite ? slide.captionOverwrite.title : '') + "</h2>" + (slide.captionOverwrite ? slide.captionOverwrite.content : '')),"video":slide,"crop-type":"landscape"}}):_vm._e()]}),(_vm.view360)?_c('Viewer360',{class:{'o-imageVideoGallery__fullImg': true,
                          'o-imageVideoGallery__fullImg--360': true,
                          'o-imageVideoGallery__fullImg--hide': _vm.itemToShow !== -1},attrs:{"images":_vm.view360 ? _vm.view360.images : null,"jump":_vm.view360 && _vm.view360.meta ? _vm.view360.meta.jump : null}}):_vm._e()],2):_vm._e(),(!_vm.isPrinting && (!_vm.isInline || (_vm.isInline && _vm.slides.length > 1)))?_c('div',{staticClass:"o-imageVideoGallery__thumbnails js-animate",attrs:{"data-animate-config":"{\n         \"staggered\": true,\n         \"staggerDelay\": 100,\n         \"animationName\": \"slideFadeFromRight\",\n         \"childs\": \".o-imageVideoGallery__thumbnailWrapper\",\n         \"delay\": 500\n         }"}},[(_vm.view360 && _vm.view360.thumbnail)?_c('div',{class:{
             'o-imageVideoGallery__thumbnailWrapper': true,
             'selected': _vm.itemToShow === -1,
           }},[(!_vm.view360.thumbnail._empty)?_c('div',{ref:"slideThumbnail",class:{
               'o-imageVideoGallery__thumbnail': true,
               'o-imageVideoGallery__thumbnail--360': true,
               'o-imageVideoGallery__inlineThumbnail': _vm.isInline,
             },style:(("background-image: url(" + (_vm.thumbnailSrcRendering()) + ")")),on:{"click":function($event){return _vm.showSlide(-1)}}}):_vm._e(),(_vm.view360)?_c('IconView360',{attrs:{"width":"40px"},nativeOn:{"click":function($event){return _vm.showSlide(-1)}}}):_vm._e()],1):_vm._e(),_vm._l((_vm.slides),function(slide,index){return [(slide.type === 'asset-image')?_c('div',{key:index,class:{
               'o-imageVideoGallery__thumbnailWrapper': true,
               'selected': parseInt(index) === _vm.itemToShow,
             }},[(!slide._empty)?_c('div',{ref:"slideThumbnail",refInFor:true,class:{
                 'o-imageVideoGallery__thumbnail': true,
                 'o-imageVideoGallery__inlineThumbnail': _vm.isInline,
               },style:(("background-image: url(" + (_vm.thumbnailSrcImg(slide)) + ")")),on:{"click":function($event){return _vm.showSlide(index)}}}):_vm._e()]):(slide.type === 'asset-video')?_c('div',{key:index,class:{
               'o-imageVideoGallery__thumbnailWrapper': true,
               'selected': parseInt(index) === _vm.itemToShow,
             }},[(!slide._empty && slide.versions && slide.versions.length > 0)?_c('div',{ref:"slideThumbnail",refInFor:true,class:{
                 'o-imageVideoGallery__thumbnail': true,
                 'o-imageVideoGallery__thumbnail--video': true,
                 'o-imageVideoGallery__inlineThumbnail': _vm.isInline,
               },style:(("background-image: url(" + (_vm.thumbnailSrcVideo(slide)) + ")")),on:{"click":function($event){return _vm.showSlide(index)}}}):_vm._e(),(!slide._empty && slide.versions && slide.versions.length > 0)?_c('IconPlayStart',{attrs:{"color":"red","width":"20px"},nativeOn:{"click":function($event){return _vm.showSlide(index)}}}):_vm._e()],1):_vm._e()]})],2):_vm._e()]):_vm._e(),(!_vm.isInline && _vm.showSliderOverlay && !_vm.isPrinting)?_c('div',{ref:"sliderOverlay",staticClass:"o-imageVideoGallery__overlay",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeOverlay.apply(null, arguments)}}},[_c('div',{staticClass:"o-imageVideoGallery__closeBtnWrapper"},[_c('IconClose',{staticClass:"o-imageVideoGallery__closeBtn",nativeOn:{"click":function($event){return _vm.closeOverlay.apply(null, arguments)}}})],1),(_vm.showSliderOverlay)?_c('slider-overlay',{ref:"sliderOverlay",attrs:{"slides":_vm.slides,"selected-item":_vm.itemToShow}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }