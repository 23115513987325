export default {
    name: 'PrintItem',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        headline: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            cn: 'p-printItem',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},

    mounted() {},
	
    created() {},
	
    methods: {},
};
