import { createNamespacedHelpers } from 'vuex';
import Notification from '../../_molecules/notification/_component.vue';
import { NOTIFICATION_POLL_TIME_MS } from '../../../../settings';

const notificationMapper = createNamespacedHelpers('notifications');

export default {
    name: 'NotificationOverlay',

    components: {
        Notification,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-notificationOverlay',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
        ...notificationMapper.mapState([
            'currentNotifications',
        ]),
    },

    watch: {
        currentNotifications() {
            this.$nextTick(() => {
                this.calculateHeight();
            });
        },
    },

    created() {
        window.addEventListener('resize', this.calculateHeight);
    },

    mounted() {
        setInterval(() => {
            if (!this.$store.state.desktopApp || // not using desktop app
                // or using desktop app in online mode
                (this.$store.state.desktopApp && !this.$store.state.desktopApp.isOffline)) {
                this.$store.dispatch('notifications/checkForNewNotifications');
            }
        }, NOTIFICATION_POLL_TIME_MS);
    },

    destroyed() {
        window.removeEventListener('resize', this.calculateHeight);
    },

    methods: {
        calculateHeight() {
            if (this.$refs.notifications) {
                let containerHeight = 0;

                [...this.$refs.notifications.children].forEach((notification) => {
                    containerHeight += notification.clientHeight;
                });

                this.$refs.notifications.style.height = `${containerHeight}px`;
            }
        }
    },
};
