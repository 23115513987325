import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../../i18n';

const electron = require('electron');

const notificationAlreadySent = (timeString) => {
    const lastSentNotificationSinceStartup = electron.remote.getGlobal('offlineMode').logoutWarning;
    return lastSentNotificationSinceStartup === timeString;
};

const handleOfflineLogoutNotifications = (context, allowedOfflineTimeMs, currentOfflineTimeMs) => {
    const restTimeMs = allowedOfflineTimeMs - currentOfflineTimeMs;
    const restTimeHours = restTimeMs / 1000 / 60 / 60;
    const restTimeDays = Math.floor(restTimeHours / 24);

    if (restTimeDays > 0 && restTimeDays <= 10 && !notificationAlreadySent(`${restTimeDays}d`)) {
        electron.remote.getGlobal('offlineMode').logoutWarning = `${restTimeDays}d`;
        context.dispatch('notifications/addNotification', {
            id: uuidv4(),
            icon: 'check',
            text: `${i18n.t('notifications.offlineModeForcedLogoutWarningDaysMessage1')} ${30 - restTimeDays} ${i18n.t('notifications.offlineModeForcedLogoutWarningDaysMessage2')}`,
            deleteOnClose: true,
            action: 'disableOfflineMode',
            actionText: i18n.t('notifications.offlineModeForcedLogoutWarningAction'),
        }, { root: true });
    } else if (restTimeDays <= 0 && restTimeHours > 0 && !notificationAlreadySent('hours')) {
        electron.remote.getGlobal('offlineMode').logoutWarning = 'hours';
        context.dispatch('notifications/addNotification', {
            id: uuidv4(),
            icon: 'check',
            text: i18n.t('notifications.offlineModeForcedLogoutWarningHoursMessage'),
            deleteOnClose: true,
            action: 'disableOfflineMode',
            actionText: i18n.t('notifications.offlineModeForcedLogoutWarningAction'),
        }, { root: true });
    }
};

export default handleOfflineLogoutNotifications;
