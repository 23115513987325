
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m16.5 16 1.427-1.5 5.573 6-5.573 6-1.427-1.5 4.2-4.5z" fill="#161a1b" transform="matrix(-1 0 0 -1 40 41)"/></svg>
    </div>
</template>

<script>
export default {
    name: 'left',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-left',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-left {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-left--black {
    path {
      fill: c('black');
    }
  }

  .svg-left--red {
    path {
      fill: c('red');
    }
  }

  .svg-left--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-left--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    