
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M14,29 L13,14 L12,14 L12,12 L17,12 L17,10.1 C17,9.4384 17.3384,9 18,9 L22,9 C22.66,9 23,9.4368 23,10.1 L23,12 L28,12 L28,14 L27,14 L26,29 L14,29 Z M25,14 L15,14 L16,27 L24,27 L25,14 Z M19,12 L21,12 L21,11 L19,11 L19,12 Z"></path>
</svg>
    </div>
</template>

<script>
export default {
    name: 'trash-empty',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-trash-empty',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-trash-empty {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-trash-empty--black {
    path {
      fill: c('black');
    }
  }

  .svg-trash-empty--red {
    path {
      fill: c('red');
    }
  }

  .svg-trash-empty--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-trash-empty--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    