<template>
  <div class="o-documentListing__documentList" :class="{'o-documentListing__documentList--open': showSub}">
    <div :class="[
           'o-documentListing__entry',
           `o-documentListing__entry--level-${level}`,
           { 'o-documentListing__entry--open': showSub}]"
         @click="toggleSubmenu">
      <div class="o-documentListing__entryTitle">
        {{ document.name }}
      </div>
      <ChevronIconRight v-if="document.subList && !showSub" :color="'white'" />
      <ChevronIconDown v-if="document.subList && showSub" :color="'white'" />
    </div>
    <div v-for="doc in document.subList"
         :key="doc.id"
         :class="['o-documentListing__subList', { 'o-documentListing__subList--open': showSub }]">
      <DocumentList v-if="doc.subList && Object.values(doc.subList).length > 0"
                    :document="doc"
                    :level="level + 1" />
      <Document v-else-if="!doc.itemHidden"
                :document="doc"
                :level="level + 1" />
    </div>
  </div>
</template>

<script>
import ChevronIconDown from '../../_svg/icon/chevron/down.vue';
import ChevronIconRight from '../../_svg/icon/chevron/right.vue';
import Document from './__document.vue';

export default {
    name: 'DocumentList',

    components: {
        Document,
        ChevronIconDown,
        ChevronIconRight,
    },

    props: {
        level: {
            type: Number,
            required: true,
        },
        document: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            showSub: false,
        };
    },

    methods: {
        toggleSubmenu() {
            this.showSub = !this.showSub;
        }
    },
};
</script>