<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <g fill="none" fill-rule="evenodd">
        <path d="M0,0 L40,0 L40,40 L0,40 L0,0 Z M0,0 L40,0 L40,40 L0,40 L0,0 Z" />
        <path fill="#E11624" fill-rule="nonzero" d="M26.9753849,13.6333333 L17.5128206,23.0958976 L12.3579488,17.9553847 L10.3333333,19.9800001 L17.5128206,27.1594874 L29.0000003,15.6723077 L26.9753849,13.6333333 Z M20,3.33333333 C10.8,3.33333333 3.33333333,10.8 3.33333333,20 C3.33333333,29.2 10.8,36.6666667 20,36.6666667 C29.2,36.6666667 36.6666667,29.2 36.6666667,20 C36.6666667,10.8 29.2,3.33333333 20,3.33333333 Z M20,33.3333333 C12.6333333,33.3333333 6.66666667,27.3666667 6.66666667,20 C6.66666667,12.6333333 12.6333333,6.66666667 20,6.66666667 C27.3666667,6.66666667 33.3333333,12.6333333 33.3333333,20 C33.3333333,27.3666667 27.3666667,33.3333333 20,33.3333333 Z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'Check',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationCheck',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationCheck {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationCheck--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationCheck--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationCheck--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationCheck--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
