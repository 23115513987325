const handlePrinting = (context) => {
    window.addEventListener('beforeprint', () => {
        context.commit('SET_isPrinting', true);
    });
    window.addEventListener('afterprint', () => {
        context.commit('SET_isPrinting', false);
    });

    context.dispatch('loadPrintingMetaData');
};

export default handlePrinting;