import AddIcon from '../../../components/_atoms/addIcon/_component.vue';
import IconChevronRight from '../../../components/_svg/icon/chevron/right.vue';
import LoadingSpinner from '../../../components/_atoms/loadingSpinner/_component.vue';

export default {
    name: 'DocumentProcessing',

    components: {
        AddIcon,
        IconChevronRight,
        LoadingSpinner,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-documentProcessing',
            done: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {
        window.setTimeout(() => {
            this.$router.push({ name: 'home' }).catch(() => {});
        }, 4000);
    },

    created() {},

    methods: {
        isDone() {
            return false;
        }
    },
};
