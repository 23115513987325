import { mapState } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import ImageVideoGallery from '../../_organisms/imageVideoGallery/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';

export default {
    name: 'CaseStudyDetailsSection',

    components: {
        ImageVideoGallery,
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        category: {
            type: String,
            default: null,
        },
        details: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-caseStudyDetailsSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapState(['isPrinting']),
        headline() {
            const headlineBlock = this.getDetailWithType('headline');
            return headlineBlock ? headlineBlock.data : null;
        },
        subline() {
            const sublineBlock = this.getDetailWithType('subline');
            return sublineBlock ? sublineBlock.data : null;
        },
        quote() {
            const quoteBlock = this.getDetailWithType('quote');
            return quoteBlock;
        },
        table() {
            const tableBlock = this.getDetailWithType('details');
            return tableBlock ? tableBlock.attributes : null;
        },
        tableKeys() {
            let tableBlockKeys = this.getDetailWithType('details');
            if (tableBlockKeys) {
                tableBlockKeys = Object.keys(tableBlockKeys.attributes).filter(key => key !== 'contentType');
            }
            return tableBlockKeys;
        },
        images() {
            const imagesBlock = this.getDetailWithType('image');
            return imagesBlock ? imagesBlock.imageList : null;
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...viewConditionHelper,

        getDetailWithType(type) {
            return this.details.filter(detail => detail.contentType === type)[0];
        },
    },
};
