import LaskaLogo from '../../../components/_svg/LaskaLogo.vue';

export default {
    computed: {
        user() {
            return this.$store.state.rr.objects.filter(o => o.type === 'user')[0];
        }
    },
    components: {
        LaskaLogo
    }
};
