import sendDocument from './sendDocument';

const sendQueuedDocument = (context, document) => {
    const customer = {
        email: document.receiver.email,
        firstname: document.receiver.firstName,
        lastname: document.receiver.lastName,
        company: document.receiver.company,
        gender: document.receiver.gender,
    };

    const { message } = document.receiver;

    const documentArray = document.addedDocuments;

    return sendDocument(context, customer, message, documentArray);
};

export default sendQueuedDocument;
