var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.rootClass},[_c('div',{staticClass:"p-documentForm__wrapper"},[_c('div',{staticClass:"p-documentForm__content"},[_c('div',{staticClass:"p-documentForm__intro"},[_c('RouterLink',{staticClass:"p-documentForm__backLink",attrs:{"to":{ name: 'document-overview' }}},[_c('IconChevronLeft',{attrs:{"color":"black"}}),_vm._v(" "+_vm._s(_vm.$t("sendDocument.stepBack"))+" ")],1),_c('h1',{staticClass:"p-documentForm__headline"},[_vm._v(_vm._s(_vm.$t('sendDocument.formHeadline')))])],1),_c('div',{staticClass:"p-documentForm__form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"p-documentForm__formGroupLabel"},[_vm._v(" "+_vm._s(_vm.$t('sendDocument.formRecipient'))+" ")]),_c('div',{staticClass:"p-documentForm__formRow"},[_c('div',{staticClass:"p-documentForm__formColFull"},[_c('label',{staticClass:"form__radio"},[_vm._v(" "+_vm._s(_vm.$t('form.male'))+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"form__radio",attrs:{"type":"radio","value":"male"},domProps:{"checked":_vm._q(_vm.gender,"male")},on:{"change":function($event){_vm.gender="male"}}}),_c('span',{staticClass:"formRadio__checkmark"})]),_c('label',{staticClass:"form__radio"},[_vm._v(" "+_vm._s(_vm.$t('form.female'))+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"form__radio",attrs:{"type":"radio","value":"female"},domProps:{"checked":_vm._q(_vm.gender,"female")},on:{"change":function($event){_vm.gender="female"}}}),_c('span',{staticClass:"formRadio__checkmark"})])])]),_c('div',{staticClass:"p-documentForm__formRow"},[_c('div',{staticClass:"p-documentForm__formCol"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],class:{
                       'form__input': true,
                       'error': _vm.errorMessage && _vm.errorMessage['meta.customer.firstname'],
                     },attrs:{"type":"text","placeholder":_vm.$t('form.firstName')},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage['meta.customer.firstname'])?_c('span',{staticClass:"errorMessage"},[_vm._v(" "+_vm._s(_vm.errorMessage['meta.customer.firstname'])+" ")]):_vm._e()]),_c('div',{staticClass:"p-documentForm__formCol"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],class:{
                       'form__input': true,
                       'error': _vm.errorMessage && _vm.errorMessage['meta.customer.lastname'],
                     },attrs:{"type":"text","placeholder":_vm.$t('form.lastName')},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage['meta.customer.lastname'])?_c('span',{staticClass:"errorMessage"},[_vm._v(" "+_vm._s(_vm.errorMessage['meta.customer.lastname'])+" ")]):_vm._e()])]),_c('div',{staticClass:"p-documentForm__formRow"},[_c('div',{staticClass:"p-documentForm__formCol-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],class:{
                       'form__input': true,
                       'error': _vm.errorMessage && _vm.errorMessage['meta.customer.company'],
                     },attrs:{"type":"text","placeholder":_vm.$t('form.company')},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value}}}),(_vm.errorMessage && _vm.errorMessage['meta.customer.company'])?_c('span',{staticClass:"errorMessage"},[_vm._v(" "+_vm._s(_vm.errorMessage['meta.customer.company'])+" ")]):_vm._e()])]),_c('div',{staticClass:"p-documentForm__formGroupLabel"},[_vm._v(" "+_vm._s(_vm.$t('sendDocument.formMessage'))+" ")]),_c('div',{staticClass:"p-documentForm__formRow"},[_c('div',{staticClass:"p-documentForm__formColFull"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],class:{
                          'form__textarea': true,
                          'error': _vm.errorMessage && _vm.errorMessage.message
                        },attrs:{"placeholder":_vm.$t('form.message')},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})])]),_c('div',{staticClass:"p-documentForm__formRow"},[_c('div',{staticClass:"p-documentForm__formCol"},[_c('button',{staticClass:"form__submitBtn"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("form.submit"))+" ")]),_c('IconChevronRight',{attrs:{"color":'red'}})],1)])])])]),_c('div',{staticClass:"p-documentForm__summary"},[_c('div',{staticClass:"p-documentForm__summaryHeadline"},[_vm._v(" "+_vm._s(_vm.$t("sendDocument.summaryHeadline"))+" ")]),_c('ul',{staticClass:"p-documentForm__summaryList"},_vm._l((_vm.documents),function(document){return _c('li',{key:document.id,domProps:{"innerHTML":_vm._s(document.tableOfContentsTitle)}})}),0),(_vm.errorMessage && _vm.errorMessage['pages'])?_c('span',{staticClass:"errorMessage"},[_vm._v(" "+_vm._s(_vm.errorMessage['pages'])+" ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }