
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m27.1461794 23.1601569-1.8067137-1.8067137-1.2030352 1.2030352 3.0097489 3.0097489zm0-2.6216982v-7.5384587h-14.2923588v7.7223735l4.7242525-4.7242525 5.2475083 5.2475083 2.5138843-2.5138843zm-1.1879255 6.4615413-8.3801808-8.3801808-4.7242525 4.7242525v3.6559283zm-14.9582539-16h18v18h-18zm11.6495017 7.7740864c-1.3807119 0-2.5-1.1192881-2.5-2.5s1.1192881-2.5 2.5-2.5c1.3807118 0 2.5 1.1192881 2.5 2.5s-1.1192882 2.5-2.5 2.5zm0-1.6489362c.4700295 0 .8510638-.3810342.8510638-.8510638s-.3810343-.8510639-.8510638-.8510639c-.4700296 0-.8510639.3810343-.8510639.8510639s.3810343.8510638.8510639.8510638z" fill="#161a1b"/></svg>
    </div>
</template>

<script>
export default {
    name: 'imageIcon',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-imageIcon',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-imageIcon {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-imageIcon--black {
    path {
      fill: c('black');
    }
  }

  .svg-imageIcon--red {
    path {
      fill: c('red');
    }
  }

  .svg-imageIcon--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-imageIcon--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    