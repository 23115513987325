import { createNamespacedHelpers } from 'vuex';
import { Actions as MediaLibraryActions, Getters as MediaLibraryGetters } from '../../../vuex/modules/medialibrary/module';
import MediaLibraryItem from '../../_molecules/mediaLibraryItem/_component.vue';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';
import SliderOverlay from '../../_organisms/sliderOverlay/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';

export const Events = {
    MEDIA_LIBRARY_ITEM_CLICKED: 'mediaLibraryItemClicked',
}

const mediaLibraryMapper = createNamespacedHelpers('medialibrary');

export default {
    name: 'MediaGrid',

    components: {
        LoadingSpinner,
        MediaLibraryItem,
        SliderOverlay,
        IconClose,
    },

    props: {},

    data() {
        return {
            cn: 'o-mediaGrid',
            perPage: 12,
            currentPage: 1,
            busy: false,
            showOverlay: false
        };
    },

    computed: {
        ...mediaLibraryMapper.mapGetters([MediaLibraryGetters.GET_MEDIA_ITEMS]),
        resultsToDisplay() {
            return this.mediaItems.length ? this.mediaItems.slice(0, this.perPage * this.currentPage) : [];
        },
        hasResultsLeft() {
            return this.mediaItems.length >= this.perPage * this.currentPage
        },
        slides() {
            return this.resultsToDisplay.map(item => {
                return {
                    ...item,
                    image: item.image,
                    title: item.title,
                    description: item.description,
                    link: item.link,
                    linkText: item.linkText,
                    linkTarget: item.linkTarget,
                    type: `asset-${item.type}`
                }
            })
        }
    },

    watch: {},

    mounted() {},

    created() {
    },

    methods: {
        ...mediaLibraryMapper.mapActions([MediaLibraryActions.ACTIVATE_DETAIL_VIEW]),
        openOverlay(item) {
            this[MediaLibraryActions.ACTIVATE_DETAIL_VIEW](item);
        },
        loadMore() {
            if (this.hasResultsLeft) {
                this.busy = true;

                setTimeout(() => {
                    this.currentPage++;
                    this.busy = false;
                }, 500);
            }
        },
    },
};
