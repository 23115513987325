import { createNamespacedHelpers } from 'vuex';
import Accordion from '../../../../_organisms/accordion/_component.vue';
import Slider from '../../../../_organisms/slider/_component.vue';
import ImageWrapper from '../../../../_atoms/image/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ContentBuilderImageSliderWithDescription',

    components: {
        Accordion,
        Slider,
        ImageWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        headline: {
            type: String,
            default: '',
        },
        subline: {
            type: String,
            default: '',
        },
        copy: {
            type: String,
            default: '',
        },
        imageSize: {
            type: String,
            default: 'landscape',
        },
        images: {
            type: Object,
            default: () => {},
        }
    },

    data() {
        return {
            cn: 'o-contentBuilderImageSliderWithDescription',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
