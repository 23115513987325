import rimraf from 'rimraf';
import env from '../../../../../.env';

const path = require('path');

const deleteLocalStorageFiles = (assetType) => {
    if (assetType) {
        const folderPath = path.join(env.offline.storage.path, assetType);
        rimraf.sync(folderPath);
    } else {
        rimraf.sync(env.offline.storage.path);
    }
};

export default deleteLocalStorageFiles;
