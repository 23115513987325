import DocumentListing from '../../../components/_organisms/documentListing/_component.vue';
import DocumentsDragDropList from '../../../components/_organisms/documentsDragDropList/_component.vue';

export default {
    name: 'DocumentOverview',

    components: {
        DocumentListing,
        DocumentsDragDropList,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-documentOverview',
            sections: [],
            showSidebar: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},
    
    created() {},

    methods: {
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        }
    },
};
