import refreshTokenMethod from './methods/refresh-token';
import checkIfLoginIsValid from './methods/check-if-login-is-valid';
import login from './methods/login';
import forgotPassword from './methods/forgot-password';
import register from './methods/register';
import resetPassword from './methods/reset-password';
import logout from './methods/logout';
import logoutOnlyFrontend from './methods/logout-only-frontend';
import loginByGetParameter from './methods/login-by-get-parameter';

const module = {
    namespaced: true,
    state: {
        token: null,
        refreshToken: null,
        isCheckingAuthentication: false,
        isTokenRefreshing: false,
    },
    mutations: {
        setToken(state, {token}) {
            state.token = token;
        },
        setRefreshToken(state, { refreshToken }) {
            state.refreshToken = refreshToken;
        },
    },
    getters: {
    },
    actions: {
        refreshToken: refreshTokenMethod,
        checkIfLoginIsValid,
        login,
        forgotPassword,
        register,
        resetPassword,
        logout,
        logoutOnlyFrontend,
        loginByGetParameter,
    }
};

export default module;
