import { mapObject } from 'vuex-json-relationship-resolver';
import { createNamespacedHelpers } from 'vuex';
import { transformHelper } from '../../../helpers/dataHelper';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import ContentPageLayout from '../../../layouts/contentPage/_component.vue';
import IntroSection from '../../../components/_sections/introSection/_component.vue';
import LocationsSection from '../../../components/_sections/locationsSection/_component.vue';
import RelatedContentSection from '../../../components/_sections/relatedContentSection/_component.vue';
import ViewWrapper from '../../../components/_organisms/viewWrapper/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'locations',

    components: {
        ContentPageLayout,
        IntroSection,
        LocationsSection,
        RelatedContentSection,
        ViewWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        locationPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            cn: 'p-locationOverview',
            sections: [],
            product: false,
        };
    },

    created() {
        if (!this.isOffline) {
            this.$store.dispatch('rr/find', 'location/_all');
        }
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({
            locations: { type: 'location', isArray: true },
        }),
        ...baseMapper.mapGetters([
            'isOffline',
        ]),
    },

    mounted() {
    },

    watch: {
        locations : {
            handler() {
                this.$nextTick(() => {
                    if (this.$refs && this.$refs.locationsTemplate) {
                        this.sections = Array.from(this.$refs.locationsTemplate.children);
                    }
                });
            }
        }
    },
    methods: {
        ...viewConditionHelper,
        ...transformHelper,
    },
};
