import { createNamespacedHelpers } from 'vuex';
import Downloads from '../../_organisms/downloads/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'TechnicalDocumentsSection',

    components: {
        Downloads,
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        downloads: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-technicalDocumentsSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
        ...baseMapper.mapGetters(['isOffline']),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        getFileLinkFrom(downloadItem) {
            if (downloadItem.content) {
                if (this.isOffline) { // eslint-disable-line
                    return downloadItem.content.offlineLink;
                }
            
                return downloadItem.content.storageUrl;
            }

            return null;
        }
    },
};
