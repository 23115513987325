import {createNamespacedHelpers} from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import BulletPoints from '../contentBuilderSection/modules/bulletPoints/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import ImageComponent from '../../_atoms/image/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ReasonsSection',

    components: {
        BulletPoints,
        SectionWrapper,
        ImageComponent,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: null,
        },
        imagePrint: {
            type: Object,
            default: null,
        },
        reasons: {
            type: Array,
            default: () => [],
        },
        mappingFunction: {
            type: Function,
            required: true,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-reasonsSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        ...viewConditionHelper,
    },
};
