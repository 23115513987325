import Task from './Task';
import { RENDERING_SIZE, TYPE_RENDERING } from '../../../../settings';
import storeAsset from '../../store-asset';
import RenderingDownloadError from '../../../helpers/errors/download/RenderingDownloadError';
import InternetConnectionError from '../../../helpers/errors/InternetConnectionError';
import registerError from '../../../helpers/registerError';

class RenderingTask extends Task {
    constructor(downloadManager, dataExecutor, rendering, fileExtension) {
        super();
        this.downloadManager = downloadManager;
        this.dataExecutor = dataExecutor;
        this.rendering = rendering;
        this.fileExtension = fileExtension;
    }

    async run() {
        if (!this.rendering) {
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingRenderingsDone');
            return;
        }
        
        try {
            await this.saveRenderingToDisk();
            this.downloadManager.context.commit('INCREASE_downloadStateLoadingRenderingsDone');
        } catch (err) {
            this.handleError(err);
        }
    }

    // eslint-disable-next-line
    handleError(err) {
        registerError('RenderingTask could not load rendering', err);

        if (err instanceof InternetConnectionError) {
            throw err;
        } else {
            throw new RenderingDownloadError('RenderingTask could not load rendering');
        }
    }

    async saveRenderingToDisk() {
        if (!this.rendering.default) {
            throw new RenderingDownloadError('Default format does not exist for rendering');
        }

        const size = this.getRenderingSize();
        const url = this.rendering.default[size];
        const storageLink = await storeAsset(url, this.fileExtension, TYPE_RENDERING);
        this.rendering.offline = storageLink;
    }
    
    // eslint-disable-next-line
    getRenderingSize() {
        if (this.rendering.default[RENDERING_SIZE]) {
            return RENDERING_SIZE;
        }

        // rendering of size RENDERING_SIZE is not available
        // use biggest available rendering
        const sizes = Object.keys(this.rendering.default);
        return Math.max(...sizes);
    }
}

export default RenderingTask;
