import { mapObject } from 'vuex-json-relationship-resolver';
import { createNamespacedHelpers } from 'vuex';
import DetailPage from '../../layouts/detailPage/_component.vue';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';
import ImageIntroSection from '../../components/_sections/imageIntroSection/_component.vue';
import OutputProductSection from '../../components/_sections/outputProductSection/_component.vue';
import OutputProductSectionPrint from '../../components/_print/outputProductSection/_component.vue';
import { REDIRECT_TO_404_TIME_MS } from '../../../settings';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'productOverview',

    components: {
        DetailPage,
        ViewWrapper,
        ImageIntroSection,
        OutputProductSection,
        OutputProductSectionPrint,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-productOverview',
            sections: [],
            timeout404Page: null,
        };
    },

    computed: {
        ...mapObject({
            productOverview: {
                type: 'product-overview',
                filter: {
                    slug: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.slug;
                    }
                }
            },
            navItem: {
                type: 'navigation-item',
                filter: {
                    link: ($vm) => {
                        if (!$vm) return;
                        return `/product-overview/${$vm.$route.params.slug}`;
                    }
                }
            },
        }),
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {
        productOverview() {
            this.initSections();
            this.$nextTick(() => {
                this.setCurrentDocument();
            });
        },
        navItem(newItem, oldItem) {
            if (!oldItem && newItem) {
                this.$nextTick(() => {
                    this.setCurrentDocument();
                });
            }
        }
    },

    mounted() {
        this.setCurrentDocument();
        if (this.productOverview) {
            this.initSections();
        }
    },

    created() {
        this.$store.dispatch('rr/find', `product-overview/${this.$route.params.slug}`);

        this.timeout404Page = setTimeout(() => {
            if (!this.productOverview) {
                this.$router.push({ name: 'page404' });
            }
        }, REDIRECT_TO_404_TIME_MS);
    },

    methods: {
        setCurrentDocument() {
            if (this.navItem && this.productOverview) {
                this.$store.dispatch('documents/setCurrentDocumentTo', null);
                this.$nextTick(() => {
                    this.$store.dispatch('documents/setCurrentDocumentTo', {
                        id: `${this.navItem.id}`,
                        tableOfContentsTitle: this.navItem.pdfName || this.navItem.name,
                        urlPath: `/product-overview/${this.$route.params.slug}`,
                    });
                });
            }
        },
        initSections() {
            this.$nextTick(() => {
                this.sections = Array.from(this.$refs.productOverviewTemplate.children);
            });
        },
    },

    destroyed() {
        this.$store.dispatch('documents/setCurrentDocumentTo', null);
        clearTimeout(this.timeout404Page);
    },
};
