import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import HighlightSlider from '../../_organisms/highlightSlider/_component.vue';
import HighlightSliderPrintTemplate from '../../_print/highlightSlider/_component.vue';
import '../../../assets/js/scrollTriggers';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'HighlightSliderSection',

    components: {
        SectionWrapper,
        HighlightSlider,
        HighlightSliderPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        highlights: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
        isProductLine: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-highlightSliderSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['viewport', 'isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
    },
};
