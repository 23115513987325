import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SliderOverlay from '../../_organisms/sliderOverlay/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';
import BulletPoints from '../contentBuilderSection/modules/bulletPoints/_component.vue';
import applicationDetailsPrintTemplate from '../../_print/applicationDetails/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ApplicationDetailsSection',

    components: {
        CardSlider,
        SliderOverlay,
        IconClose,
        SectionWrapper,
        BulletPoints,
        applicationDetailsPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: null,
        },
        category: {
            type: String,
            default: null,
        },
        detailSlider: {
            type: Array,
            default: () => [],
        },
        detailSliderPreviewImages: {
            type: Array,
            default: () => [],
        },
        detailSliderBigImages: {
            type: Array,
            default: () => [],
        },
        detailFacts: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-applicationDetailsSection',
            showOverlay: false,
            activeSliderItem: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
        slides() {
            return this.detailSlider.map((slide, index) => ({
                id: index,
                callback: this.openSliderOverlay,
                image: this.detailSliderPreviewImages[index] || null,
            }));
        },
        overlaySlides() {
            return this.detailSlider.map((slide, index) => ({
                ...this.detailSliderBigImages[index],
                captionOverwrite: {
                    title: slide.headline || '',
                    content: slide.bulletPoints || '',
                }
            }));
        },
        facts() {
            return this.detailFacts.map((fact) => ({
                text: fact.bulletPoint,
                iconType: fact.icon.value,
            }));
        }
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,

        openSliderOverlay(index) {
            if (!this.isPrinting) {
                this.activeSliderItem = index;
                this.showOverlay = true;
                this.$store.dispatch('base/lockBody');
            }
        },

        closeOverlay() {
            this.activeSliderItem = null;
            this.showOverlay = false;
            this.$store.dispatch('base/freeBody');
        },
    },
};
