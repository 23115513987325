import VuexPersistence from 'vuex-persist';
import isDesktopApp from '../services/is-desktop-app';


export const vuexAuthPersistence = new VuexPersistence({
    key: 'vuexAuth',
    storage: window.localStorage,
    modules: ['auth'],
    supportCircular: true,
});

export const vuexBasePersistence = new VuexPersistence({
    key: 'vuexBase',
    storage: window.localStorage,
    modules: ['base'],
    reducer: (state) => {
        return { base: {
            http: state.base.http,
            isDesktopApp: state.base.isDesktopApp,
            isInPdfGenerationMode: state.base.isInPdfGenerationMode,
            isPrinting: state.base.isPrinting,
            isTouchDevice: state.base.isTouchDevice,
            language: state.base.language,
        }};
    },
    supportCircular: true,
});

export const vuexDesktopAppPersistence = new VuexPersistence({
    key: 'vuexDesktopApp',
    storage: window.localStorage,
    modules: ['desktopApp'],
    supportCircular: true,
});

export const vuexDocumentPersistence = new VuexPersistence({
    key: 'vuexDocuments',
    storage: window.localStorage,
    modules: ['documents'],
    reducer: (state) => {
        return { documents: {
            addedDocuments: state.documents.addedDocuments,
            documentsToSendQueue: state.documents.documentsToSendQueue,
            generatingDocumentsQueue: state.documents.generatingDocumentsQueue,
            isGenerating: state.documents.isGenerating,
            receiver: state.documents.receiver,
        }};
    },
    supportCircular: true,
});

export const vuexNotificationPersistence = new VuexPersistence({
    key: 'vuexNotifications',
    storage: window.localStorage,
    modules: ['notifications'],
    reducer: (state) => {
        return { notifications: {
            currentNotifications: state.notifications.currentNotifications,
        }};
    },
    supportCircular: true,
});

let fileStorage = null;

if (isDesktopApp) {
    // eslint-disable-next-line
    fileStorage = require('electron-json-storage');

    fileStorage.setItem = (key, object) => {
        return new Promise((resolve, reject) => {
            fileStorage.set(key, object, (error, data) => {
                if (error) reject(error);
                resolve(data);
            });
        });
    };

    fileStorage.getItem = (key) => {
        return new Promise((resolve, reject) => {
            fileStorage.get(key, (error, data) => {
                if (error) reject(error);

                // ----- necessary because old app version v1.1.0 stores state differently
                let resultData = data;
                if(typeof data === 'string') {
                    try {
                        const FlattedJSON = require('flatted');
                        resultData = FlattedJSON.parse(data);
                    } catch (error) {
                        resultData = JSON.parse(data);
                    }
                } // -----

                resolve(resultData);
            });
        });
    };
}

export const vuexObjectsPersistence = new VuexPersistence({
    key: 'vuexObjects',
    strictMode: true,
    asyncStorage: isDesktopApp,
    storage: fileStorage || window.localStorage,
    modules: ['rr'],
    reducer: (state) => {
        return { rr: { objects: state.rr.objects }};
    },
    filter: (mutation) => mutation.type === 'desktopApp/SET_isOffline',
    supportCircular: true,
});
