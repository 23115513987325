import MoveIcon from '../../_svg/icon/move.vue';
import TrashEmptyIcon from '../../_svg/icon/trash-empty.vue';

export default {
    name: 'DocumentsDragDropListItem',

    components: {
        MoveIcon,
        TrashEmptyIcon,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            cn: 'm-documentsDragDropListItem',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {

    },

    mounted() {},

    created() {},

    methods: {
        removeDocument() {
            this.$store.dispatch('documents/remove', this.id);
        },
    },
};
