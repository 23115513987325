const login = (context, {username, password}) => {
    return new Promise((resolve, reject) => {
        const searchParams = (params) => {
            return Object.keys(params).map((key) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
            }).join('&');
        };

        fetch(`${context.rootState.base.http.baseUrl}auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            },
            body: searchParams({
                'credentials[username]': username,
                'credentials[password]': password,
            })
        }).then((response) => {
            return response.json().then(body => {
                if (response.ok) {
                    context.commit('setRefreshToken', {
                        refreshToken: body.data.attributes.refresh_token
                    });
                    context.commit('setToken', {token: body.data.attributes.token});
                    context.dispatch('rr/find', body.data.relationships.user, { root: true });
                    resolve();
                } else {
                    reject(body.message);
                }
            });
        });
    });
};

export default login;