import svg from '../../../assets/img/icons/map/cluster.svg';

const clusterOptions = {
    zoomOnClick: true,
    styles: [
        {
            textColor: '#e11624',
            url: svg,
            height: 61,
            width: 60
        },
    ]
};

export default clusterOptions;