import { mapObject } from 'vuex-json-relationship-resolver';
import { createNamespacedHelpers } from 'vuex';
import anime from 'animejs';
import { transformHelper } from '../../helpers/dataHelper';
import ChevronButton from '../../components/_atoms/chevronButton/_component.vue';
import ImageComponent from '../../components/_atoms/image/_component.vue';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';
import VideoPlayer from '../../components/_molecules/videoPlayer/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'home',

    components: {
        ChevronButton,
        ImageComponent,
        ViewWrapper,
        VideoPlayer,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: () => {
            },
        },
    },

    data() {
        return {
            cn: 'p-home',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({
            homePage: {
                type: 'homepage',
            }
        }),
        ...baseMapper.mapGetters(['isOffline']),
        ...baseMapper.mapState(['isDesktopApp']),
    },

    watch: {
        homePage(newHome, oldHome) {
            if (newHome && !oldHome) {
                this.$nextTick(() => {
                    this.doAnimations();
                });
            }
        }
    },

    mounted() {
        if (this.homePage) {
            this.doAnimations();
        }
    },

    created() {
        if (!this.isOffline) {
            this.$store.dispatch('rr/find', 'home-page');
        }
    },

    methods: {
        ...transformHelper,

        getLinkFrom(teaser) {
            if (!teaser || !teaser.link || !teaser.link.type) return '/';

            switch (teaser.link.type) {
                case 'entry':
                    return this.getHomeTeaserLinkFromEntry(teaser.entry, this.isDesktopApp);
                case 'asset':
                    return this.getHomeTeaserLinkFromAsset(teaser.linkAsset, this.isOffline);
                case 'url':
                    return teaser.link.url;
                default:
                    return '/';
            }
        },

        doAnimations() {
            // Els Slide from bottom
            const {homeSection} = this.$refs;
            const els = [...homeSection.querySelectorAll('.p-home__headline, .p-home__teaser')];

            if (els) {
                els.forEach((el) => {
                    el.style.opacity = 0;
                    el.style.transform = 'translateY(100px)';
                });

                anime({
                    targets: els,
                    easing: 'easeOutQuart',
                    begin: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animated');
                            aniEl.target.classList.add('js-animating');
                        });
                    },
                    complete: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animating');
                            aniEl.target.classList.add('js-animated');
                        });
                    },
                    delay: 0,
                    translateY: [
                        {value: 0, duration: 500}
                    ],
                    opacity: [
                        {value: 1, duration: 750}
                    ],
                });
            }

            // Image Fading
            const image = homeSection.querySelector('.p-home__video');
            if (image) {
                image.style.opacity = 0;

                anime({
                    targets: image,
                    easing: 'linear',
                    begin: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animated');
                            aniEl.target.classList.add('js-animating');
                        });
                    },
                    complete: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animating');
                            aniEl.target.classList.add('js-animated');
                        });
                    },
                    delay: 1000,
                    duration: 1500,
                    opacity: 1
                });
            }
        }
    },
};
