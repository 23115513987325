import ContentPage from '../../views/contentPage/_component.vue';

const routes = [
    {
        path: '/content-page/:slug',
        name: 'content-page',
        component: ContentPage,
        meta: {
            pdfRenderer: {
                enabled: true,
                title: ''
            },
            isPrintable: true,
            requiresAuth: true,
            showTitleInHeader: true,
            documentAddable: true,
        },
    },
];

export default routes;