import productRoutes from './product';
import productOverviewRoutes from './productOverview';
import productLineRoutes from './productLine';
import channelRoutes from './channels';
import userRoutes from './user';
import miscRoutes from './misc';
import documentRoutes from './documents';
import contentPageRoutes from './contentPage';
import mediaLibraryRoutes from './mediaLibrary';
import landingPage from '../../views/landingPage/_component.vue';
import Page404 from '../../views/page404/_component.vue';

const routes = [
    ...productRoutes,
    ...productOverviewRoutes,
    ...productLineRoutes,
    ...channelRoutes,
    ...userRoutes,
    ...miscRoutes,
    ...documentRoutes,
    ...mediaLibraryRoutes,
    ...contentPageRoutes,
    {
        path: '/',
        name: 'home',
        component: landingPage,
        meta: {
            hideAppHeader: true,
            requiresAuth: false,
        },
    },
    {
        path: '*',
        name: 'page404',
        component: Page404,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: false,
        },
    },
];

export default routes;