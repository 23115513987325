var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewWrapper',{attrs:{"property":_vm.caseStudy}},[(_vm.caseStudy)?_c('DetailPage',{attrs:{"sections":_vm.sections},scopedSlots:_vm._u([{key:"default",fn:function(detailPage){return [_c('div',{ref:"caseStudySections"},[_c('IntroSection',{attrs:{"id":"overview","title":_vm.caseStudy.title,"subline":"","image":_vm.caseStudy.image,"scroll-function":function () { return detailPage.scrollToSection(1); }}}),_c('DetailSection',{attrs:{"id":"details","show-section":_vm.relationsNotEmpty(_vm.caseStudy, 'detail'),"category":_vm.caseStudy.categoryList && _vm.caseStudy.categoryList['0'] ? _vm.caseStudy.categoryList['0'].title : null,"details":_vm.getArrayFrom(_vm.caseStudy.detailList)}}),_c('ProductSection',{attrs:{"id":"products","show-section":_vm.relationsNotEmpty(_vm.caseStudy, 'product') ||
                          _vm.relationsNotEmpty(_vm.caseStudy, 'productLine'),"products":_vm.products}}),_c('ApplicationSection',{attrs:{"id":"usecases","show-section":_vm.relationsNotEmpty(_vm.caseStudy, 'application'),"applications":_vm.getArrayFrom(_vm.caseStudy.applicationList),"mapping-function":function (application) { return ({
                              id: application.id,
                              title: application.title,
                              category: application.category,
                              image: application.productImage,
                              link: { name: 'product', slug: application.slug },
                            }); }}}),_c('RelatedContentSection',{attrs:{"id":"relatedContent","show-section":_vm.relationsNotEmpty(_vm.caseStudy, 'relatedContent'),"contents":_vm.getArrayFrom(_vm.caseStudy.relatedContentList),"mapping-function":function (content) { return ({
                                 id: content.id,
                                 title: content.title,
                                 category: content.category,
                                 image: content.productImage,
                                 link: _vm.getRelatedContentLinkFrom(content),
                               }); }}})],1)]}}],null,false,2009144777)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }