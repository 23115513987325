import Executor from '../Executor';
import DataObjectTask from '../Executor/tasks/DataObjectTask';
import ImageTask from '../Executor/tasks/ImageTask';
import RenderingTask from '../Executor/tasks/RenderingTask';
import PdfTask from '../Executor/tasks/PdfTask';
import VideoTask from '../Executor/tasks/VideoTask';
import getTimeString from '../../helpers/getTimeString';

import {
    TYPE_IMAGE,
    TYPE_PRODUCT,
    TYPE_VIDEO,
    TYPE_PDF,
    TYPE_WEBROTATE_360,
} from '../../../settings';
import Webrotate360Task from '@/services/Executor/tasks/Webrotate360Task';

class DownloadManager {
    constructor(vuexContext) {
        this.context = vuexContext;
        this.history = [];
        this.startTime = null;
        this.endTime = null;
    }

    async downloadAll() {
        this.startTime = Date.now();

        // WARNING: if this order is changed,
        // handleOfflineError.js and
        // retryDownloadingAfterError.js must be adjusted
        await this.downloadDataObjects();
        await this.downloadImages();
        await this.downloadRenderings();
        await this.downloadVideos();
        await this.downloadPDFs();
        await this.downloadWebrotate360();

        this.endTime = Date.now();
        // eslint-disable-next-line
        console.log('download time', getTimeString(this.startTime, this.endTime));
    }

    async downloadDataObjects() {
        this.context.commit('SET_downloadStateObjectsTotal', 0);
        this.context.commit('SET_downloadStateObjectsDone', 0);

        // eslint-disable-next-line
        console.log('download data objects');

        let routes = [];

        const response = await this.context.dispatch('rr/find', 'offline-route/_all', { root: true });
        routes = response;

        const dataExecutor = new Executor(this.context, 'loadingObjectsStatistics', 20);
        routes.map(routeObject => routeObject.route).forEach(route => {
            this.context.commit('INCREASE_downloadStateObjectsTotal');
            dataExecutor.enqueueTask(new DataObjectTask(this, dataExecutor, route));
        });

        try {
            await dataExecutor.start();
        } catch (error) {
            dataExecutor.stop();
            throw error;
        }
    }

    async downloadImages() {
        // eslint-disable-next-line
        console.log('download images');

        const images = this.context.rootState.rr.objects.filter(o => o.type === TYPE_IMAGE);
        const imageExecutor = new Executor(this.context, 'loadingImagesStatistics', 1);
        images.forEach(image => {
            imageExecutor.enqueueTask(new ImageTask(this, imageExecutor, image));
        });

        this.context.commit('SET_downloadStateLoadingImagesTotal', images.length);
        this.context.commit('SET_downloadStateLoadingImagesDone', 0);

        try {
            await imageExecutor.start();
        } catch (error) {
            imageExecutor.stop();
            throw error;
        }
    }

    async downloadRenderings() {
        // eslint-disable-next-line
        console.log('download renderings');

        const products = this.context.rootState.rr.objects.filter(o => o.type === TYPE_PRODUCT);
        const renderingExecutor = new Executor(this.context, 'loadingRenderingsStatistics', 4);
        products.forEach(product => {
            if (product['three-d-renderings'] &&
            product['three-d-renderings'].images &&
            product['three-d-renderings'].meta) {
                const extension = product['three-d-renderings'].meta.extension || 'png';
                product['three-d-renderings'].images.forEach((rendering) => {
                    renderingExecutor.enqueueTask(new RenderingTask(this, renderingExecutor, rendering, extension));
                });
            }
        });

        this.context.commit('SET_downloadStateLoadingRenderingsTotal', renderingExecutor.taskQueue.getLength());
        this.context.commit('SET_downloadStateLoadingRenderingsDone', 0);

        try {
            await renderingExecutor.start();
        } catch (error) {
            renderingExecutor.stop();
            throw error;
        }
    }

    async downloadWebrotate360() {
        // eslint-disable-next-line
        console.log('download Webrotate360 renderings');
        const renderings = this.context.rootState.rr.objects.filter(o => o.type === TYPE_WEBROTATE_360);
        const webrotate360Executor = new Executor(this.context, 'loadingWebrotate360Statistics', 1);
        renderings.forEach(rendering => {
            rendering.attributes.renderingPaths.forEach((renderingPath) => {
                webrotate360Executor.enqueueTask(new Webrotate360Task(this, webrotate360Executor, `${renderingPath.baseUrl}${renderingPath.renderingFolder}/${renderingPath.xmlFile}`,2));
                renderingPath.imagePaths.forEach((imagePath) => {
                    const url = `${renderingPath.baseUrl}${renderingPath.renderingFolder}/${imagePath}`;
                    webrotate360Executor.enqueueTask(new Webrotate360Task(this, webrotate360Executor, url, 3));
                });
                renderingPath.hotspotPaths.forEach((hotspotPath) => {
                    const url = `${renderingPath.baseUrl}${renderingPath.renderingFolder}/${hotspotPath}`;
                    webrotate360Executor.enqueueTask(new Webrotate360Task(this, webrotate360Executor, url, 3));
                });
                renderingPath.themePaths.forEach((themePath) => {
                    const url = `${renderingPath.baseUrl}${renderingPath.renderingFolder}/${themePath}`;
                    webrotate360Executor.enqueueTask(new Webrotate360Task(this, webrotate360Executor, url, 3));
                });
            });
        });

        this.context.commit('SET_downloadStateLoadingWebrotate360Total', webrotate360Executor.taskQueue.getLength());
        this.context.commit('SET_downloadStateLoadingWebrotate360Done', 0);

        try {
            await webrotate360Executor.start();
        } catch (error) {
            webrotate360Executor.stop();
            throw error;
        }
    }

    async downloadVideos() {
        // eslint-disable-next-line
        console.log('download videos');

        const videos = this.context.rootState.rr.objects.filter(o => o.type === TYPE_VIDEO);
        const videoExecutor = new Executor(this.context, 'loadingVideosStatistics', 1);
        videos.forEach(video => {
            videoExecutor.enqueueTask(new VideoTask(this, videoExecutor, video));
        });

        this.context.commit('SET_downloadStateLoadingVideosTotal', videos.length);
        this.context.commit('SET_downloadStateLoadingVideosDone', 0);

        try {
            await videoExecutor.start();
        } catch (error) {
            videoExecutor.stop();
            throw error;
        }
    }

    async downloadPDFs() {
        // eslint-disable-next-line
        console.log('download pdfs');

        const pdfs = this.context.rootState.rr.objects.filter(o => o.type === TYPE_PDF);
        const pdfExecutor = new Executor(this.context, 'loadingPdfsStatistics', 1);
        pdfs.forEach(pdf => {
            pdfExecutor.enqueueTask(new PdfTask(this, pdfExecutor, pdf));
        });

        this.context.commit('SET_downloadStateLoadingPdfsTotal', pdfs.length);
        this.context.commit('SET_downloadStateLoadingPdfsDone', 0);

        try {
            await pdfExecutor.start();
        } catch (error) {
            pdfExecutor.stop();
            throw error;
        }
    }
}

export default DownloadManager;
