import isDesktopApp from '../../../services/is-desktop-app';

const actions = {};

actions.linkToEntry = (notification) => {
    notification.$store.dispatch('notifications/removeNotification', notification.id);

    // eslint-disable-next-line
    switch (notification.linkedSectionType) {
        case 'products':
            notification.$router.push({ name: 'product',
                params: { slug: notification.linkedEntrySlug }}).catch(() => {});
            break;

        case 'productOverviewPages':
            notification.$router.push({ name: 'product-overview',
                params: { slug: notification.linkedEntrySlug }}).catch(() => {});
            break;

        case 'productLines':
            notification.$router.push({ name: 'product-line',
                params: { slug: notification.linkedEntrySlug }}).catch(() => {});
            break;

        case 'productApplication':
            notification.$router.push({ name: 'product-application',
                params: { slug: notification.linkedEntrySlug }}).catch(() => {});
            break;

        case 'pages':
            notification.$router.push({ name: 'content-page',
                params: { slug: notification.linkedEntrySlug }}).catch(() => {});
            break;
    }
};

if (isDesktopApp) {
    // eslint-disable-next-line
    const { ipcRenderer } = require('electron');

    actions.restartApp = () => {
        ipcRenderer.send('restartApp');
    };

    actions.checkForUpdate = (notification) => {
        notification.$store.dispatch('notifications/removeNotification', notification.id);
        ipcRenderer.send('checkForUpdate');
    };

    actions.enableOfflineMode = (notification) => {
        notification.$store.dispatch('notifications/removeNotification', notification.id);
        notification.$store.dispatch('desktopApp/switchToOffline');
    };

    actions.disableOfflineMode = (notification) => {
        notification.$store.dispatch('notifications/removeNotification', notification.id);
        if (notification.$store.state.desktopApp.isOffline) {
            notification.$store.dispatch('desktopApp/switchToOnline');
        }
    };
}

export default actions;
