import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import AccordionImageCombination from '../../_organisms/accordionImageCombination/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import ImageVideoGallery from '../../_organisms/imageVideoGallery/_component.vue';
import View360Container from '../../_organisms/view360Container/_component.vue';
import Webrotate360Container from '../../_organisms/webrotate360Container/_component.vue';
import ImageComponent from '../../_atoms/image/_component.vue';
import '../../../assets/js/scrollTriggers';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'HighlightSection',

    components: {
        ImageComponent,
        AccordionImageCombination,
        SectionWrapper,
        ImageVideoGallery,
        View360Container,
        Webrotate360Container
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        highlights: {
            type: Array,
            default: () => [],
        },
        rendering: {
            type: Object,
            default: () => null,
        },
        webrotate360: {
            type: Array,
            default: () => null,
        },
        printRendering: {
            type: Object,
            default: () => null,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        subline: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-highlightSection',
            showHighlights: true,
            showRendering: true,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['viewport', 'isPrinting']),
        ...baseMapper.mapGetters(['isOffline']),
        slides() {
            return this.highlights.map(highlight => ({
                ...this.getVideoOrImageFrom(highlight),
                captionOverwrite: {
                    title: highlight.accordionHeadline,
                    content: highlight.accordionCopy,
                },
            }));
        },
        view360images() {
            const images = [];
            if (this.rendering && this.rendering.images) {
                const windowWidth = this.viewport ? this.viewport.width : null;
                this.rendering.images.forEach((image) => { // eslint-disable-line
                    if (this.isOffline) {
                        images.push(image.offline);
                    } else if (image.default && windowWidth && image.default[1600] && windowWidth > 800) {
                        images.push(image.default[1600]);
                    } else if (image.default && windowWidth && image.default[400] && windowWidth < 400) {
                        images.push(image.default[400]);
                    } else if (image.default && image.default[800]) {
                        images.push(image.default[800]);
                    } else if (image.default) {
                        const defaultKey = Object.keys(image.default).sort()[0];
                        images.push(image[defaultKey]);
                    }
                });
            }
            return images;
        },
        view360() {
            let view360 = null;
            if (this.rendering && this.view360images.length > 0) {
                view360 = {
                    images: this.view360images,
                    thumbnail: this.rendering.thumbnail,
                    meta: this.rendering.meta,
                };
            }
            return view360;
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
        getVideoOrImageFrom(highlight) {
            let videoOrImage = {};
            if (highlight.videoList && Object.values(highlight.videoList).length > 0) {
                videoOrImage = {
                    ...highlight.videoList[0],
                    title: highlight.videoList[0].title,
                    versions: highlight.videoList[0].versions,
                };
            } else if (highlight.imagesList && Object.values(highlight.imagesList).length > 0) {
                videoOrImage = {
                    ...highlight.imagesList[0],
                };
            }
            return videoOrImage;
        }
    },
};
