import { mapObject } from 'vuex-json-relationship-resolver';
import { createNamespacedHelpers } from 'vuex';
import { transformHelper, viewConditionHelper } from '../../helpers/dataHelper';
import AppHeader from '../../components/_organisms/appHeader/_component.vue';
import Home from '../home/_component.vue';
import Login from '../authentification/login/_component.vue';
import ImageComponent from '../../components/_atoms/image/_component.vue';
import LandingPageFASection from '../../components/_sections/landingPageFASection/_component.vue';
import LandingPageFooterSection from '../../components/_sections/landingPageFooterSection/_component.vue';
import LandingPageIntroSection from '../../components/_sections/landingPageIntroSection/_component.vue';
import LandingPageFaqSection from '../../components/_sections/landingPageFaqSection/_component.vue';
import LandingPageVideoSection from '../../components/_sections/landingPageVideoSection/_component.vue';
import ViewWrapper from '../../components/_organisms/viewWrapper/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'landingPage',

    components: {
        AppHeader,
        Home,
        Login,
        ImageComponent,
        LandingPageFASection,
        LandingPageFooterSection,
        LandingPageIntroSection,
        ViewWrapper,
        LandingPageFaqSection,
        LandingPageVideoSection,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: () => {
            },
        },
    },

    data() {
        return {
            cn: 'p-landingpage',
            downloadLinkOsx: 'https://update-service.laska-app.com/download/osx',
            downloadLinkWin: 'https://update-service.laska-app.com/download/win',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({
            landingPage: {
                type: 'landingpage',
            },
            user: {
                type: 'user'
            }
        }),
        ...baseMapper.mapGetters(['isOffline']),
        ...baseMapper.mapState(['isDesktopApp']),
    },

    watch: {
    },

    mounted() {
    },

    created() {
        if (!this.isOffline && !this.user) {
            this.$store.dispatch('rr/find', 'landing-page');
        }
    },

    methods: {
        ...viewConditionHelper,
        ...transformHelper,
    },
};
