import { mapObject } from 'vuex-json-relationship-resolver';
import Locations from './locations/_component.vue';
import Medialibrary from './mediathek/_component.vue';
import Default from './default/_component.vue';

export default {
    name: 'ContentPageRouter',

    components: {
        Locations,
        Medialibrary,
        Default,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-contentPageRouter',
        };
    },

    watch: {
        '$route.params.slug'() {
            this.$store.dispatch('rr/find', `content-page/${this.$route.params.slug}`);
        },
        contentPage() {
            this.setCurrentDocument();
        },
        navItem(newItem, oldItem) {
            if (!oldItem && newItem) {
                this.$nextTick(() => {
                    this.setCurrentDocument();
                });
            }
        }
    },

    created() {
        this.$store.dispatch('rr/find', `content-page/${this.$route.params.slug}`);
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({
            contentPage: {
                type: 'content-page',
                filter: {
                    slug: ($vm) => {
                        if (!$vm) return;
                        return $vm.$route.params.slug;
                    }
                }
            },
            navItem: {
                type: 'navigation-item',
                filter: {
                    itemHidden: ($vm) => {
                        if (!$vm) return;
                        return false;
                    },
                    link: ($vm) => {
                        if (!$vm) return;
                        return `/content-page/${$vm.$route.params.slug}`;
                    },
                },
            },
        }),
    },

    mounted() {
        this.setCurrentDocument();
    },

    methods: {
        setCurrentDocument() {
            if (this.navItem) {
                this.$store.dispatch('documents/setCurrentDocumentTo', {
                    id: `${this.navItem.id}`,
                    tableOfContentsTitle: this.navItem.pdfName || this.navItem.name,
                    urlPath: `/content-page/${this.$route.params.slug}`,
                });
            }
        },
    },

    destroyed() {
        this.$store.dispatch('documents/setCurrentDocumentTo', null);
    },
};
