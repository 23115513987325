import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../../i18n';

const electron = require('electron');

const noOfflineModeCheckingForUpdateNotification = {
    id: uuidv4(),
    icon: 'update',
    text: i18n.t('notifications.noOfflineModeCheckingForUpdateNotificationMessage'),
    action: 'enableOfflineMode',
    actionText: i18n.t('notifications.noOfflineModeCheckingForUpdateNotificationAction'),
    deleteOnClose: true,
};

const noOfflineModeDownloadingUpdateNotification = {
    id: uuidv4(),
    icon: 'update',
    text: i18n.t('notifications.noOfflineModeDownloadingUpdateNotificationMessage'),
    action: '',
    deleteOnClose: true,
};

const noOfflineModeUpdateDownloadedNotification = {
    id: uuidv4(),
    icon: 'update',
    text: i18n.t('notifications.noOfflineModeUpdateDownloadedNotificationMessage'),
    action: 'restartApp',
    actionText: i18n.t('notifications.noOfflineModeUpdateDownloadedNotificationAction'),
    deleteOnClose: true,
};

const isUpdating = (context) => {
    const { updateState } = electron.remote.getGlobal('autoUpdater');

    if (!updateState) {
        return false;
    }

    // eslint-disable-next-line
    switch (updateState) {
        case 'checkingForUpdate':
            context.dispatch('notifications/addNotification',
                noOfflineModeCheckingForUpdateNotification,
                { root: true });
            break;
        case 'downloadingUpdate':
            context.dispatch('notifications/addNotification',
                noOfflineModeDownloadingUpdateNotification,
                { root: true });
            break;
        case 'updateDownloaded':
            context.dispatch('notifications/addNotification',
                noOfflineModeUpdateDownloadedNotification,
                { root: true });
            break;
    }

    return true;
};

export default isUpdating;
