import DownloadManager from '../../../../services/DownloadManager';
import {
    TYPE_IMAGE,
    TYPE_PDF,
    TYPE_RENDERING,
    TYPE_VIDEO,
    TYPE_WEBROTATE_360,
} from '../../../../../settings';

// eslint-disable-next-line
const retryFromImageDownload = async (downloadManager) => {
    // eslint-disable-next-line
    console.log('Retry loading images');
    await downloadManager.downloadImages();
    await downloadManager.downloadRenderings();
    await downloadManager.downloadVideos();
    await downloadManager.downloadPDFs();
    await downloadManager.downloadWebrotate360();
};

// eslint-disable-next-line
const retryFromPdfDownload = async (downloadManager) => {
    // eslint-disable-next-line
    console.log('Retry loading pdf files');
    await downloadManager.downloadPDFs();
    await downloadManager.downloadWebrotate360();
};

// eslint-disable-next-line
const retryFromRenderingDownload = async (downloadManager) => {
    // eslint-disable-next-line
    console.log('Retry loading renderings');
    await downloadManager.downloadRenderings();
    await downloadManager.downloadVideos();
    await downloadManager.downloadPDFs();
    await downloadManager.downloadWebrotate360();
};

// eslint-disable-next-line
const retryFromVideoDownload = async (downloadManager) => {
    // eslint-disable-next-line
    console.log('Retry loading videos');
    await downloadManager.downloadVideos();
    await downloadManager.downloadPDFs();
    await downloadManager.downloadWebrotate360();
};

const retryFromWebrotate360Download = async (downloadManager) => {
    // eslint-disable-next-line
    console.log('Retry loading webrotate360');
    await downloadManager.downloadWebrotate360();
}

// eslint-disable-next-line
const retryDownloadingAfterError = async (context) => {
    context.dispatch('startLoading');

    const downloadManager = new DownloadManager(context);

    if (context.state.errorType === TYPE_IMAGE) {
        await retryFromImageDownload(downloadManager);
    } else if (context.state.errorType === TYPE_PDF) {
        await retryFromPdfDownload(downloadManager);
    } else if (context.state.errorType === TYPE_RENDERING) {
        await retryFromRenderingDownload(downloadManager);
    } else if (context.state.errorType === TYPE_VIDEO) {
        await retryFromVideoDownload(downloadManager);
    } else if (context.state.errorType === TYPE_WEBROTATE_360) {
        await retryFromWebrotate360Download(downloadManager);
    } else {
        // eslint-disable-next-line
        console.log('Retry loading everything');
        await context.dispatch('switchToOnline', { error: true, offlineStatusForUpdates: false });
        context.dispatch('startLoading');
        await downloadManager.downloadAll();
    }

    context.dispatch('setLoadingErrorType', null);
};

export default retryDownloadingAfterError;
