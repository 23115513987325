import anime from 'animejs';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import MediaGrid from '../../_organisms/mediaGrid/_component.vue';
import MediaTypeToggle from '../../_organisms/mediaTypeToggle/_component.vue';
import MediaFilters from '../../_organisms/mediaFilters/_component.vue';
import { transformHelper } from '../../../helpers/dataHelper';

export default {
    name: 'MedialibrarySection',

    components: {
        SectionWrapper,
        MediaGrid,
        MediaFilters,
        MediaTypeToggle
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-medialibrarySection',
            hasOpenFilters: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {
    },

    created() {
    },

    methods: {
        ...transformHelper,
        toggleFilters() {
            this.hasOpenFilters = !this.hasOpenFilters;
            if (this.hasOpenFilters) this.$store.dispatch('base/lockBody');
            if (!this.hasOpenFilters) this.$store.dispatch('base/freeBody');
        },
        getItems(category) {
            if (category) {
                const children = this.getArrayFrom(category.childrenList);
                return children;
            }

            return [];
        },
        doFadeIn(el) {
            // Image Fading
            if (el) {
                el.style.opacity = 0;

                anime({
                    targets: el,
                    easing: 'linear',
                    begin: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animated');
                            aniEl.target.classList.add('js-animating');
                        });
                    },
                    complete: (response) => {
                        response.animatables.forEach((aniEl) => {
                            aniEl.target.classList.remove('js-animating');
                            aniEl.target.classList.add('js-animated');
                        });
                    },
                    duration: 1500,
                    opacity: 1
                });
            }
        },
    },
};
