import { createNamespacedHelpers } from 'vuex';
import Flickity from 'vue-flickity'; // eslint-disable-line
import IconPlayStart from '../../_svg/icon/player/play-start.vue';
import IconView360 from '../../_svg/icon/view360.vue';
import ImageWrapper from '../../_atoms/image/_component.vue';
import SliderOverlay from '../sliderOverlay/_component.vue';
import VideoPlayer from '../../_molecules/videoPlayer/_component.vue';
import Viewer360 from '../../_molecules/viewer360/_component.vue';
import IconClose from '../../_svg/icon/nav/close.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ImageVideoGallery',

    components: {
        Flickity,
        IconPlayStart,
        ImageWrapper,
        SliderOverlay,
        VideoPlayer,
        Viewer360,
        IconView360,
        IconClose,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        slides: {
            type: Array,
            default: () => [],
        },
        view360: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            cn: 'o-imageVideoGallery',
            flickityOptions: {
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                dragThreshold: 3,
                prevNextButtons: true,
                pageDots: false,
                wrapAround: true,
                imagesLoaded: true,
            },
            showSliderOverlay: false,
            selectedItem: 0,
            itemToShow: 0, // same es selectedItam but with little timeout for animation
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        isInline() {
            return this.modifiers.includes('inline');
        },
        ...baseMapper.mapState(['isPrinting']),
        ...baseMapper.mapGetters(['isOffline']),
        videoSelected() {
            return this.isVideo(this.selectedItem);
        },
        view360Selected() {
            return this.isView360(this.selectedItem);
        },
    },

    watch: {
        '$route.params.slug'() {
            if (this.view360) {
                this.itemToShow = -1;
            } else {
                this.itemToShow = 0;
            }
        },
    },

    mounted() {
        if (this.view360) {
            this.itemToShow = -1;
        }
    },

    created() {},

    methods: {
        thumbnailSrcRendering() {
            if (this.isOffline) {
                return this.view360.thumbnail.offlineLink;
            }
            return this.view360.thumbnail.original;
        },
        thumbnailSrcImg(slide) {
            if (this.isOffline) {
                return slide.attributes.versions.square.offline;
            }
            return slide.attributes.versions.square.default[200];
        },
        thumbnailSrcVideo(slide) {
            if (this.isOffline) {
                return `${slide.offlineVersion.thumbnail}`;
            }
            return `${slide.versions[0].thumbnail}.${slide.versions[0].thumbnailExtension}`;
        },
        isVideo(index) {
            return this.slides[index] && this.slides[index].type === 'asset-video';
        },
        isView360(index) {
            return this.slides.length === index;
        },
        isImage(index) {
            return !this.showSlide.isVideo(index) && !this.isView360(index);
        },
        getTypeOf(index) {
            if (this.isVideo(index)) {
                return 'video';
            }
            if (this.isView360(index)) {
                return 'view360';
            }
            return 'image';
        },
        showSlide(index) {
            if (this.isInline) {
                if (this.getTypeOf(this.selectedItem) !== this.getTypeOf(index) &&
                   (this.getTypeOf(this.selectedItem) === 'view360' ||
                   (this.getTypeOf(this.selectedItem) === 'image' && this.getTypeOf(index) === 'video'))) {
                    this.selectedItem = parseInt(index, 10);
                    window.setTimeout(() => {
                        this.itemToShow = parseInt(index, 10);
                    }, 200);
                } else {
                    this.selectedItem = parseInt(index, 10);
                    this.itemToShow = parseInt(index, 10);
                }
            } else {
                this.selectedItem = parseInt(index, 10);
                this.itemToShow = parseInt(index, 10);
                this.showSliderOverlay = true;
                this.$store.dispatch('base/lockBody');
            }
        },
        closeOverlay() {
            this.showSliderOverlay = false;
            this.$store.dispatch('base/freeBody');
        },
    },
};
