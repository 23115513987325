/*
 * Description of what this file is for
 *
 * @package  Laska Salesapp
 * @author David Hellmann [david@fredmansky.at]
 * @author Sarah Schütz [sarah@fredmansky.at]
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * @author Frederic Köberl [fred@fredmansky.at]
 * Navigation
 */
import { mapObject } from 'vuex-json-relationship-resolver';
import ChevronIconLeft from '../../_svg/icon/chevron/left.vue';
import ChevronIconRight from '../../_svg/icon/chevron/right.vue';
import ChevronIconDown from '../../_svg/icon/chevron/down.vue';
import Document from './__document.vue';
import DocumentList from './__documentList.vue';

export default {
    name: 'DocumentListing',

    components: {
        ChevronIconLeft,
        ChevronIconRight,
        ChevronIconDown,
        Document,
        DocumentList,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => []
        },
    },
    
    data() {
        return {
            cn: 'o-documentListing',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
        ...mapObject({
            documentItems: {
                type: 'navigation-item',
                filter: {
                    itemHidden: ($vm) => {
                        if (!$vm) return;
                        return false;
                    },
                },
                isArray: true
            },
        }),
        documentTree() {
            return this.documentItems ?
                this.documentItems.filter(item => item.level === '1') :
                [];
        },
    },

    watch: {},
    
    mounted() {},

    created() {},

    methods: {},
};
