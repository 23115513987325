import initRenderProcessEventsForDebugging from './debug/initRenderProcessEvents';
import initRenderProcessEventsForNotifications from './notifications/initRenderProcessEvents';
import initRenderProcessEventsForOfflineMode from './offlineMode/initRenderProcessEvents';

const initRenderProcessEvents = (store) => {
    initRenderProcessEventsForDebugging(store);
    initRenderProcessEventsForNotifications(store);
    initRenderProcessEventsForOfflineMode(store);
};

export default initRenderProcessEvents;
