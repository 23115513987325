import {createNamespacedHelpers} from 'vuex';
import {mapObject} from 'vuex-json-relationship-resolver';
import LaskaLogo from '../../../components/_svg/LaskaLogo.vue';
import ImageComponent from '../../../components/_atoms/image/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    computed: {
        ...baseMapper.mapState(['isInPdfGenerationMode', 'printingMeta']),
        ...mapObject({pdfMeta: {type: 'pdf-meta', isArray: true}}),
    },
    created() {
        this.$store.dispatch('rr/find', 'pdf-meta/_all');
    },
    components: {
        LaskaLogo,
        ImageComponent
    },
};
