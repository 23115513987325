import 'intersection-observer';
import enterView from 'enter-view';

import animations from './animations';

const scrollTriggers = {
    triggerAnimate(el, selector) {
        animations.init(el, selector);
    },

    scrollSnap() {
        enterView({
            selector: '.js-animateWrapper, .cb-animateWrapper',
            enter: (el) => {
                if (!el.classList.contains('is-show')) {
                    if (el.classList.contains('js-animateWrapper')) {
                        this.triggerAnimate(el);
                        el.classList.add('is-show');
                    } else if (el.classList.contains('cb-animateWrapper')) {
                        this.triggerAnimate(el, '.cbaw-animate');
                        el.classList.add('is-show');
                    }
                }
            },
            exit: (el) => {
                el.classList.add('is-done');
            },
            progress: (el, progress) => {
                if (!el.classList.contains('cb-animateWrapper') && !el.classList.contains('js-animateWrapperOnce')) {
                    el.style.opacity = Math.sqrt(progress * 9);
                }
            },
            offset: 0.2,
            once: false,
        });
    },

    init() {
        this.scrollSnap();
    }
};

export default scrollTriggers;
