import { createNamespacedHelpers } from 'vuex';
import IconRight from '../../_svg/icon/chevron/right.vue';

const desktopAppMapper = createNamespacedHelpers('desktopApp');
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ErrorOverlay',

    components: {
        IconRight,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-errorOverlay',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        errorLabel() {
            if (this.error && typeof this.error === 'string') {
                return 'default';
            }
            if (this.error) {
                return this.error.userErrorMessageLabel || 'default';
            }
            return 'default';
        },
        ...baseMapper.mapState(['isDesktopApp']),
        ...desktopAppMapper.mapState([
            'error',
        ]),
    },
    
    watch: {},
    
    mounted() {},
    
    created() {},
    
    methods: {
        retry() {
            this.$store.dispatch('desktopApp/retryDownloading');
        },
        switchToOnline() {
            this.$store.dispatch('desktopApp/setLoadingError', null);
            this.$store.dispatch('desktopApp/setLoadingErrorType', null);
            this.$store.dispatch('desktopApp/switchToOnline');
        }
    },
};
