
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m21 21v6h-2v-6h-6v-2h6v-6h2v6h6v2z" fill="#161a1b" fill-rule="evenodd"/></svg>
    </div>
</template>

<script>
export default {
    name: 'plus',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-plus',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-plus {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-plus--black {
    path {
      fill: c('black');
    }
  }

  .svg-plus--red {
    path {
      fill: c('red');
    }
  }

  .svg-plus--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-plus--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    