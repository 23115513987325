var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"navigation",class:[_vm.rootClass, {navOpen: _vm.isVisible}]},[_c('div',{ref:"blackWrapper",class:['o-Navigation__blackWrapper', { navOpen: _vm.isVisible}]}),_c('div',{ref:"redWrapper",class:['o-Navigation__redWrapper', { navOpen: _vm.isVisible}]}),_c('div',{ref:"navWrapper",class:['o-Navigation__section', { navOpen: _vm.isVisible}]},[_c('nav',[_c('ul',{class:{ 'o-Navigation__primary': true,
                    'o-Navigation__primary--active': _vm.currentMenuState.length }},_vm._l((_vm.firstLevelNavigationItems),function(primaryLink,primaryIndex){return _c('li',{key:primaryIndex,class:{ 'o-Navigation__linkLevel--1': true,
                      'o-Navigation__linkLevel--1--active': _vm.isActive(primaryIndex) }},[_c('div',{ref:"mainNavLinks",refInFor:true},[_c('div',{staticClass:"o-Navigation__link",on:{"click":function($event){return _vm.select(primaryIndex, 2)}}},[_vm._v(" "+_vm._s(primaryLink.name)+" ")])]),(_vm.$refs.navWrapper)?_c('MainMenuSub',{attrs:{"level":2,"nav-ref":_vm.$refs.navWrapper,"links":Object.values(primaryLink.subList),"active-parent":_vm.isActive(primaryIndex),"is-active-link":_vm.isActiveLink,"toggle-visibility":_vm.toggleVisibility}}):_vm._e()],1)}),0)]),_c('nav',{staticClass:"o-Navigation__secondaryWrapper"},[_c('ul',{ref:"metaLinks",staticClass:"o-Navigation__secondary"},[_c('li',{class:[
          'o-Navigation__secondaryLink',
          { 'o-Navigation__secondaryLink--active': _vm.medialibraryLinkIsActive() } ]},[_c('RouterLink',{attrs:{"to":{ path: '/content-page/mediathek' }},nativeOn:{"click":function($event){return _vm.toggleVisibility.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("navigation.medialibrary")))])])],1),_c('li',{class:[
          'o-Navigation__secondaryLink',
          { 'o-Navigation__secondaryLink--active': _vm.medialibraryLinkIsActive() } ]},[_c('RouterLink',{attrs:{"to":{ path: '/content-page/faq' }},nativeOn:{"click":function($event){return _vm.toggleVisibility.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("navigation.faq")))])])],1),_c('li',{class:[
          'o-Navigation__secondaryLink',
          { 'o-Navigation__secondaryLink--active': _vm.documentLinkIsActive() } ]},[_c('RouterLink',{attrs:{"to":{ name: 'document-overview' }},nativeOn:{"click":function($event){return _vm.toggleVisibility.apply(null, arguments)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("navigation.documents"))+" "),_c('NumberCircle',{staticClass:"o-Navigation__numberCircle",attrs:{"value":_vm.documentCount}})],1)])],1),(!_vm.isOffline)?_c('li',{staticClass:"o-Navigation__secondaryLink"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("navigation.logout")))])])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }