import { createNamespacedHelpers } from 'vuex';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';

const desktopAppMapper = createNamespacedHelpers('desktopApp');
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'LoadingOverlay',

    components: {
        LoadingSpinner,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-loadingOverlay',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isDesktopApp']),
        ...desktopAppMapper.mapState(['isRestoring']),
        ...desktopAppMapper.mapGetters([
            'loadingObjectsStatistics',
            'loadingImagesStatistics',
            'loadingVideosStatistics',
            'loadingPdfsStatistics',
            'loadingRenderingsStatistics',
            'loadingWebrotate360Statistics',
            'isLoading',
        ]),
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
