import { createNamespacedHelpers } from 'vuex';
import AddDocument from '../addDocument/_component.vue';

const documentsMapper = createNamespacedHelpers('documents');

export default {
    name: 'sendDocument',

    components: {
        AddDocument,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'm-sendDocument',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
        ...documentsMapper.mapState({
            document: 'currentDocument',
        }),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {},
};
