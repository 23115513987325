import PrintItem from '../printItem/_component.vue';

export default {
    name: 'ProductsPrintTemplate',

    components: {
        PrintItem,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        applicationName: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            cn: 'o-productsPrintTemplate',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        categories() {
            return [...new Set(this.products.map(product => product ? product.category : ''))];
        },
        categoryPairs() {
            const categoryPairs = [];
            const numberOfCategories = this.categories.length;
            for (let i = 0; i < numberOfCategories; i += 2) {
                categoryPairs.push([
                    this.categories[i],
                    this.categories[i + 1],
                ]);
            }
            return categoryPairs;
        },
    },

    watch: {},

    mounted() {
    },

    created() {},

    methods: {
        getProductsByCategory(category) {
            return this.products.filter(product => product ? product.category === category : false);
        },
        getProductHTMLforCategory(category) {
            const htmlElementArray = this.getProductsByCategory(category).map(product => `<p>${product.title}</p>`);
            return htmlElementArray.join('');
        }
    },
};
