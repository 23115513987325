import fetchMediaItems from './methods/fetchMediaItems'
import fetchMediaFilters from './methods/fetchMediaFilters'
import { v4 as uuidv4 } from 'uuid'
import i18n from '../../../../i18n';

export const Actions = {
    END_LOADING: 'endLoading',
    START_LOADING: 'startLoading',
    FETCH_MEDIA_ITEMS: 'fetchMediaItems',
    FETCH_MEDIA_FILTERS: 'fetchMediaFilters',
    ACTIVATE_FILTER: 'activateFilter',
    DEACTIVATE_FILTER: 'deactivateFilter',
    SWITCH_MEDIA_TYPE: 'switchMediaType',
    ACTIVATE_CATEGORY: 'activateCategory',
    DEACTIVATE_CATEGORY: 'deactivateCategory',
    ACTIVATE_DETAIL_VIEW: 'activateDetailView',
    DEACTIVATE_DETAIL_VIEW: 'deactivateDetailView',
}

export const Mutations = {
    SET_LOADING_STATE: 'SET_loadingState',
    SET_MEDIA_ITEMS: 'SET_mediaItems',
    SET_FILTER_ITEMS: 'SET_mediaFilterItems',
    SET_ACTIVE_FILTER: 'SET_activeFilter',
    SET_INACTIVE_FILTER: 'SET_inactiveFilter',
    SET_MEDIA_TYPE: 'SET_mediaType',
    SET_ACTIVE_FILTER_CATEGORY: 'SET_activeFilterCategory',
    SET_INACTIVE_FILTER_CATEGORY: 'SET_inactiveFilterCategory',
    SET_ACTIVE_ITEM: 'SET_activeItem',
}

export const Getters = {
    GET_MEDIA_ITEMS: 'mediaItems',
    GET_MEDIA_FILTERS_AVAILABLE: 'mediaFiltersAvailable',
    GET_MEDIA_FILTERS_CATEGORIES: 'mediaFiltersCategories',
    GET_MEDIA_FILTERS_ACTIVE: 'mediaFiltersActive',
    GET_ACTIVE_MEDIA_TYPE: 'activeMediaType',
    GET_ARE_ALL_ACTIVE_FILTERS_FOR_CATEGORY: 'areAllActiveForCategory',
    GET_ACTIVE_DETAIL_VIEW_ITEM: 'activeDetailViewItem',
}

const module = {

    namespaced: true,

    state: {
        mediaState: [],
        filterState: {
            active: [],
            available: [],
            displayType: 'all'
        },
        detailViewState: {
            activeItem: null,
        },
        loadingState: false,
    },

    mutations: {
        [Mutations.SET_MEDIA_ITEMS](state, items) {
            state.mediaState.items = items;
        },
        [Mutations.SET_FILTER_ITEMS](state, items) {
            const transformed = items
            Object.keys(transformed).map((item) => {

                // Artificially adding a structure which renders a dropdown
                if (item === 'mediaLibraryApplications') {
                    transformed[item] = [
                        {
                            id: uuidv4(),
                            title: i18n.t('medialibrary.categories.mediaLibraryApplications'),
                            children: items[item]
                        }
                    ]
                } else if (item === 'mediaLibraryProducts') {
                    // Artificially adding a child filter for Production Lines
                    const productionLinesFilter = items[item].find(filter => filter.id === 208358)
                    const clone = { ...productionLinesFilter }
                    productionLinesFilter.children = [ clone ]
                }
            });
            state.filterState.available = transformed;
        },
        [Mutations.SET_ACTIVE_FILTER](state, filter) {
            state.filterState.active = [...state.filterState.active, {
                id: filter.id,
                type: filter.type,
                title: filter.title,
                parentId: filter.parentId,
            }];
        },
        [Mutations.SET_INACTIVE_FILTER](state, id) {
            state.filterState.active = [...state.filterState.active.filter(item => item.id !== id)];
        },
        [Mutations.SET_LOADING_STATE](state, isLoading) {
            state.loadingState = isLoading;
        },
        [Mutations.SET_MEDIA_TYPE](state, type) {
            state.filterState.displayType = type;
        },
        [Mutations.SET_ACTIVE_FILTER_CATEGORY](state, categoryFilterData) {
            if (categoryFilterData) {
                const parentTitle =  categoryFilterData.filter.title;
                state.filterState.active = [...state.filterState.active, {
                    id: categoryFilterData.filter.id,
                    type: categoryFilterData.identifier,
                    title: parentTitle,
                    parent: categoryFilterData.parent
                }];
                categoryFilterData.filter.children.forEach(filter => {
                    state.filterState.active = [...state.filterState.active, {
                        id: filter.id,
                        type: categoryFilterData.identifier,
                        title: filter.title,
                        parent: categoryFilterData.filter.id
                    }];
                })
            }
        },
        [Mutations.SET_INACTIVE_FILTER_CATEGORY](state, categoryFilterData) {
            if (categoryFilterData) {
                state.filterState.active = [...state.filterState.active.filter(item => categoryFilterData.filter.id !== item.id)];
                categoryFilterData.filter.children.forEach(filter => {
                    state.filterState.active = [...state.filterState.active.filter(item => filter.id !== item.id)];
                })
            }
        },
        [Mutations.SET_ACTIVE_ITEM](state, item) {
            state.detailViewState.activeItem = item;
        },
    },

    getters: {
        getLoadingState: (state) => {
            return state.loadingState;
        },
        [Getters.GET_MEDIA_ITEMS]: (state) => {
            const activeProductFilters = state.filterState.active.filter(item => item.type === 'mediaLibraryProducts');
            const activeApplicationsFilters = state.filterState.active.filter(item => item.type === 'mediaLibraryApplications');
            const activeAdvantagesFilters = state.filterState.active.filter(item => item.type === 'mediaLibraryAdvantagesOptions');
            const activeBrandEventsFilters = state.filterState.active.filter(item => item.type === 'mediaLibraryBrandEvents');

            const hasActiveProductFilters = !!activeProductFilters.length
            const hasActiveApplicationsFilters = !!activeApplicationsFilters.length
            const hasActiveAdvantagesFilters = !!activeAdvantagesFilters.length
            const hasActiveBrandEventsFilters = !!activeBrandEventsFilters.length

            const activeCategories = [...new Set(state.filterState.active.map(filterItem => filterItem.type))]; // [2]

            let resultSet = [];

            /**
             * [1] Get all items by media type (can be used in combination with filters or as a standalone filter itself)
             */
            resultSet = state.filterState.displayType === 'all' ? state.mediaState.items : state.mediaState.items.filter(item => item.type === state.filterState.displayType);

            /**
             * [2] If no categories are combined (only filters of one category are active), the operator to filter will always be 'OR'
             */


            const isSiblingActive = (item, parentFilter) => {
                // Find all siblings from available filters that share the same parent ID
                const siblings = state.filterState.available['mediaLibraryProducts'].flatMap(product => {
                    if (product.id === parentFilter.parentId) {
                        return product.children;  // Get children of the parent ID
                    }
                    return [];
                });

                // Check if any sibling matches the item's categories
                return siblings.some(sibling => item.categories.includes(sibling.id));
            };

            if (activeCategories.length === 1) {
                resultSet = resultSet.filter(item => {
                    if (item.type === 'video') {
                        return item.categories.some(category => {
                            // Check for a direct match with active filters
                            if (state.filterState.active.some(filter => filter.id === category)) {
                                return true;  // Item should be shown
                            }

                            // Find if there's a parent filter active for this category
                            const parentFilter = state.filterState.active.find(filter => filter.parentId === category);

                            if (parentFilter) {
                                // Check if any sibling filter is actively conflicting
                                return !isSiblingActive(item, parentFilter);
                            }

                            return false;  // No direct match and no valid parent match without sibling conflicts
                        });
                    } else {
                        return item.categories.some(category => state.filterState.active.find(filter => filter.id === category));
                    }
                });
            }

            /**
             * [3] If more than one category is combined, the operator to filter will be determined by the filter categories.
             * TODO: Make this more abstract
             */

            if (activeCategories.length === 2) {
                // Operator: OR
                if (hasActiveAdvantagesFilters && hasActiveBrandEventsFilters) {
                    const allFilters = [...activeAdvantagesFilters, ...activeBrandEventsFilters]
                    resultSet = resultSet.filter(item => item.categories.some(category => allFilters.find(item => item.id === category)));
                }

                // Operator: OR
                else if (hasActiveAdvantagesFilters && hasActiveApplicationsFilters) {
                    const allFilters = [...activeAdvantagesFilters, ...activeApplicationsFilters]
                    resultSet = resultSet.filter(item => item.categories.some(category => allFilters.find(item => item.id === category)));
                }

                // Operator: OR
                else if (hasActiveApplicationsFilters && hasActiveBrandEventsFilters) {
                    const allFilters = [...activeApplicationsFilters, ...activeBrandEventsFilters]
                    resultSet = resultSet.filter(item => item.categories.some(category => allFilters.find(item => item.id === category)));
                }

                // Operator: OR
                else if (hasActiveProductFilters && hasActiveBrandEventsFilters) {
                    const allFilters = [...activeProductFilters, ...activeBrandEventsFilters]
                    resultSet = resultSet.filter(item => item.categories.some(category => allFilters.find(item => item.id === category)));
                }

                // Operator: AND
                else if (hasActiveProductFilters && hasActiveAdvantagesFilters) {
                    resultSet = resultSet.filter(item => item.categories.some(category => activeProductFilters.find(item => item.id === category)) && item.categories.some(category => activeAdvantagesFilters.find(item => item.id === category)));
                }

                // Operator: AND
                else if (hasActiveProductFilters && hasActiveApplicationsFilters) {
                    resultSet = resultSet.filter(item => item.categories.some(category => activeProductFilters.find(item => item.id === category)) && item.categories.some(category => activeApplicationsFilters.find(item => item.id === category)));
                }
            } else if (activeCategories.length === 3) {
                if (hasActiveProductFilters && hasActiveBrandEventsFilters && hasActiveApplicationsFilters) {
                    const resultsetOne = resultSet.filter(item => item.categories.some(category => activeBrandEventsFilters.find(item => item.id === category)))
                    const resultsetTwo = resultSet
                        .filter(item => item.categories.some(category => activeProductFilters.find(item => item.id === category)))
                        .filter(item => item.categories.some(category => activeApplicationsFilters.find(item => item.id === category)))
                    resultSet = [...resultsetOne, ...resultsetTwo]
                }

                else if (hasActiveProductFilters && hasActiveApplicationsFilters && hasActiveAdvantagesFilters) {
                    resultSet = resultSet
                        .filter(item => item.categories.some(category => activeProductFilters.find(item => item.id === category)))
                        .filter(item => item.categories.some(category => activeApplicationsFilters.find(item => item.id === category)))
                        .filter(item => item.categories.some(category => activeAdvantagesFilters.find(item => item.id === category)));
                }

                else if (hasActiveProductFilters && hasActiveBrandEventsFilters && hasActiveAdvantagesFilters) {
                    const resultSetOne = resultSet
                        .filter(item => item.categories.some(category => activeBrandEventsFilters.find(item => item.id === category)))
                    const resultsetTwo = resultSet
                        .filter(item => item.categories.some(category => activeProductFilters.find(item => item.id === category)))
                        .filter(item => item.categories.some(category => activeAdvantagesFilters.find(item => item.id === category)))
                    resultSet = [...resultSetOne, ...resultsetTwo]
                }

                else if (hasActiveApplicationsFilters && hasActiveBrandEventsFilters && hasActiveAdvantagesFilters) {
                    const resultSetOne = resultSet
                        .filter(item => item.categories.some(category => activeBrandEventsFilters.find(item => item.id === category)))
                    const resultsetTwo = resultSet
                        .filter(item => item.categories.some(category => activeApplicationsFilters.find(item => item.id === category)))
                        .filter(item => item.categories.some(category => activeAdvantagesFilters.find(item => item.id === category)))
                    resultSet = [...resultSetOne, ...resultsetTwo]
                }
            } else if (activeCategories.length === 4) {
                if (hasActiveProductFilters && hasActiveBrandEventsFilters && hasActiveApplicationsFilters && hasActiveAdvantagesFilters) {
                    const orFilters = [...activeProductFilters, ...activeBrandEventsFilters]
                    const resultSetOne = resultSet.filter(item => item.categories.some(category => orFilters.find(item => item.id === category)))
                    const resultSetTwo = resultSet
                        .filter(item => item.categories.some(category => activeApplicationsFilters.find(item => item.id === category)))
                        .filter(item => item.categories.some(category => activeAdvantagesFilters.find(item => item.id === category)));
                    resultSet = [...resultSetOne, ...resultSetTwo]
                }
            }

            return [...new Set(resultSet)]
        },
        [Getters.GET_ACTIVE_MEDIA_TYPE]: (state) => {
            return state.filterState.displayType;
        },
        [Getters.GET_MEDIA_FILTERS_AVAILABLE]: (state) => {
            return state.filterState.available;
        },
        [Getters.GET_ACTIVE_DETAIL_VIEW_ITEM]: (state) => {
            return state.detailViewState.activeItem
        },
        [Getters.GET_MEDIA_FILTERS_ACTIVE]: (state) => {
            return state.filterState.active;
        },
        [Getters.GET_MEDIA_FILTERS_CATEGORIES]: (state) => {
            const availableCategories = Object.keys(state.filterState.available)

            const order = [
                'mediaLibraryProducts',
                'mediaLibraryApplications',
                'mediaLibraryAdvantagesOptions',
                'mediaLibraryBrandEvents'
            ]

            return order.map(category => {
                if (availableCategories.includes(category)) {
                    return {
                        identifier: category,
                        items: state.filterState.available[category],
                    }
                }
            })
        },
        [Getters.GET_ARE_ALL_ACTIVE_FILTERS_FOR_CATEGORY]: (state) => filterCategory => {
            if (filterCategory.children.length > 0) {
                const amount = filterCategory.children.length;
                const enabled = filterCategory.children.filter(filter => {
                    return state.filterState.active.find(item => item.id === filter.id)
                })?.length

                return amount === enabled;
            }
        
        },
    },

    actions: {
        async [Actions.FETCH_MEDIA_ITEMS](context)  {
            const items = await fetchMediaItems(context);
            context.commit(Mutations.SET_MEDIA_ITEMS, items);
        },
        async [Actions.FETCH_MEDIA_FILTERS](context)  {
            const items = await fetchMediaFilters(context);
            context.commit(Mutations.SET_FILTER_ITEMS, items);
        },
        [Actions.START_LOADING](context)  {
            context.commit(Mutations.SET_LOADING_STATE, true);
        },
        [Actions.END_LOADING](context)  {
            context.commit(Mutations.SET_LOADING_STATE, false);
        },
        [Actions.ACTIVATE_FILTER](context, filterData) {
            const child = filterData.parent.children.find(child => child.id === filterData.id);
            const title = child ? child.title : null;

            context.commit(Mutations.SET_ACTIVE_FILTER, {
                id: filterData.id,
                type: filterData.type,
                title: title,
                parentId: filterData.parent.id,
            });

            if (context.getters[Getters.GET_ARE_ALL_ACTIVE_FILTERS_FOR_CATEGORY](filterData.parent)) {
                context.commit(Mutations.SET_ACTIVE_FILTER, {
                    id: filterData.parent.id,
                    type: filterData.type,
                    title: title
                });
            }
        },
        [Actions.DEACTIVATE_FILTER](context, filterData)  {
            context.commit(Mutations.SET_INACTIVE_FILTER, filterData.id);

            if (!context.getters[Getters.GET_ARE_ALL_ACTIVE_FILTERS_FOR_CATEGORY](filterData.parent)) {
                context.commit(Mutations.SET_INACTIVE_FILTER, filterData.parent.id);
            }
        },
        [Actions.SWITCH_MEDIA_TYPE](context, type)  {
            context.commit(Mutations.SET_MEDIA_TYPE, type);
        },
        [Actions.ACTIVATE_CATEGORY](context, categoryFilterData)  {
            context.commit(Mutations.SET_ACTIVE_FILTER_CATEGORY, categoryFilterData);
        },
        [Actions.DEACTIVATE_CATEGORY](context, categoryFilterData) {
            context.commit(Mutations.SET_INACTIVE_FILTER_CATEGORY, categoryFilterData);
        },
        [Actions.ACTIVATE_DETAIL_VIEW](context, item)  {
            context.commit(Mutations.SET_ACTIVE_ITEM, item);
        },
        [Actions.DEACTIVATE_DETAIL_VIEW](context)  {
            context.commit(Mutations.SET_ACTIVE_ITEM, null);
        },
    }
};

export default module;
