import ImageVideoGallery from '../imageVideoGallery/_component.vue';
import Toggle from '../../_atoms/toggle/_component.vue';

export default {
    name: 'OutputTables',

    components: {
        ImageVideoGallery,
        Toggle,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        tables: {
            type: Array,
            default: () => [],
        },
        frozenImages: {
            type: Array,
            default: () => [],
        },
        freshImages: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-outputTables',
            condition: 'fresh',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        tableContent() {
            const table = this.tables.filter(t => t.type === this.condition)[0];
            return table ? table.content : [];
        },
        numberOfCols() {
            return this.tableContent[0] ? this.tableContent[0].length : 0;
        },
        colCountArray() {
            const result = [];
            if (this.numberOfCols && this.numberOfCols > 1) {
                for (let i = 0; i < this.numberOfCols - 1; i++) {
                    result.push(i);
                }
            }
            return result;
        },
        showToggle() {
            const moreThenOneTable = this.tables.length > 1;
            let numberOfNonEmptyTables = 0;
            this.tables.forEach((table) => {
                if (!this.tableIsEmpty(table)) {
                    numberOfNonEmptyTables += 1;
                }
            });
            return moreThenOneTable && numberOfNonEmptyTables > 1;
        }
    },

    watch: {},

    mounted() {
    },

    created() {},

    methods: {
        stateChange(condition) {
            this.condition = condition ? 'frozen' : 'fresh';
        },
        getImageArray(index) {
            if (this.condition === 'frozen') {
                return this.frozenImages[index] ? [this.frozenImages[index]] : [];
            }
            if (this.condition === 'fresh') {
                return this.freshImages[index] ? [this.freshImages[index]] : [];
            }
            return [];
        },
        tableIsEmpty(table) {
            let isEmpty = true;
            if (table.content) {
                table.content.forEach((tableRow) => {
                    tableRow.forEach((tableCell) => {
                        if (isEmpty && tableCell) {
                            isEmpty = false;
                        }
                    });
                });
            }
            return isEmpty;
        },
    },
};
