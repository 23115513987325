/**
 * 360° turntable from image sequence
 * @author M.G.Kishalmi
 *
 * @param {HTMLElement} element - container element
 * @param {Array<String>} aImages - array of image urls
 * @param {Object=} config
 *
 * @constructor
 */
function Turntable(element, aImages, config) {

    // default options
    config = Object.assign({
        clockwise: true,
        dragSpeedFactor: 0.025,
        dragSpeedMax: 2,
        dampingFactor: 0.8, // 1 no damping!
    }, config);

    // attach all the images to the container
    let images = [];
    let imgActive;
    let numTotal = aImages.length;
    let numLoaded = 0;
    aImages.forEach((img) => {
        let elImg = document.createElement('img');
        elImg.setAttribute('src', img);
        elImg.addEventListener('load', () => {
            elImg.classList.add('loaded');
            if (++numLoaded >= numTotal) onLoaded();
        });
        element.appendChild(elImg);
        images.push(elImg);
    });

    let ttImage = 0; // currently shown turntable image
    let ttSpeed = 0;
    let ttDir = config.clockwise ? -1 : +1;
    // eslint-disable-next-line no-unused-vars
    let xDown, yDown;
    let onMouseMove = function (e) {
        let eX = (e.clientX !== undefined) ? e.clientX : e.touches[0].clientX;
        let dx = eX - xDown;
        // console.log('mousemove', dx, eX, xDown);

        //let eY = (e.clientY !== undefined) ? e.clientY : e.touches[0].clientY;
        //let dy = eY - yDown;

        ttSpeed += ttDir * dx * config.dragSpeedFactor;
        if (Math.abs(ttSpeed) > config.dragSpeedMax)
            ttSpeed = config.dragSpeedMax * Math.sign(ttSpeed);
        xDown += dx;
    };
    let onMouseUp = function () {
        xDown = undefined; // abuse xDown as marker for being down
        yDown = undefined;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        document.removeEventListener('touchmove', onMouseMove);
        document.removeEventListener('touchend', onMouseUp);
        document.removeEventListener('touchcancel', onMouseUp);
    };
    let onMouseDown = function (e) {

        e.preventDefault(); // prevent event handling (drag'n'drop amongst others)
        xDown = (e.clientX !== undefined) ? e.clientX : e.touches[0].clientX;
        yDown = (e.clientY !== undefined) ? e.clientY : e.touches[0].clientY;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        document.addEventListener('touchmove', onMouseMove);
        document.addEventListener('touchend', onMouseUp);
        document.addEventListener('touchcancel', onMouseUp);
    };
    element.addEventListener('mousedown', onMouseDown);
    element.addEventListener('touchstart', onMouseDown);
    element.classList.add('turntable');

    function onLoaded() {
        // console.debug('all images loaded.');
        element.dispatchEvent(new Event('loaded'));
        start();
    }

    function cycle(n) {
        while (n < 0) n += numTotal;
        while (n >= numTotal) n -= numTotal;
        return n;
    }

    /** render/animation loop **/
    let running = false;

    function render() {
        let iImage = Math.floor(ttImage);
        let imgNext = images[iImage];

        if (imgNext.classList.contains('loaded')) {
            if (imgActive) imgActive.classList.remove('active');
            imgActive = imgNext;
            imgActive.classList.add('active');
        }
    }

    function animate() {
        if (running)
            window.requestAnimationFrame(animate);

        if (!ttSpeed) return;
        // console.debug('[',ttImage,']',ttSpeed);

        ttImage = cycle(ttImage + ttSpeed); // turning

        ttSpeed *= config.dampingFactor;
        if (Math.abs(ttSpeed) < 0.01) ttSpeed = 0;

        render();
    }

    function start() {
        if (running) return;

        running = true;
        animate(); // start loop
        render(); // render initial state
    }

    element.jump = function (n) {
        ttImage = cycle(n);
    };
    element.nudge = function (n) {
        ttSpeed = n;
    };

    return element;
}

/**
 * helper function to create an array of filenames
 *  from a numbering function containing ### to pad
 *
 * @param {string} fName
 * @param {number} amount
 * @param {number=0} first
 *
 * @returns {Array}
 */
Turntable.sequenceNames = function (fName, amount, first) {
    if (first === undefined) first = 0;

    // pad a number with zeros
    function zeropad(i, n) {
        return new Array(n - String(i).length + 1).join('0') + i;
    }

    // prepare fName parts
    let match = fName.match(/^(.*?)(#+)(.*?)$/);

    let mPath = match[1];
    let lNum = match[2].length;
    let mExt = match[3];

    let aSeq = [];

    for (let i = 0; i < amount; i++)
        aSeq.push(mPath + zeropad(first + i, lNum) + mExt);

    return aSeq;
};

export default Turntable;
