import AddIconAdd from '../../_svg/icon/documents/docs_add.vue';
import AddIconAdded from '../../_svg/icon/documents/docs_added.vue';

export default {
    name: 'AddIcon',

    components: {
        AddIconAdd,
        AddIconAdded,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        isAdded: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            cn: 'a-addIcon',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
    },

    methods: {
    },
};
