import { mapObject } from 'vuex-json-relationship-resolver';
import ImageComponent from '../../../../components/_atoms/image/_component.vue';
import IconRight from '../../../../components/_svg/icon/chevron/right.vue';
import LoadingSpinner from '../../../../components/_atoms/loadingSpinner/_component.vue';
import AuthenticationPage from '../../../../layouts/authenticationPage/_component.vue';

export default {
    name: 'resetPassword',

    components: {
        ImageComponent,
        IconRight,
        LoadingSpinner,
        AuthenticationPage,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-resetPassword',
            errorMessage: null,
            id: '',
            code: '',
            newPassword: '',
            newPasswordConfirmation: '',
            loading: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...mapObject({navigationMeta: {type: 'navigation-meta', isArray: true}}),
    },

    watch: {},

    mounted() {
        this.id = this.$route.query.id;
        this.code = this.$route.query.code;
    },

    created() {
        this.$store.dispatch('rr/find', 'navigation-meta/_all', { root: true });
    },

    methods: {
        submit() {
            this.loading = true;
            this.errorMessage = null;

            this.$store.dispatch('auth/resetPassword', {
                id: this.id,
                code: this.code,
                newPassword: this.newPassword,
                newPasswordConfirmation: this.newPasswordConfirmation,
            }).then(() => {
                this.loading = false;
                this.$router.push(this.$route.query.redirect || '/password-reset-success');
            }).catch((error) => {
                this.loading = false;
                this.errorMessage = error;
            });
        }
    },
};
