import VueI18n from "vue-i18n";
import infiniteScroll from 'vue-infinite-scroll';
import Vue from 'vue';
import de from './de.json';
import en from './en.json';

Vue.use(VueI18n);
Vue.use(infiniteScroll);

const translations = {
    de,
    en,
};

const i18n = new VueI18n({
    locale: 'de',
    messages: translations,
});

export default i18n;