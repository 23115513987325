import pushSublevelsToTrail from './methods/pushSublevelsToTrail';
import pushMainLevelToTrail from './methods/pushMainLevelToTrail';

const module = {

    namespaced: true,

    state: {
        currentMenuState: [],
    },

    mutations: {
        SET_currentMenuState(state, trail) {
            state.currentMenuState = trail;
        },
        PUSH_toCurrentMenuState(state, value) {
            state.currentMenuState.push(value);
        },
        SET_currentMenuStateToLevel(state, level) {
            const oldState = state.currentMenuState;
            const newState = oldState.slice(0, level);
            state.currentMenuState = newState;
        },
        CLEAR_activeMenuState(state) {
            state.currentMenuState = [];
        },
        INIT_activeMenuState(state, trail) {
            state.currentMenuState = [...trail];
        },
    },

    getters: {
        getActivePageTrail: () => (currentRoute, navItems) => {
            if (!currentRoute || !navItems) {
                return [0];
            }
            
            const { path } = currentRoute;
            let currentNavItem = navItems.filter(item => item.link === path)[0];
            const activePageTrail = [];

            if (currentNavItem) {
                currentNavItem = pushSublevelsToTrail(activePageTrail, currentNavItem);
                pushMainLevelToTrail(activePageTrail, navItems, currentNavItem);
                return activePageTrail.reverse();
            }
            
            return [0];
        },
    },

    actions: {
        setCurrentMenuState(context, trail)  {
            context.commit('SET_currentMenuState', trail);
        },
        pushToCurrentMenuState(context, value)  {
            context.commit('PUSH_toCurrentMenuState', value);
        },
        resetCurrentMenuStateToLevel(context, level) {
            context.commit('SET_currentMenuStateToLevel', level);
        },
        clearActiveMenuState(context) {
            context.commit('CLEAR_activeMenuState');
        },
        initializeActiveMenuState(context, trail) {
            context.commit('INIT_activeMenuState', trail);
        },
    }
};

export default module;
