
export default {
    name: 'GridOverlay',

    components: {
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-gridOverlay',
            environment: process.env.NODE_ENV,
            showGrid: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        toggleGrid() {
            this.showGrid = !this.showGrid;
        }
    },
};
