import handleObject from './handle-object';
import InternetConnectionError from '../../../../helpers/errors/InternetConnectionError';
import DataDownloadError from '../../../../helpers/errors/download/DataDownloadError';
import packageJson from '../../../../../package.json';

const fetchMethod = (context, customHelpers, mainEndpoint) => {
    return new Promise((mainResolve, mainReject) => {
        context.commit('setObjectLoading', mainEndpoint);
        let headers = {};

        // eslint-disable-next-line
        let httpClient = async (endpoint) => {

            // eslint-disable-next-line
            if (customHelpers.hasOwnProperty('requestOptions')) {
                headers = customHelpers.requestOptions(context.rootState, { endpoint });
            }

            headers['headers']['X-App-Version'] = packageJson.version;

            let response;

            try {
                response = await fetch(endpoint, headers);

            // will only reject on network failure or if anything prevented the request from completing
            // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
            } catch (error) {
                throw new InternetConnectionError(error);
            }

            if (!response.ok) {
                if (response.status === 401) {
                    context.commit('rr/deleteUser', null, { root: true });
                    context.dispatch('auth/logoutOnlyFrontend', null, { root: true });
                    const router = window.app.$router;

                    if (router && (router.name === 'home' || (router.currentRoute && router.currentRoute.meta && router.currentRoute.meta.requiresAuth))) {
                        router.push({ name: 'login' });
                    }
                } else {
                    // eslint-disable-next-line
                    throw new DataDownloadError('Error fetching data');
                }
            }

            const data = await response.json();
            return data;
        };

        // eslint-disable-next-line
        if (customHelpers.hasOwnProperty('httpClient')) {
            const customHttpClient = customHelpers.httpClient(context.rootState, { mainEndpoint });
            if (customHttpClient) {
                httpClient = customHttpClient;
            }
        }

        httpClient(mainEndpoint).then(body => {
            context.commit('setObjectDoneLoading', mainEndpoint);

            // eslint-disable-next-line
            if (customHelpers.hasOwnProperty('onRequestFailed')) {
                return customHelpers.onRequestFailed(context.rootState, {
                    mainEndpoint,
                    body,
                });
            }

            if (Array.isArray(body.data)) {
                const result = [];
                for (let i = 0; i < body.data.length; i++) {
                    result.push(handleObject(body.data[i], context));
                }
                mainResolve(result);
                return;
            }

            const object = handleObject(body.data, context);
            mainResolve(object);
        }).catch(error => {
            mainReject(error);
            context.commit('setObjectDoneLoading', mainEndpoint);
        });
    });
};

export default fetchMethod;
