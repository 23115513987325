import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueScrollTo from 'vue-scrollto';

import packageJson from '../package.json';
import env from '../.env';
import App from './App.vue';
import store from './vuex/store';
import router from './routing/router';
import './assets/js/fontfaceobserver';
import i18n from '../i18n';
import isDesktopApp from './services/is-desktop-app';
import registerError from './helpers/registerError';

import initElectronEvents from './electron/events/initRenderProcessEvents';

/* eslint-disable-next-line */
console.log(`App Version: ${packageJson.version}`);

if (env.sentry && env.sentry.enabled) {
    Sentry.init({
        dsn: env.sentry.dsnJs,
    });
    Sentry.configureScope(scope => {
        scope.setExtra('app-version', packageJson.version);
        scope.setExtra('is-desktop-app', isDesktopApp ? 'true' : 'false');
    });
}

if (isDesktopApp) {
    process.on('unhandledRejection', error => {
        registerError('unhandledRejection', error);
    });

    // INITIALIZE ELECTRON EVENTS FOR MENU & NOTIFICATIONS
    initElectronEvents(store);
}

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
    load: {
        key: env.mapsApiKey,
    },
});

Vue.use(VueScrollTo, {
    duration: 500,
    easing: [0.455, 0.030, 0.515, 0.955], // in-out-quad
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
});

window.app = new Vue({
    i18n,
    store,
    router: router(store),
    render: h => h(App),
}).$mount('#vue-root');
