import LoginPage from '../../views/authentification/login/_component.vue';
import RegistrationPage from '../../views/authentification/registration/registrationForm/_component.vue';
import RegistrationCompletePage from '../../views/authentification/registration/registrationComplete/_component.vue';
import RegistrationSuccessPage from '../../views/authentification/registration/registrationSuccess/_component.vue';
import RegistrationErrorPage from '../../views/authentification/registration/registrationError/_component.vue';
import ResetPasswordPage from '../../views/authentification/passwordReset/resetPassword/_component.vue';
import ResetPasswordSuccessPage from '../../views/authentification/passwordReset/resetPasswordComplete/_component.vue';
import ForgotPasswordPage from '../../views/authentification/passwordReset/forgotPassword/_component.vue';
import ForgotPasswordEmailSentPage from '../../views/authentification/passwordReset/forgotPasswordEmailSent/_component.vue';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/registration',
        name: 'registration',
        component: RegistrationPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/registration-email-sent',
        name: 'registration-email-sent',
        component: RegistrationCompletePage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/registration-success',
        name: 'registration-success',
        component: RegistrationSuccessPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/registration-error',
        name: 'registration-error',
        component: RegistrationErrorPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPasswordPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/forgot-password/:code',
        name: 'reset-password',
        component: ResetPasswordPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/password-reset-success',
        name: 'password-reset-success',
        component: ResetPasswordSuccessPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
    {
        path: '/forgot-password-email-sent',
        name: 'forgot-password-email-sent',
        component: ForgotPasswordEmailSentPage,
        meta: {
            requiresAuth: false,
            redirectIfLoggedIn: true,
            hideAppHeader: true,
        },
    },
];

export default routes;