import {createNamespacedHelpers} from 'vuex';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import GmapCustomMarker from 'vue2-gmap-custom-marker'; // eslint-disable-line
import {gmapApi} from 'vue2-google-maps';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import Accordion from '../../_organisms/accordion/_component.vue';
import MapMarker from '../../_molecules/mapMarker/_component.vue';
import clusterOptions from './__clusterOptions';
import mapOptions from './__mapOptions';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'LocationsSection',

    components: {
        GmapCluster,
        GmapCustomMarker,
        SectionWrapper,
        Accordion,
        MapMarker,
        mapOptions,
        LoadingSpinner,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: null,
        },
        subline: {
            type: String,
            default: null,
        },
        locations: {
            type: Array,
            default: () => [],
        },
        showSection: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cn: 'o-locationsSection',
            mapOptions: mapOptions,
            clusterOptions: clusterOptions,
            activeMarker: -1,
            mapRendered: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        google: gmapApi,
        ...baseMapper.mapState(['isDesktopApp']),
        ...baseMapper.mapGetters([
            'isOffline',
        ]),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        markerClick(l, index) {
            if (this.activeMarker !== -1) {
                this.$refs.mapMarker[this.activeMarker].setActive(false);
            }
            if (this.activeMarker === index) {
                this.activeMarker = -1;
            } else {
                this.activeMarker = index;
                this.$refs.mapMarker[index].setActive(true);
            }

            this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo({lat: parseFloat(l.address.lat), lng: parseFloat(l.address.lng)});
            });
        },

        getAccordionItems() {
            const items = [];

            function groupBy(list, keyGetter) {
                const map = new Map();
                list.forEach((item) => {
                    const key = keyGetter(item);
                    const collection = map.get(key);
                    if (!collection) {
                        map.set(key, [item]);
                    } else {
                        collection.push(item);
                    }
                });
                return map;
            }

            if (this.locations) {
                const grouped = groupBy(this.locations, location => location.country.region);

                // eslint-disable-next-line no-restricted-syntax
                for (const key of grouped.keys()) {
                    items.push({accordionHeadline: this.$t(`location.${key.toLowerCase()}`), locations: grouped.get(key)});
                }
            }

            return items;
        },

        tilesloaded() {
            this.mapRendered = true;
        }
    },
};
