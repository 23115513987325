import ImageWrapper from '../../_atoms/image/_component.vue';

export default {
    name: 'FullPageQuote',

    components: {
        ImageWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: () => {},
        },
        quote: {
            type: String,
            default: '',
        },
        source: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            cn: 'm-fullPageQuote',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
