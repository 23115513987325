import { createNamespacedHelpers } from 'vuex';
import Turntable from '../../../helpers/Turntable';
import LoadingSpinner from '../../_atoms/loadingSpinner/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Viewer360',

    components: {
        LoadingSpinner,
    },

    data() {
        return {
            cn: 'm-viewer360',
            viewer360: null,
            viewer360Config: {
                clockwise: false,
            },
            showView360: true,
            play: false,
            isLoading: false,
            inlineBase64ThumbnailImage: null,
        };
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        images: {
            type: Array,
            default: () => [],
        },
        jump: {
            type: Number,
            default: 10,
        }
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        thumbnail() {
            if (this.inlineBase64ThumbnailImage) {
                return this.inlineBase64ThumbnailImage;
            }
            return this.images[0];
        },
        ...baseMapper.mapGetters(['isOffline', 'isInPdfGenerationMode']),
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {
        if (this.isOffline) {
            this.play360View();
        }
    },
    
    methods: {
        play360View() {
            if (this.showView360 && !this.isPrinting) {
                this.play = true;
                this.isLoading = true;
                this.$nextTick(() => {
                    this.viewer360 = new Turntable(
                        this.$refs.view360,
                        this.images,
                        this.viewer360Config,
                    );
                    this.viewer360.addEventListener('loaded', () => {
                        this.isLoading = false;
                        this.viewer360.jump(this.jump);
                        window.setTimeout(() => {
                            this.viewer360.nudge(-this.jump / 5 * Math.sign(this.jump));
                        }, 100);
                    });
                });
            }
        }
    },

    destroyed() {},
};
