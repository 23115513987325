<template>
  <div :class="rootClass"
       :style="`
          width: ${width};
          height: ${height};
       `">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
      <defs>
        <path id="apple-a" d="M27.257211,20.045394 C27.2218163,16.459028 30.1816965,14.7397313 30.3135417,14.6538992 C28.6499917,12.21963 26.0591011,11.88692 25.1361847,11.8479859 C22.9310961,11.6249994 20.8339613,13.1460858 19.7146045,13.1460858 C18.5987872,13.1460858 16.8715267,11.880726 15.0425066,11.9143509 C12.6392078,11.9497456 10.4226159,13.311556 9.18557178,15.4644374 C6.68847704,19.7976312 8.54581293,26.2164566 10.9800821,29.7311484 C12.1693434,31.4513299 13.5877852,33.3829946 15.4495454,33.313975 C17.242286,33.2423008 17.920979,32.1539143 20.0880184,32.1539143 C22.2550577,32.1539143 22.8647311,33.313975 24.7627709,33.2785803 C26.691781,33.2423008 27.9146672,31.5256588 29.0959647,29.8001681 C30.4613145,27.8047929 31.0240899,25.8722433 31.05683,25.7731382 C31.0143564,25.7536712 27.2943754,24.3290353 27.257211,20.045394 L27.257211,20.045394 Z M23.6920818,9.52167049 C24.6804782,8.32267567 25.347668,6.65912567 25.1653854,5 C23.7416343,5.05840122 22.0161436,5.94857745 20.9950071,7.1440328 C20.0782848,8.20498836 19.2765953,9.89862384 19.491618,11.5250094 C21.0808392,11.6488908 22.7028004,10.7171258 23.6920818,9.52167049 Z"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask id="apple-b" fill="#fff">
          <use xlink:href="#apple-a"/>
        </mask>
        <use fill="#000" fill-rule="nonzero" xlink:href="#apple-a"/>
        <g fill="#161A1B" fill-rule="nonzero" mask="url(#apple-b)">
          <rect width="40" height="40"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'apple',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-apple',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-apple {
    svg {
      width: 100%;
      height: 100%;
    }
  
    mask, g, path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-apple--black {
    mask, g, path {
      fill: c('black');
    }
  }

  .svg-apple--red {
    mask, g, path {
      fill: c('red');
    }
  }

  .svg-apple--gray {
    mask, g, path {
      fill: c('white', '30%');
    }
  }

  .svg-apple--blue {
    mask, g, path {
      fill: c('blue');
    }
  }
</style>
    