import { createNamespacedHelpers } from 'vuex';
import { transformHelper } from '../../../helpers/dataHelper';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import OutputTablesGrinder from '../outputTablesGrinder/_component.vue';
import OutputTableCutter from '../outputTableCutter/_component.vue';
import OutputTableMixerGrinders from '../../_organisms/outputTableMixerGrinder/_component.vue';
import OutputTableMixers from '../../_organisms/outputTableMixer/_component.vue';
import OutputTableEmulsifier from '../../_organisms/outputTableEmulsifier/_component.vue';
import OutputTableFrozenMeatCutter from '../../_organisms/outputTableFrozenMeatCutter/_component.vue';
import OutputImages from '../../_molecules/outputImages/_component.vue';
import ImageWrapper from '../../_atoms/image/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'OutputProductSection',

    components: {
        SectionWrapper,
        OutputTablesGrinder,
        OutputTableCutter,
        OutputTableMixerGrinders,
        OutputTableMixers,
        OutputTableEmulsifier,
        OutputTableFrozenMeatCutter,
        OutputImages,
        ImageWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        introText: {
            type: String,
            default: '',
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productOverview: {
            type: Object,
            require: true,
        },
    },

    data() {
        return {
            cn: 'o-outputProductSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...transformHelper,
    },
};
