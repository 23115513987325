export default {
    name: 'Toggle',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        leftOption: {
            type: String,
            default: 'off',
        },
        rightOption: {
            type: String,
            default: 'on',
        },
        onChange: {
            type: Function,
            default: () => {},
        },
        initialValue: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            cn: 'a-toggle',
            value: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
    },

    watch: {
        value() {
            this.onChange(this.value);
        }
    },
    
    mounted() {
        this.value = this.initialValue;
    },

    methods: {
        toggle() {
            this.value = !this.value;
        },
    },
};
