import { transformHelper } from '../../../helpers/dataHelper';
import ChevronButton from '../../_atoms/chevronButton/_component.vue';

export default {
    name: 'OutputTablesGrinder',

    components: {
        ChevronButton,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            require: true,
        },
        suffix: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            cn: 'o-outputTablesGrinder',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        uniqueProductTitles() {
            return [...new Set(this.data.map((col) => col.productTitle))];
        },
        dataByProductTitle() {
            const orderedData = [];
            this.uniqueProductTitles.forEach((productTitle) => {
                orderedData[productTitle] = this.data.filter((col) => col.productTitle === productTitle);
            });
            return orderedData;
        }
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...transformHelper,
        hasContent(columnName) {
            let hasContent = false;

            this.uniqueProductTitles.forEach((productTitle) => {
                const orderedData = this.data.filter((col) => col.productTitle === productTitle);

                orderedData.forEach((orderedDataItem) => {
                    if (orderedDataItem[columnName] && orderedDataItem[columnName] !== '') {
                        hasContent = true;
                    }
                });
            });

            return hasContent;
        }
    },
};
