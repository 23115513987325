import * as Sentry from '@sentry/browser';
import NoRefreshTokenError from '../../../../helpers/errors/NoRefreshTokenError';

// eslint-disable-next-line
const initializeSentryUser = async (context) => {
    try {
        await context.dispatch('auth/checkIfLoginIsValid', {}, { root: true });
        const user = await context.dispatch('rr/find', 'user/current', { root: true });
        Sentry.configureScope(scope => {
            scope.setExtra('token', context.rootState.auth.token);
            scope.setUser({ user });
        });
    } catch (error) {
        if (error instanceof NoRefreshTokenError) {
            return;
        }
        // eslint-disable-next-line
        console.log('Error:', error);
    }
};

export default initializeSentryUser;