
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 53.1 (72631) - https://sketchapp.com -->
    <title>129CF81A-15EB-4AEE-B635-8BD1A101970B@1.00x</title>
    <desc>Created with sketchtool.</desc>
    <g id="↳-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icon/docs/added" fill="#161A1B" fill-rule="nonzero">
            <path d="M27,24 L22,24 L22,29 L13,29 L13,11 L27,11 L27,24 Z M11,31 L23,31 L29,25 L29,9 L11,9 L11,31 Z M19.1605339,22.5318542 L19.1568542,22.5355339 L15.5,18.8786797 L16.9142136,17.4644661 L19.1605339,19.7107864 L23.4068542,15.4644661 L24.8210678,16.8786797 L19.1642136,22.5355339 L19.1605339,22.5318542 Z" id="Combined-Shape"></path>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
export default {
    name: 'docs_added',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-docs_added',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-docs_added {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-docs_added--black {
    path {
      fill: c('black');
    }
  }

  .svg-docs_added--red {
    path {
      fill: c('red');
    }
  }

  .svg-docs_added--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-docs_added--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    