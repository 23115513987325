import { createNamespacedHelpers } from 'vuex';
import IconChevronLeft from '../../../components/_svg/icon/chevron/left.vue';
import IconChevronRight from '../../../components/_svg/icon/chevron/right.vue';

const documentMapper = createNamespacedHelpers('documents');

export default {
    name: 'DocumentForm',

    components: {
        IconChevronLeft,
        IconChevronRight,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'p-documentForm',
            errorMessage: {},
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...documentMapper.mapState({
            documents: 'addedDocuments',
        }),
        gender: {
            get() {
                return this.$store.state.documents.receiver.gender;
            },
            set(gender) {
                this.$store.dispatch('documents/setGenderOfReceiver', gender);
            },
        },
        firstName: {
            get() {
                return this.$store.state.documents.receiver.firstName;
            },
            set(firstName) {
                this.errorMessage['meta.customer.firstname'] = undefined;
                this.$store.dispatch('documents/setFirstNameOfReceiver', firstName);
            },
        },
        lastName: {
            get() {
                return this.$store.state.documents.receiver.lastName;
            },
            set(lastName) {
                this.errorMessage['meta.customer.lastname'] = undefined;
                this.$store.dispatch('documents/setLastNameOfReceiver', lastName);
            },
        },
        company: {
            get() {
                return this.$store.state.documents.receiver.company;
            },
            set(company) {
                this.errorMessage['meta.customer.company'] = undefined;
                this.$store.dispatch('documents/setCompanyOfReceiver', company);
            },
        },
        message: {
            get() {
                return this.$store.state.documents.receiver.message;
            },
            set(message) {
                this.$store.dispatch('documents/setMessageOfReceiver', message);
            },
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        async submit() {
            if (this.formIsValid()) {
                this.errorMessage = null;

                try {
                    await this.$store.dispatch('documents/sendDocuments');
                    this.$router.push({ name: 'document-processing' });
                } catch (error) {
                    this.errorMessage = error.errorMessages;
                }
            }
        },
        formIsValid() {
            let formIsValid = true;
            const errors = {};

            if (this.fieldIsEmpty(this.firstName)) {
                errors['meta.customer.firstname'] = this.$t('form.fieldCannotBeEmpty');
                formIsValid = false;
            }

            if (this.fieldIsEmpty(this.lastName)) {
                errors['meta.customer.lastname'] = this.$t('form.fieldCannotBeEmpty');
                formIsValid = false;
            }

            if (this.fieldIsEmpty(this.company)) {
                errors['meta.customer.company'] = this.$t('form.fieldCannotBeEmpty');
                formIsValid = false;
            }

            if (this.isEmpty(this.documents)) {
                errors.pages = this.$t('form.fieldCannotBeEmpty');
                formIsValid = false;
            }

            this.errorMessage = errors;
            return formIsValid;
        },
        fieldIsEmpty(value) {
            return !value;
        },
        isEmpty(array) {
            return array.length === 0;
        },
    },
};
