import videojs from 'video.js';
import { createNamespacedHelpers } from 'vuex';
import videoPlayerOptions from './__options';

const baseMapper = createNamespacedHelpers('base');

/**
 * Create an Imageblock with responsive Images. Uses Imgix to load the Images
 */
export default {
    name: 'VideoPlayer',

    data() {
        return {
            cn: 'm-videoPlayer',
            player: undefined,
            showVideo: false,
        };
    },

    props: {
        modifiers: {
            type: Array,
            default: () => []
        },
        video: {
            type: Object,
        },
        options: {
            type: Object,
            default: () => {},
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        caption: {
            type: Boolean,
            default: false,
        },
        captionContent: {
            type: String,
            default: '',
        },
        captionAddition: {
            type: String,
        }
    },

    watch: {
        video() {
            if (!this.player && this.video._loading === false && this.video._empty === false) {
                this.loadVideoPlayer();
            }
        },
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapGetters(['isOffline']),
    },

    mounted() {
        if (this.video._loading === false && this.video._empty === false) {
            this.loadVideoPlayer();
        }
    },

    methods: {
        loadVideoPlayer() {
            const source = this.selectVideoSource(this.video.versions || this.video.attributes.versions || null);
            if (source && source.url && source.thumbnail) {
                if (this.autoplay) {
                    this.$refs.videoSrc.src = source.url;
                    this.$refs.videoSrc.type = source.type;
                    this.showVideo = true;
                } else {
                    const options = {
                        ...videoPlayerOptions,
                        ...this.options,
                        sources: [{
                            src: source.url,
                            type: source.type,
                        }],
                        poster: source.thumbnail,
                    };

                    // timeout necessary for videos to work in electron app
                    // TODO: find out why this is necessary (SS)
                    setTimeout(() => {
                        this.player = videojs(this.$refs.videoNode, options, () => {
                            this.showVideo = true;
                        });
                    }, 1);
                }
            }
        },

        selectVideoSource(videoVersions) {
            if (!videoVersions) {
                return null;
            }

            let videoSource = null;
            if (this.isOffline) {
                videoSource = this.video.offlineVersion;
            } else if (videoVersions.length === 1) {
                [ videoSource ] = videoVersions;
            } else if (videoVersions.length > 1) {
                // const videoWidth = this.$refs.videoContainer.clientWidth;
                const videoWidth = this.getWindowWidth();
                let index = 0;
                do {
                    videoSource = videoVersions[index];
                    index += 1;
                } while (index < videoVersions.length &&
                       this.getWidthFrom(videoVersions[index]) >= videoWidth);
            }
            return videoSource;
        },

        getWidthFrom(video) {
            return video.height / 9 * 16;
        },

        getWindowWidth() {
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        },
    },

    destroyed() {
        if (this.player) {
            this.showVideo = false;
            this.player.dispose();
        }
    }
};
