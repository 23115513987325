import TaskRunner from './TaskRunner';
import Task from './tasks/Task';
import Queue from '../../helpers/queue';

class Executor {
    constructor(vuexContext, loadingStaticsKey, numberOfTaskRunners = 1) {
        this.vuexContext = vuexContext;
        this.loadingStaticsKey = loadingStaticsKey;
        this.taskRunnerList = [];
        this.taskQueue = new Queue();
        this.isRunning = false;
        this.initTaskRunners(numberOfTaskRunners);
    }

    initTaskRunners(numberOfTasks) {
        for (let i = 0; i < numberOfTasks; i++) {
            this.taskRunnerList.push(new TaskRunner(this.taskQueue, () => !this.isFinished()));
        }
    }
    
    enqueueTask(task) {
        if (task instanceof Task) {
            this.taskQueue.enqueue(task);
        } else {
            throw new TypeError('Expected enqueued tasks to be instance of Task');
        }
    }
    
    async start() {
        if (this.isRunning) {
            throw new Error('Executer has already been submitted');
        }

        this.isRunning = true;
        const promises = [];
        this.taskRunnerList.forEach((taskRunner) => {
            const promise = taskRunner.run();
            promises.push(promise);
        });
        
        await Promise.all(promises);
    }
    
    stop() {
        if (this.isRunning) {
            this.isRunning = false;
            this.taskRunnerList.forEach((taskRunner) => {
                taskRunner.stop();
            });
        }
    }

    isFinished() {
        const loadingStatistics = this.vuexContext.getters[this.loadingStaticsKey];
        return loadingStatistics.done === loadingStatistics.total;
    }
}

export default Executor;