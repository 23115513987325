/*
 * Description of what this file is for
 *
 * @package  Laska Salesapp
 * @author David Hellmann [david@fredmansky.at]
 * @author Sarah Schütz [sarah@fredmansky.at]
 * @author Theresa Obermayr [theresa@fredmansky.at]
 * @author Frederic Köberl [fred@fredmansky.at]
 * Navigation
 */
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { mapObject } from 'vuex-json-relationship-resolver';
// import anime from 'animejs/lib/anime';
import ChevronIconLeft from '../../_svg/icon/chevron/left.vue';
import ChevronIconRight from '../../_svg/icon/chevron/right.vue';
import MainMenuSub from './__submenu.vue';
import NumberCircle from '../../_atoms/numberCircle/_component.vue';
import DocumentIcon from '../../_atoms/documentIcon/_component.vue';
import Settings from '../settings/_component.vue';

const navigationMapper = createNamespacedHelpers('navigation');
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Navigation',

    components: {
        ChevronIconLeft,
        ChevronIconRight,
        MainMenuSub,
        NumberCircle,
        DocumentIcon,
        Settings,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-Navigation',
            isVisible: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
        ...mapObject({
            navigationItems: { type: 'navigation-item', isArray: true },
        }),
        ...mapGetters({
            documentCount: 'documents/getLength',
        }),
        firstLevelNavigationItems() {
            return this.navigationItems ?
                this.navigationItems.filter(item => item.level === '1') :
                [];
        },
        ...navigationMapper.mapState([
            'currentMenuState',
        ]),
        ...navigationMapper.mapGetters([
            'getActivePageTrail',
        ]),
        ...baseMapper.mapGetters([
            'isOffline',
        ]),
    },

    watch: {},

    mounted() {},

    created() {
        if (!this.isOffline) {
            this.$store.dispatch('rr/find', 'navigation/_all');
        }
    },

    methods: {
        documentLinkIsActive() {
            return this.$router &&
                this.$router.currentRoute &&
                (this.$router.currentRoute.name === 'document-overview' ||
                    this.$router.currentRoute.name === 'document-form' ||
                    this.$router.currentRoute.name === 'document-processing');
        },
        medialibraryLinkIsActive() {
            return this.$router &&
                this.$router.currentRoute &&
                (this.$router.currentRoute.name === 'content-page') &&
                (this.$router.currentRoute.params.slug === 'mediathek');
        },
        activePageTrail() {
            return this.getActivePageTrail(this.$router.currentRoute, this.navigationItems);
        },
        isActiveLink(navItem) {
            const { currentRoute } = this.$router;
            if (!currentRoute || !this.navigationItems || !navItem) {
                return false;
            }

            const { path } = currentRoute;
            const currentNavItem = this.navigationItems.filter(item => item.link === path)[0];

            return currentNavItem && currentNavItem.id === navItem.id;
        },
        toggleVisibility() {
            if (!this.isVisible) {
                document.addEventListener('keyup', this.keyPressed);
                this.$store.dispatch('base/lockBody');
                this.$store.dispatch('navigation/initializeActiveMenuState', this.activePageTrail());
                this.aniNavOpen();
            } else {
                document.removeEventListener('keyup', this.keyPressed);
                this.$store.dispatch('base/freeBody');
                this.aniNavClose();
                this.$store.dispatch('navigation/clearActiveMenuState');
            }
        },
        
        aniNavOpen() {
            this.isVisible = true;
            this.$parent.isNavOpen = this.isVisible;
        },

        aniNavClose() {
            this.isVisible = false;
            this.$parent.isNavOpen = this.isVisible;
        },

        keyPressed($event) {
            if ($event.keyCode === 27) {
                this.toggleVisibility();
            }
        },
        select(index) {
            this.$store.dispatch('navigation/setCurrentMenuState', [index]);
        },
        isActive(index) {
            return this.currentMenuState[0] === index;
        },
        logout() {
            this.$store.dispatch('auth/logout').then(() => {
                document.removeEventListener('keyup', this.keyPressed);
                this.$store.dispatch('base/freeBody');
                setTimeout(() => {
                    this.aniNavClose();
                }, 1);
                this.$store.dispatch('navigation/clearActiveMenuState');
            }).catch((err) => {
                console.log(err); // eslint-disable-line
            });
        },
    },
};
