import ImageWrapper from '../../../../_atoms/image/_component.vue';

export default {
    name: 'ContentBuilderImageWithText',

    components: {
        ImageWrapper
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object
        },
        text: {
            type: String
        }
    },

    data() {
        return {
            cn: 'o-contentBuilderImageWithText',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {},
};
