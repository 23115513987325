import anime from 'animejs';
import ImageComponent from '../../components/_atoms/image/_component.vue';
import LaskaLogo from '../../components/_svg/LaskaLogo.vue';

export default {
    name: 'authenticationPage',

    components: {
        ImageComponent,
        LaskaLogo,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            cn: 'p-authenticationPage',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {
        this.doAnimations();
    },

    created() {},

    methods: {
        doAnimations() {
            // Image
            const {image} = this.$refs;

            anime({
                targets: image,
                easing: 'easeOutQuart',
                begin: (response) => {
                    response.animatables.forEach((aniEl) => {
                        aniEl.target.classList.remove('js-animated');
                        aniEl.target.classList.add('js-animating');
                    });
                },
                complete: (response) => {
                    response.animatables.forEach((aniEl) => {
                        aniEl.target.classList.remove('js-animating');
                        aniEl.target.classList.add('js-animated');
                    });
                },
                opacity: [
                    { value: [0, 1], duration: 1500, easing: 'linear' }
                ],
                scale: [
                    { value: [1, 1.1], duration: 20000, easing: 'linear' }
                ],
            });

            // Wrapper
            const {wrapper} = this.$refs;
            const {authenticationPage} = this.$refs;
            let transformValue = '-100%';

            if (authenticationPage.classList.contains('p-authenticationPage--right')) {
                transformValue = '100%';
            }

            anime({
                targets: wrapper,
                easing: 'easeOutQuart',
                begin: (response) => {
                    response.animatables.forEach((aniEl) => {
                        aniEl.target.classList.remove('js-animated');
                        aniEl.target.classList.add('js-animating');
                    });

                    // Wrapper
                    const {logo} = this.$refs;

                    anime({
                        targets: logo,
                        easing: 'easeOutQuart',
                        begin: (response) => {
                            response.animatables.forEach((aniEl) => {
                                aniEl.target.classList.remove('js-animated');
                                aniEl.target.classList.add('js-animating');
                            });
                        },
                        complete: (response) => {
                            response.animatables.forEach((aniEl) => {
                                aniEl.target.classList.remove('js-animating');
                                aniEl.target.classList.add('js-animated');
                            });
                        },
                        translateY: [
                            { value: ['-100px', 0], duration: 500 }
                        ],
                        translateX: [
                            { value: '-50%', duration: 0 }
                        ],
                        opacity: [
                            { value: [0, 1], duration: 750 }
                        ],
                    });
                },
                complete: (response) => {
                    response.animatables.forEach((aniEl) => {
                        aniEl.target.classList.remove('js-animating');
                        aniEl.target.classList.add('js-animated');
                    });
                },
                translateX: [
                    { value: [transformValue, 0], duration: 500 }
                ],
                opacity: [
                    { value: [0, 1], duration: 750 }
                ],
            });
        }
    },

    destroyed() {},
};
