import { createNamespacedHelpers } from 'vuex';
import ImageWrapper from '../../../../_atoms/image/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ContentBuilderImageSingle',

    components: {
        ImageWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: null,
        },
        pdfOverwriteImage: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            cn: 'o-contentBuilderImageSingle',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        imageToShow() {
            if (this.isPrinting && this.pdfOverwriteImage && !this.pdfOverwriteImage._empty) {
                return this.pdfOverwriteImage;
            }

            return this.image;
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {},
};
