import CaseStudyPage from '../../views/caseStudyDetail/_component.vue';

const routes = [
    {
        path: '/case-study/:slug',
        name: 'case-study',
        component: CaseStudyPage,
        meta: {
            requiresAuth: true,
        },
    },
];

export default routes;