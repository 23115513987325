
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <path fill="#161A1B" d="M18.9090909,20 L14,15.0909091 L15.0909091,14 L20,18.9090909 L24.9090909,14 L26,15.0909091 L21.0909091,20 L26,24.9090909 L24.9090909,26 L20,21.0909091 L15.0909091,26 L14,24.9090909 L18.9090909,20 Z"/>
</svg>

    </div>
</template>

<script>
export default {
    name: 'close',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-close',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-close {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-close--black {
    path {
      fill: c('black');
    }
  }

  .svg-close--red {
    path {
      fill: c('red');
    }
  }

  .svg-close--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-close--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    