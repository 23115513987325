import { createNamespacedHelpers } from 'vuex';
import { viewConditionHelper } from '../../../helpers/dataHelper';
import CardSlider from '../../_organisms/cardSlider/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';
import ApplicationPrintTemplate from '../../_print/applications/_component.vue';

const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'ApplicationSection',

    components: {
        CardSlider,
        SectionWrapper,
        ApplicationPrintTemplate,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        applications: {
            type: Array,
            default: () => [],
        },
        mappingFunction: {
            type: Function,
            required: true,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        productName: {
            type: String,
            default: null,
        },
        applicationIntroduction: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
            cn: 'o-applicationSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapState(['isPrinting']),
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
    },
};
