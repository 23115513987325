import objectRelationshipAggregator from './object-aggregation/relationship';
import objectAttributesGetterSetter from './object-aggregation/attributes-getter-setter';

const handleObject = (object, context) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            object = objectRelationshipAggregator({ object, context });
            object = objectAttributesGetterSetter({ object, context });
            resolve(object);
        }, 1);
    });
};

export default handleObject;
