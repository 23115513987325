export default {
    name: 'LoadingSpinner',

    components: {},

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        background: {
            type: String,
            default: 'black',
        },
        scale: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            cn: 'a-loadingSpinner',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    methods: {},
};
