import Flickity from 'vue-flickity'; // eslint-disable-line
import imagesLoaded from 'vue-images-loaded';
import HighlightSlide from '../../_molecules/highlightSlide/_component.vue';

export default {
    name: 'HighlightSlider',

    components: {
        Flickity,
        HighlightSlide,
    },

    directives: {
        imagesLoaded,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-highlightSlider',
            flickityOptions: {
                groupCells: '80%',
                contain: true,
                cellAlign: 'left',
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                dragThreshold: 3,
                prevNextButtons: true,
                pageDots: false,
                imagesLoaded: true,
                resize: true,
            },
            dragging: false,
            activeSlideIndex: 0,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        currentLabel() {
            return this.slides[this.activeSlideIndex].label;
        }
    },

    watch: {},

    mounted() {
        if (this.slides && this.slides.length > 0) {
            const flickityElem = this.$refs.flickity.$flickity;
            flickityElem.nextButton.element.classList.add('flickity__nextBtn');
            flickityElem.prevButton.element.classList.add('flickity__prevBtn');

            this.$refs.flickity.on('dragMove', this.startDragging);
            this.$refs.flickity.on('dragEnd', this.stoppDragging);
            this.$refs.flickity.on('change', this.updateSlideIndex);
        }
    },

    created() {
    },

    methods: {
        startDragging() {
            this.dragging = true;
            document.ontouchmove = (e) => {
                e.preventDefault();
            };
        },
        stoppDragging() {
            this.$nextTick(() => {
                this.dragging = false;
                document.ontouchmove = () => {
                    return true;
                };
            });
        },
        imgLoaded() {
            const flickityElem = this.$refs.flickity.$flickity;
            flickityElem.resize();
        },
        updateSlideIndex(index) {
            this.activeSlideIndex = index;
        },
    },
};
