import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';

export default {
    name: 'OutputSectionPrintTemplate',

    components: {
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        output: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'o-outputSectionPrintTemplate',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        categories() {
            const categories = [
                ...new Set(this.output.map(output => output.outputType ? output.outputType.value : null))
            ].filter(output => output !== null);
            return categories;
        },
        outputTablesByCategory() {
            const result = [];
            this.categories.forEach((category) => {
                const outputWithCurrentCategory = this.output.filter(output => output.outputType && output.outputType.value === category);
                result.push(outputWithCurrentCategory);
            });
            return result;
        },
    },
	
    watch: {},
	
    mounted() {},
	
    created() {},
	
    methods: {
        tableIsEmpty(table) {
            let isEmpty = true;
            if (table.content) {
                table.content.forEach((tableRow) => {
                    tableRow.forEach((tableCell) => {
                        if (isEmpty && tableCell) {
                            isEmpty = false;
                        }
                    });
                });
            }
            return isEmpty;
        },
        moreThanOneTable(tables) {
            const moreThenOneTable = tables.length > 1;
            let numberOfNonEmptyTables = 0;
            tables.forEach((table) => {
                if (!this.tableIsEmpty(table)) {
                    numberOfNonEmptyTables += 1;
                }
            });
            return moreThenOneTable && numberOfNonEmptyTables > 1;
        },
    },
};
