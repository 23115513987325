import {createNamespacedHelpers} from 'vuex';
const baseMapper = createNamespacedHelpers('base');

export default {
    name: 'Tab',

    components: {
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        tabs: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'm-tabs',
            activeTab: this.tabs[0].id,
            flickityOptions: {
                contain: true,
                cellAlign: 'left',
                initialIndex: 0,
                friction: 1,
                selectedAttraction: 0.3,
                dragThreshold: 3,
                prevNextButtons: false,
                pageDots: false,
                resize: true,
                freeScroll: true,
                freeScrollFriction: 0.03
            },
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
        ...baseMapper.mapGetters({
            isTouchDevice: 'isMobile',
        })
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        showTab(id) {
            this.$emit('showTab', id);
            this.activeTab = id;
        }
    },
};
