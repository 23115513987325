<template>
  <div :class="rootClass"
       :style="`
             width: ${width};
             height: ${height};
           `">
    <svg xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="40"
         height="40"
         viewBox="0 0 40 40">
      <defs>
        <path id="error-a" d="M3,3 L37,3 L37,37 L3,37 L3,3 Z M6,6 L6,34 L34,34 L34,6 L6,6 Z M19,12 L22,12 L22,22 L19,22 L19,12 Z M19,28 L19,25 L22,25 L22,28 L19,28 Z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <polygon points="0 0 40 0 40 40 0 40" />
        <use fill="#E11624" fill-rule="nonzero" xlink:href="#error-a" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: 'Error',
    props: {
        color: {
            type: String,
            default: 'white',
        },
        width: {
            type: String,
            default: '40px',
        },
        height: {
            type: String,
            default: '40px',
        },
    },
    data() {
        return {
            cn: 'svg-notificationError',
        };
    },
    computed: {
        rootClass() {
            return `${this.cn}  ${this.cn}--${this.color}`;
        },
    },
};
</script>

<style lang="scss">
  .svg-notificationError {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-notificationError--black {
    path {
      fill: c('black');
    }
  }

  .svg-notificationError--red {
    path {
      fill: c('red');
    }
  }

  .svg-notificationError--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-notificationError--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    
