import { viewConditionHelper } from '../../../helpers/dataHelper';
import VideoPlayer from '../../_molecules/videoPlayer/_component.vue';
import SectionWrapper from '../../_organisms/sectionWrapper/_component.vue';

export default {
    name: 'LandingPageVideoSection',

    components: {
        VideoPlayer,
        SectionWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        video: {
            type: Object,
            default: () => null,
        },
        showSection: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            cn: 'o-landingPageVideoSection',
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {},

    mounted() {},

    created() {},

    methods: {
        ...viewConditionHelper,
    },
};
