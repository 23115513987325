import * as Sentry from '@sentry/browser';

const registerError = (msg, err) => {
    // eslint-disable-next-line
    console.error(msg, err);
    Sentry.captureMessage(msg);
    Sentry.captureException(err);
};

export default registerError;
