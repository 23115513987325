import Pin from '../../_svg/icon/map/pin.vue';
import PinActive from '../../_svg/icon/map/pin-active.vue';

export default {
    name: 'MapPin',

    components: {
        Pin,
        PinActive,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            cn: 'a-mapPin',
            isActive: false,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');

            return [this.cn, modifiers];
        },
    },

    methods: {
        setActive(isActive) {
            this.isActive = isActive;
        }
    },
};
