const register = (context, {gender, firstname, lastname, company, phone, countryId, email, password, password_confirmation, acceptTerms, newsletter}) => {
    return new Promise((resolve, reject) => {
        fetch(`${context.rootState.base.http.baseUrl}auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Site-Language': context.rootState.base.language,
                'X-IS-DOCUMENT-RENDERER': context.rootState.base.isInPdfGenerationMode,
            },
            body: JSON.stringify({
                'gender': gender,
                'firstname': firstname,
                'lastname': lastname,
                'company': company,
                'phone': phone,
                'countryId': countryId,
                'email': email,
                'password': password,
                'password_confirmation': password_confirmation,
                'acceptTerms': acceptTerms,
                'newsletter': newsletter,
            })
        }).then((response) => {
            return response.json().then(body => {
                if (response.ok) {
                    resolve();
                } else {
                    reject(body.data.errors);
                }
            });
        });
    });
};

export default register;