
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
        <svg width="56px" height="61px" viewBox="0 0 56 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M18,42 C30,32.9660973 36,25.0751882 36,18.3272727 C36,8.20539949 27.9411255,-9.23705556e-14 18,-9.23705556e-14 C8.0588745,-9.23705556e-14 0,8.20539949 0,18.3272727 C0,25.0751882 6,32.9660973 18,42 Z" id="path-mappinactive1"></path>
      <filter x="-41.7%" y="-35.7%" width="183.3%" height="171.4%" filterUnits="objectBoundingBox" id="filter-mappinactive">
        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.0862745098   0 0 0 0 0.101960784   0 0 0 0 0.105882353  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="m/map/marker/single-active" transform="translate(10.000000, 7.000000)">
        <g transform="translate(0.000000, 3.000000)">
          <g id="oval" fill-rule="nonzero">
            <use fill="black" fill-opacity="1" filter="url(#filter-mappinactive)" xlink:href="#path-mappinactive1"></use>
            <use fill="#E11624" xlink:href="#path-mappinactive1"></use>
          </g>
          <polygon id="shape" fill="#161A1B" points="12 28.5 15.4664865 10.5 24.4003966 10.5 21.5628432 25.2000494 25.5 25.2000494 24.8610651 28.5"></polygon>
        </g>
      </g>
    </g>
  </svg>
    </div>
</template>

<script>
export default {
    name: 'pin-active',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '56px',
      },
      height: {
        type: String,
        default: '61px',
      },
    },
    data() {
      return {
        cn: 'svg-pin-active',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-pin-active {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-pin-active--black {
    path {
      fill: c('black');
    }
  }

  .svg-pin-active--red {
    path {
      fill: c('red');
    }
  }

  .svg-pin-active--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-pin-active--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    