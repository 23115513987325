import { transformHelper, viewConditionHelper } from '../../../helpers/dataHelper';
import ContentPageLayout from '../../../layouts/contentPage/_component.vue';
import IntroSection from '../../../components/_sections/introSection/_component.vue';
import RelatedContentSection from '../../../components/_sections/relatedContentSection/_component.vue';
import ContentBuilderSection from '../../../components/_sections/contentBuilderSection/_component.vue';
import ViewWrapper from '../../../components/_organisms/viewWrapper/_component.vue';
import { REDIRECT_TO_404_TIME_MS } from '../../../../settings';


export default {
    name: 'ContentPage',

    components: {
        ContentPageLayout,
        IntroSection,
        RelatedContentSection,
        ContentBuilderSection,
        ViewWrapper,
    },

    props: {
        modifiers: {
            type: Array,
            default: () => [],
        },
        contentPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            cn: 'p-contentPage',
            sections: [],
            timeout404Page: null,
        };
    },

    computed: {
        rootClass() {
            const modifiers = this.modifiers
                .map(mod => `${this.cn}--${mod}`)
                .join('  ');
            return [this.cn, modifiers];
        },
    },

    watch: {
        contentPage: {
            handler() {
                this.setSections();
            },
            deep: true,
        },
    },

    mounted() {
        this.setSections();
    },

    created() {
        this.timeout404Page = setTimeout(() => {
            if (!this.contentPage) {
                this.$router.push({ name: 'page404' });
            }
        }, REDIRECT_TO_404_TIME_MS);
    },

    methods: {
        ...transformHelper,
        ...viewConditionHelper,
        setSections() {
            this.$nextTick(() => {
                if (this.$refs && this.$refs.contentSections) {
                    this.sections = Array.from(this.$refs.contentSections.children);
                }
            });
        }
    },

    destroyed() {
        clearTimeout(this.timeout404Page);
    },
};
