
<template>
    <div :class="rootClass"
         :style="`
             width: ${width};
             height: ${height};
           `">
      <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M11,17 L29,17 L29,19 L11,19 L11,17 Z M11,22 L29,22 L29,24 L11,24 L11,22 Z" id="path-1"></path>
</svg>
    </div>
</template>

<script>
export default {
    name: 'move',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      width: {
        type: String,
        default: '40px',
      },
      height: {
        type: String,
        default: '40px',
      },
    },
    data() {
      return {
        cn: 'svg-move',
      };
    },
    computed: {
      rootClass() {
        return `${this.cn}  ${this.cn}--${this.color}`;
      },
    },
};
</script>

<style lang="scss">
  .svg-move {
    svg {
      width: 100%;
      height: 100%;
    }
  
    path {
      fill: c('white');
      transition: all 0.25s ease;
    }
  }

  .svg-move--black {
    path {
      fill: c('black');
    }
  }

  .svg-move--red {
    path {
      fill: c('red');
    }
  }

  .svg-move--gray {
    path {
      fill: c('white', '30%');
    }
  }

  .svg-move--blue {
    path {
      fill: c('blue');
    }
  }
</style>
    